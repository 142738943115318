import * as React from "react";
import { ResponsivePie } from "@nivo/pie";
import i18next from '../i18n/config';

export default function CircleChart(props) {
  var data = [
    // {
    //   id: i18next.t('Completed_tournaments'),
    //   label: i18next.t('Completed_tournaments'),
    //   value: props.inProgress,
    //   color: "hsl(90, 70%, 50%)",
    // },
    // {
    //   id: i18next.t('NotStartedYet'),
    //   label: i18next.t('NotStartedYet'),
    //   value: props.notStarted,
    //   color: "hsl(30, 70%, 50%)",
    // },
    // {
    //   id: i18next.t('All_assigned_tournaments'),
    //   label: i18next.t('All_assigned_tournaments'),
    //   value: props.allTournaments,
    //   color: "hsl(56, 70%, 50%)",
    // },
  ];
  // React.useEffect(() => {
  //   let arr = props.data;
  //   // let data=[];
  //   Object.entries(arr).forEach(([key, value]) => {
  //     let obj = {
  //       id: i18next.t(key),
  //       label: i18next.t(key),
  //       value: value,
  //       color: "hsl(56, 70%, 50%)",
  //     };
  //     data.push(obj);
  //   });
  // }, [props]);
 let arr = props.data;
 // let data=[];
 Object.entries(arr).forEach(([key, value]) => {
   let obj = {
     id: i18next.t(key),
     label: i18next.t(key),
     value: value,
     color: "hsl(56, 70%, 50%)",
   };
   data.push(obj);
 });
  return (
      <div style={{height: 300}}>
    <ResponsivePie
      data={data}

      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#eece52"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
    />
      </div>
  );
}
