import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import { NavLink } from "react-router-dom";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import { Auth, UserLevel, UserRole } from "../redux/Actions/AuthActions";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";
import icon from "../imgs/translateIcon.png";

export default function VerifyMail(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  let BaseUrl = config.BaseUrl;

  const id = useSelector((state) => state.appReducer.id);

  // const id=location.state.id;
  const name = useSelector((state) => state.appReducer.username);

  const form1 = location.state.form1;

  const [form, setform] = useState({
    email: form1.email,
    code: "",
  });

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  function connect1() {
    // setcount1(++count1);

    document.getElementById("message_parent").style.display = "none";
    document.getElementById("message_parent").innerHTML = "";
    // setLoading(true);
    fetch(`${BaseUrl}loginn`, {
      method: "post",
      body: JSON.stringify({
        email: form1.email,
        password: form1.password,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (loginresp) {
        // setid1(loginresp.user.id);
        dispatch(
          Auth(loginresp.user.token, loginresp.user.id, loginresp.user.name)
        );
        dispatch(UserRole(loginresp.user.role.role_id));
        dispatch(
          UserLevel(loginresp.user.level !== null ? loginresp.user.level : 0)
        );

        var y = setTimeout(function () {
          fetch(`${BaseUrl}checkwearableAccess/${loginresp.user.id}`, {
            method: "get",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${loginresp.user.token}`,
            },
          }).then(function (response33) {
            response33.json().then(function (check_fitbitResp) {
              //  setLoading(false);
              if (
                check_fitbitResp[0][0] ==
                "user connect to wearable successfully"
              ) {
                // clearInterval(x);
                document.getElementById("message_parent").style.display =
                  "none";
                document.getElementById("message_parent").innerHTML =
                  en.sign.account_prepare;
                navigate("/dgamer", { state: {} });
              }
              if ("UserError" in check_fitbitResp[0]) {
                document.getElementById("message_parent").style.display =
                  "block";
                document
                  .getElementById("message_parent")
                  .classList.remove("disabled");
                document.getElementById("message_parent").style.display =
                  "block";
                if (
                  check_fitbitResp[0].UserError.status ==
                  "This user does not have a wearable account"
                ) {
                  // clearInterval(x);
                  navigate("/dgamer", { state: { msgtest: en.sign.donthave } });
                  // document.getElementById("message_parent").innerHTML = en.sign.watchlongtime_msg;
                } else {
                  // clearInterval(x);
                  // document.getElementById("message_parent").innerHTML = en.sign.watchfailed_msg;
                  navigate("/dgamer", {
                    state: { msgtest: en.sign.watchfailed_msg },
                  });
                }
              }
            });
          });
        }, 2000);
        // }
      });
    });
  }

  // function submit() {
  //   setloading(true);
  //   document.getElementById("message_parent").style.display = "none";
  //   document.getElementById("message_parent").innerHTML = "";
  //   document.getElementById("form11").classList.add("was-validated");
  //   if (document.getElementById("form11").checkValidity()) {
  //     fetch(`${BaseUrl}VerfiyMail`, {
  //       method: "post",
  //       body: JSON.stringify(form),
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }).then(function (response) {
  //       response.json().then(function (verifyResp) {
  //         setloading(false);
  //         if (verifyResp.status == true) {
  //           // dispatch(Auth(verifyResp.user.token,verifyResp.user.id,verifyResp.user.name));
  //           // navigate('/reset', {state:{}});
  //           fetch(`${BaseUrl}users`, {
  //             method: "post",
  //             body: JSON.stringify(form1),
  //             headers: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //             },
  //           }).then(function (response) {
  //             response.json().then(function (signresp) {
  //               if (signresp.msg == "Failed") {
  //                 document.getElementById("message_parent").style.display =
  //                   "block";
  //                 if (signresp && signresp.data && signresp.data.email[0]) {
  //                   document.getElementById("message_parent").innerHTML =
  //                     signresp.data.email[0];
  //                   setloading(false);
  //                 } else {
  //                   document.getElementById("message_parent").innerHTML =
  //                     en.sign.fail_createaccount;
  //                   setloading(false);
  //                 }
  //               } else if (
  //                 signresp.msg == "That e-mail has taken, Try another"
  //               ) {
  //                 document.getElementById("message_parent").style.display =
  //                   "block";
  //                 document.getElementById("message_parent").innerHTML =
  //                   signresp.msg;
  //                 setloading(false);
  //               } else if (signresp.errNum == "S0000") {
  //                 if (form1.RoleID == "3" || form1.RoleID == "6") {
  //                   connect1();
  //                 }
  //                 if (form1.RoleID == "1") {
  //                   fetch(`${BaseUrl}loginn`, {
  //                     method: "post",
  //                     body: JSON.stringify({
  //                       email: form1.email,
  //                       password: form1.password,
  //                     }),
  //                     headers: {
  //                       Accept: "application/json",
  //                       "Content-Type": "application/json",
  //                     },
  //                   }).then(function (response) {
  //                     response.json().then(function (loginResp) {
  //                       dispatch(
  //                         Auth(
  //                           loginResp.user.token,
  //                           loginResp.user.id,
  //                           loginResp.user.name
  //                         )
  //                       );
  //                       dispatch(UserRole(loginResp.user.role.role_id));
  //                       dispatch(
  //                         UserLevel(
  //                           loginResp.user.level !== null
  //                             ? loginResp.user.level
  //                             : 0
  //                         )
  //                       );
  //                       setloading(false);
  //                       navigate("/parent", { state: {} });
  //                     });
  //                   });
  //                 }
  //               }
  //             });
  //           });
  //         } else {
  //           document.getElementById("message_parent").style.display = "block";
  //           document.getElementById("message_parent").innerHTML =
  //             en.verify.notMatch_msg;
  //           // document.getElementById('msg-reset').classList.remove('d-none');
  //           // document.getElementById('msg-reset').innerHTML=en.verify.notMatch_msg;
  //         }
  //       });
  //     });
  //   }
  // }
function cancel(){

  navigate("/", { state: {} });
}
  function submit() {
    document.getElementById("message_parent").style.display = "none";
    document.getElementById("message_parent").innerHTML = "";
    document.getElementById("form11").classList.add("was-validated");
    if (document.getElementById("form11").checkValidity()) {
      setloading(true);
      fetch(`${BaseUrl}VerfiyMail`, {
        method: "post",
        body: JSON.stringify(form),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (verifyResp) {
          if (verifyResp.status == true) {
            // dispatch(Auth(verifyResp.user.token,verifyResp.user.id,verifyResp.user.name));
            // navigate('/reset', {state:{}});

            let url =
              form1.teamId != null && form1.teamId != ""
                ? `${BaseUrl}RegisterEsportUser`
                : `${BaseUrl}users`;

            fetch(`${url}`, {
              method: "post",
              body: JSON.stringify(form1),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Accept-Language": cookies.lang,
              },
            }).then(function (response) {
              response.json().then(function (signresp) {
                if (signresp.msg == "Failed") {
                  document.getElementById("message_parent").style.display =
                    "block";
                  if (signresp && signresp.data && signresp.data.email[0]) {
                    document.getElementById("message_parent").innerHTML =
                      signresp.data.email[0];
                    setloading(false);
                  } else {
                    document.getElementById("message_parent").innerHTML =
                      i18next.t("fail_createaccount");
                    setloading(false);
                  }
                } else if (
                  signresp.msg == "That e-mail has taken, Try another"
                ) {
                  document.getElementById("message_parent").style.display =
                    "block";
                  document.getElementById("message_parent").innerHTML =
                    signresp.msg;
                  setloading(false);
                } else if (signresp.errNum == "S0000") {
                  if (form1.RoleID == "3" || form1.RoleID == "6") {
                    connect1();
                  }
                  if (form1.RoleID == "1") {
                    fetch(`${BaseUrl}loginn`, {
                      method: "post",
                      body: JSON.stringify({
                        email: form1.email,
                        password: form1.password,
                      }),
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "Accept-Language": cookies.lang,
                      },
                    }).then(function (response) {
                      response.json().then(function (loginResp) {
                        dispatch(
                          Auth(
                            loginResp.user.token,
                            loginResp.user.id,
                            loginResp.user.name
                          )
                        );
                        dispatch(UserRole(loginResp.user.role.role_id));
                        dispatch(
                          UserLevel(
                            loginResp.user.level !== null
                              ? loginResp.user.level
                              : 0
                          )
                        );
                        setloading(false);
                        navigate("/parent", { state: {} });
                      });
                    });
                  }
                }
              });
            });
          } else {
            setloading(false);
            document.getElementById("message_parent").style.display = "block";
            document.getElementById("message_parent").innerHTML =
              i18next.t("notMatch_msg");
            // document.getElementById('msg-reset').classList.remove('d-none');
            // document.getElementById('msg-reset').innerHTML=en.verify.notMatch_msg;
          }
        });
      });
    }
  }
  const setLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
  };
  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const changeLangToEn = () => {
    setLanguage("en");
    i18n.changeLanguage("en");
  };
  const changeLangToAr = () => {
    setLanguage("ar");
    i18n.changeLanguage("ar");
  };
  return (
    <>
      <div>
        <Head />
        <div className="container-fluid p-2 pb-5 sign vh">
          <Nav />
          <div className="flash1"></div>
          <div className="flash2"></div>
          <div className="flash3"></div>
          <button
            style={{ marginTop: "-60px" }}
            className="btn float-end"
            onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}
          >
            <img
              src={icon}
              title={i18next.t("changeLanguage")}
              style={{ width: "50px", height: "50px" }}
            />
          </button>
          <div className="container text-center mt-5 pt-3">
            <h2 className="text-white">{i18next.t("verify_title")}</h2>
            <div class=" mt-3 ">
              <div class="container ">
                <br />
                <form className=" m-auto form-white" id="form11">
                  <div class="mb-3 ">
                    <input
                      type="text"
                      class="form-control"
                      id="code"
                      placeholder={i18next.t("code_placeholder")}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = i18next.t("code_placeholder"))
                      }
                      name="code"
                      onChange={(e) =>
                        setform({ ...form, code: e.target.value })
                      }
                      required
                    />
                    <div className="invalid-feedback text-start text-danger">
                      {i18next.t("FieldIsRequired")}{" "}
                    </div>
                  </div>

                  <a
                    className="btn yellow-btn mr-5 mt-4"
                    id="sign"
                    onClick={submit}
                  >
                    <span className="m-3">{i18next.t("verify_btn")}</span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                        fill="#100E23"
                      />
                    </svg>
                  </a>
                  <a
                      className="btn yellow-btn mr-5 mt-4"
                      id="sign"
                      onClick={cancel}
                  >
                    <span className="m-3">{i18next.t("Cancel")}</span>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                          fill="#100E23"
                      />
                    </svg>
                  </a>
                  <div
                    id="message_parent"
                    className="w-100 mt-4 mb-5 m-auto  alert-danger p-3"
                  ></div>
                  {loading == true ? (
                    <div className="w-100 mt-3 mb-2 m-auto">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <a
                    className="btn yellow-btn mr-5 mt-4"
                    id="sign"
                    onClick={submit}>
                    <span className="m-3">{en.verify.verify_btn}</span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                        fill="#100E23"
                      />
                    </svg>
                  </a>
                  <div
                    id="message_parent"
                    className="w-100 mt-4 mb-5 m-auto  alert-danger p-3"></div>
                  {loading == true ? (
                    <div className="w-100 mt-3 mb-2 m-auto">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div> */}

        <Footer bg="bg_blue" />
      </div>
    </>
  );
}
