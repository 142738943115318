import "./App.css";
import React from "react";
import { BrowserRouter, NavLink, Routes, Route } from "react-router-dom";
import "antd/dist/reset.css";
import Signup from "./register/Signup";
import Dparent from "./dashboard/Dparent";
import Dmanager from "./dashboard/Dmanager";
import Dgamer from "./dashboard/Dgamer";
import Dchild from "./dashboard/Dchild";
import Complete from "./register/Complete";
import Profile from "./dashboard/Profile";
import EditProfile from "./dashboard/EditProfile";
import Login from "./register/Login";
import Redirect from "./register/Redirect";
import Challenge from "./dashboard/Challenge";
import Verify from "./register/Verify";
import VerifyMail from "./register/VerifyMail";
import Forget from "./register/Forget";
import ResetPass from "./register/ResetPass";
import Resetpassword from "./register/Resetpassword";
import SignChild from "./register/components/SignChild";
import ChildMissionDetails from "./dashboard/ChildMissionDetails";
import ChildchallengeDetails from "./dashboard/ChildchallengeDetails";
import TeamMemberRegistrationComponent from "./ESport/TeamMemberRegistrationComponent";
import EsportDashboard from "./ESport/EsportDashboard";
import FAQ from "./FAQ/FAQ";

////New Sign Components////
import RegisterAccountType from "./register/RegisterAccountType";
import RegisterParentComponent from "./register/components/RegisterParentComponent";
import RegisterGamerComponent from "./register/components/RegisterGamerComponent";
import NewRegisterGamer from "./register/components/NewRegisterGamer";
import MemberChallenge from "./ESport/MemberChallenge";
import TeamPage from "./ESport/TeamPage";
import { useCookies } from "react-cookie";
import i18next from "../src/i18n/config";
import { useTranslation } from "react-i18next";
import InprogressTeam from "./ESport/InprogressTeam";
import InprogressTeamDetails from "./ESport/InprogressTeamDetails";
import RegisterVoxParentComponent from "./register/components/RegisterVoxParentComponent";
// import { useCookies } from 'react-cookie';

let BaseUrl = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/veryalityApp" element={<Login />}></Route>
        <Route path="/redirect" element={<Redirect />}></Route>
        <Route path="/resetpassword" element={<Resetpassword />}></Route>
        <Route path="/dgamer" element={<Dgamer />}></Route>
        <Route path="/dchild" element={<Dchild />}></Route>
        <Route path="/parent" element={<Dparent />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/complete" element={<Complete />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/EditProfile" element={<EditProfile />}></Route>
        <Route path="/challenge" element={<Challenge />}></Route>
        <Route
          path="/childmissiondetails"
          element={<ChildMissionDetails />}></Route>
        <Route
          path="/childchallengedetails"
          element={<ChildchallengeDetails />}></Route>
        <Route path="/verify" element={<Verify />}></Route>
        <Route path="/verfiymail" element={<VerifyMail />}></Route>
        <Route path="/forget" element={<Forget />}></Route>
        <Route path="/reset" element={<ResetPass />}></Route>
        <Route path="/signchild" element={<SignChild />}></Route>
        ////New Signs componets //////
        <Route
          path="/RegisterAccountType"
          element={<RegisterAccountType />}></Route>
        <Route
          path="/RegisterParent"
          element={<RegisterParentComponent />}></Route>
        <Route
            path="/vox-register-parent/:vox_coupon?"
            element={<RegisterVoxParentComponent />}></Route>
        <Route
          path="/RegisterGamer"
          element={<RegisterGamerComponent />}></Route>
        ////New Signs logic //////
        <Route path="/NewRegisterGamer" element={<NewRegisterGamer />}></Route>
        //////////////// ESport tputs
        <Route
          path="/memberInvitation"
          element={<TeamMemberRegistrationComponent />}></Route>
        <Route path="/esportDashboard" element={<EsportDashboard />}></Route>
        <Route path="/memberChallenge" element={<MemberChallenge />}></Route>
        <Route path="/teamPage" element={<TeamPage />}></Route>
        <Route path="/inprogressTeam/:id" element={<InprogressTeam />}></Route>
        <Route
          path="/inprogressTeamDetails/:id/tournament/:tournament"
          element={<InprogressTeamDetails />}></Route>

        <Route
            path="/FAQ"
            element={<FAQ/>}></Route>



        <Route path="*" element={<Login />}></Route>
      </Routes>
    </div>
  );
}

export default App;
