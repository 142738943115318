import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
export default function NotifDialog(props) {
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  // const id = props.id;
  let [notifItem, setnotifItem] = useState();
  let [childItem, setchildItem] = useState();

  let [show, setShow] = useState(props.show);
  let [notif, setnotif] = useState({
    missionID: "",
    childID: "",
    PrentID: "",
    status: "",
    userMissionId: "",
  });
  let [notiffailed, setnotiffailed] = useState({
    missionID: "",
    childID: "",
    PrentID: "",
    status: "",
    userMissionId: "",
  });
  const [childname, setchildname] = useState("");
  const [missionname, setmissionname] = useState("");
  let [showbutton, setshowbutton] = useState(false);

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
      setnotif({
        missionID: props.item.mission.id,
        userMissionId: props.item.id,
        childID: props.item.user.id,
        PrentID: id,
        status: 0,
      });
      setnotiffailed({
        missionID: props.item.mission.id,
        childID: props.item.user.id,
        userMissionId: props.item.id,
        PrentID: id,
        status: 2,
      });
      setnotifItem(props.item);
      setchildItem(props.item.user);
      setchildname(props.item.user.name);
      setmissionname(props.item.mission.name);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    props.onChange("david");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${BaseUrl}finishChildMission`, {
      method: "post",
      body: JSON.stringify(notif),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (resp_finishmission) {
        setLoading(false);
        if (resp_finishmission.status == true) {
          document.getElementById("finish_mission1").innerHTML = resp_finishmission.msg;
          document.getElementById("finish_mission1").classList.remove("d-none");
          document
            .getElementById("finish_mission1")
            .classList.add("alert-info");
          setshowbutton(true);
        } else {
          document.getElementById("finish_mission1").innerHTML = resp_finishmission.msg;
          document.getElementById("finish_mission1").classList.remove("d-none");
          document
            .getElementById("finish_mission1")
            .classList.add("alert-danger");
        }
      });
    });
  };
  const handlesubmitFailed = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${BaseUrl}finishChildMission`, {
      method: "post",
      body: JSON.stringify(notiffailed),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (resp_finishmission) {
        setLoading(false);
        if (resp_finishmission.status == true) {
          document.getElementById("finish_mission1").innerHTML = resp_finishmission.msg;
          document.getElementById("finish_mission1").classList.remove("d-none");
          document
            .getElementById("finish_mission1")
            .classList.add("alert-info");
          setshowbutton(true);
        } else {
          document.getElementById("finish_mission1").innerHTML = resp_finishmission.msg;
          document.getElementById("finish_mission1").classList.remove("d-none");
          document
            .getElementById("finish_mission1")
            .classList.add("alert-danger");
        }
      });
    });
  };
  const location = useLocation();

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={
              cookies.lang === "ar"
                ? "modelstyle text-end p-3 rtl"
                : "modelstyle text-start p-3"
            }
          >
            {i18next.t("yourChild")}
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip>{childname}</Tooltip>}
            >
              <span className="text-primary">
                {childname.length > 27
                  ? childname.substring(0, 27) + "..."
                  : childname}
              </span>
            </OverlayTrigger>
            {i18next.t("hasCompleted")}
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip>{missionname}</Tooltip>}
            >
              <span className="text-primary">
                {missionname.length > 27
                  ? missionname.substring(0, 27) + "..."
                  : missionname}
              </span>
            </OverlayTrigger>
            .
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="bg_blue w-30"
              variant="primary"
             // disabled={showbutton}
              onClick={handlesubmit}
            >
              {i18next.t("confirm")}
            </Button>
            <Button
              type="submit"
              className="bg_red w-30"
              variant="outlined"
            //  disabled={showbutton}
              onClick={handlesubmitFailed}
            >
              {i18next.t("Failed")}
            </Button>
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("notCompleted")}
            </Button>
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                id="finish_mission1"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"
              ></div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
