import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import AlertDialog from "../layout/AlertDialog";
import AlertDialogProfile from "../layout/AlertDialogProfile";
import Usernav from "../layout/Usernav";
import Footer from "../layout/Footer";
import Game from "../layout/Game";
import ReactLoading from "react-loading";
import en from "../resources/en.json";
import { Modal, Button } from "react-bootstrap";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import { Auth, UserRole, UserLevel } from "../redux/Actions/AuthActions";
import i18next from "../i18n/config";
import InprogressGamerModal from "../layout/InprogressGamerModal";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export default function Dgamer() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const role = useSelector((state) => state.appReducer.role) || 0;

  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setshow] = useState(false);
  const [haswearble, sethaswearble] = useState([]);
  const { i18n } = useTranslation();

  // const handleShow = () => setshow(true);
  const Modalcontent = i18next.t("child_account_successfully_created");
  // const id=location.state.id;
  const id = useSelector((state) => state.appReducer.id);
  // const msgtest=location.state.msgtest;
  const parentId = location.state.parentId;
  const [isLoading, setLoading] = useState(true);
  //    const username=location.state.username;
  const username = useSelector((state) => state.appReducer.username);
  let [appimg, setappimg] = useState(false);
  let [acctype, setacctype] = useState(1);
  let [appimg1, setappimg1] = useState(false);
  let [msgtest, setmsgtest] = useState(location.state.msgtest);
  let [activ, setactive] = useState({
    calory: 0,
    heart: 0,
    km: 0,
    TotalSteps: 0,
  });
  /////////////////////
  let [batteryLevel, setbatteryLevel] = useState("");
  let [deviceVersion, setdeviceVersion] = useState("");
  let [demo, setdemo] = useState(i18next.t("no_wearable"));
  ///////////////////////
  const level = useSelector((state) => state.appReducer.level);

  let [openInprogress, setOpenInprogress] = useState(false);

  let [prog, setprog] = useState(0);
  let [challenge, setchallenge] = useState(0);
  let [inprogress, setInprogress] = useState([]);
  let [isMember, setIsMember] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);

  var hostName = window.location.origin;
  let BaseUrl = config.BaseUrl;
  function click() {
    navigate("/challenge", { state: {} });
  }
  let props = {
    show: show,
    Modalcontent: Modalcontent,
  };

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (parentId != null) {
      setshow(true);

      if (location.state != null && location.state.parentId != null) {
        navigate("/dgamer", { state: {} });
      }
    }
  }, [parentId]);

  useEffect(() => {
    setLoading(true);
    fetch(`${BaseUrl}users/userWithWearable/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {

        sethaswearble(
          Resp.data.length > 0 &&
            Resp.data[0].wearablesaccounts.length > 0 &&
            Resp.data[0].wearablesaccounts
            ? Resp.data[0].wearablesaccounts
            : []
        );
        let a= haswearble;
        setacctype(
          Resp.data.length > 0 &&
            Resp.data[0].wearablesaccounts.length > 0 &&
            Resp.data[0].wearablesaccounts[0]["warable_type_id"]
            ? Resp.data[0].wearablesaccounts[0]["warable_type_id"]
            : 0
        );
        //   console.log(haswearble[0]['warable_type_id']);
      });
    });
    fetch(`${BaseUrl}checkwearableAccess/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response33) {
      response33.json().then(function (check_fitbitResp) {
        if ("UserError" in check_fitbitResp[0]) {
          if (
            check_fitbitResp[0].UserError.status ==
            "This user does not have a wearable account"
          ) {
            setmsgtest(i18next.t('donthave') );
          } else {
            setmsgtest(i18next.t('watchfailed_msg'));
          }
        }
      });
    });
    fetch(`${BaseUrl}getwearableDashboardData/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getDashBoardFitbitData_response) {
        // setLoading(true);

        setactive({
          km:
            getDashBoardFitbitData_response.length > 0 &&
            getDashBoardFitbitData_response[0][0].activity_value
              ? getDashBoardFitbitData_response[0][0].activity_value
              : 0,
          TotalSteps:
            getDashBoardFitbitData_response.length > 0 &&
            getDashBoardFitbitData_response[1][0].activity_value
              ? getDashBoardFitbitData_response[1][0].activity_value
              : 0,
          heart:
            getDashBoardFitbitData_response.length > 0 &&
            getDashBoardFitbitData_response[2][0].activity_value
              ? getDashBoardFitbitData_response[2][0].activity_value
              : 0,
          calory:
            getDashBoardFitbitData_response.length > 0 &&
            getDashBoardFitbitData_response[3][0].activity_value
              ? getDashBoardFitbitData_response[3][0].activity_value
              : 0,
        });
        // setLoading(false);
      });
    });

    fetch(`${BaseUrl}getUserChallengeInprogress/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getUserChallengeInprogress_response) {
        if (getUserChallengeInprogress_response.status == true)
          setprog(getUserChallengeInprogress_response.inprogrssValue);
      });
    });
    fetch(`${BaseUrl}getwearableDevice/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getFitbitDevice_response) {

        if (getFitbitDevice_response.msg == "You don't have Fitbit account") {
          setappimg1(true);
          setbatteryLevel("");
          setdeviceVersion("");
          setdemo(en.dgamer.NoWearableAccount);
        } else if (
          getFitbitDevice_response.msg == "You Are Using Fitbit Application"
        ) {
          setappimg(true);
          setbatteryLevel("");
          setdeviceVersion("");
          setdemo(en.dgamer.ApplicationUsage);
        } else if (
          getFitbitDevice_response.msg == "You are using Google Fit Application"
        ) {
          setappimg(true);
          setbatteryLevel("");
          setdeviceVersion("");
          setdemo(i18next.t("googleFitApplication"));
        } else {
          setappimg(false);
          if (
            getFitbitDevice_response.data.length > 0 &&
            getFitbitDevice_response.data[0].batteryLevel &&
            getFitbitDevice_response.data[0].batteryLevel !== ""
          ) {
            setbatteryLevel(
              i18next.t("BattreyLevel") +
                getFitbitDevice_response.data[0].batteryLevel
            );
            setdeviceVersion(
                getFitbitDevice_response.data.length > 0 &&
                getFitbitDevice_response.data[0].deviceVersion
                    ? i18next.t("DeviceVersion") +
                    getFitbitDevice_response.data[0].deviceVersion
                    : ""
            );
            setdemo("");
          }
          else {
            setdemo(i18next.t("no_wearable"));
          }


        }
      });
    });

    fetch(`${BaseUrl}getCompletedCahllengesForUser/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getCompletedCahllenges_response) {
        if (role !== 6 || role !== "6") {
          setLoading(false);
        }
        if (getCompletedCahllenges_response.status == true) {
          setchallenge(
            getCompletedCahllenges_response.data.completedChallengesCount
          );
        }
      });
    });
    if (role == 6 || role == "6") {
      setIsMember(true);
      // setLoading(true);
      fetch(`${BaseUrl}GetMemberTournamentsInprogress/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (getInprogress_response) {
          setLoading(false);
          if (getInprogress_response.status == true) {
            setInprogress(getInprogress_response.data);
          }
        });
      });
    }
  }, []);

  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (appimg1 == true) {
      setTimeout(() => {
        setappimg1(false);
      }, 1000);
    }
  }, [appimg1]);

  useEffect(() => {
    if (openInprogress == true) {
      setTimeout(() => {
        setOpenInprogress(false);
      }, 1000);
    }
  }, [openInprogress]);

  if (isLoading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }

  return (
    <div>
      <Head />
      <AlertDialog
        show={appimg}
        Modalcontent={i18next.t("dont_have_watch_popup")}
      />
      <AlertDialogProfile show={appimg1} msgtest={msgtest} />
      <InprogressGamerModal show={openInprogress} inprogress={inprogress} />
      <AlertDialog {...props} />
      {width > 950 ? (
        <div className="desktop">
          <section className=" container-fluid p-2 bg_black ">
            <Usernav />
            <div className="row container-fluid mt-5 dash " style={{paddingLeft:35,paddingRight:25}}>
              <div className="col-md-5 row radius_4">
                <div className="container-fluid parent-watch pt-3 pb-3 w-40 radiusRU_4">
                  <p className="title">
                    <span className="text-capitalize">{username}</span>
                    {i18next.t("apostrophe_fitbit")}
                  </p>
                  <p className="activity"> {i18next.t("calories_burned")}</p>
                  <p className="calory">{activ.calory}</p>
                  <p className="activity mt-5">{i18next.t("heart_rate")}</p>
                  <p className="heart">{activ.heart}</p>
                  <p className="activity mt-5"> {i18next.t("TotalSteps")}</p>
                  <p
                    className={
                      activ.TotalSteps && activ.TotalSteps > 999
                        ? "heart1"
                        : "heart"
                    }
                  >
                    {activ.TotalSteps}
                  </p>
                  <div className="d-flex justify-content-start mt-5">
                    {/*<a className="btn blue-btn">*/}
                    {/*  <span className="p-2">*/}
                    {/*    {" "}*/}
                    {/*    {i18next.t("all_activity_btn")}*/}
                    {/*  </span>*/}
                    {/*  <span>*/}
                    {/*    <svg*/}
                    {/*      width="12"*/}
                    {/*      height="16"*/}
                    {/*      viewBox="0 0 12 16"*/}
                    {/*      fill="none"*/}
                    {/*      xmlns="http://www.w3.org/2000/svg"*/}
                    {/*    >*/}
                    {/*      <path*/}
                    {/*        d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"*/}
                    {/*        fill="white"*/}
                    {/*      />*/}
                    {/*    </svg>*/}
                    {/*  </span>*/}
                    {/*</a>*/}
                  </div>
                </div>
                <div className="w-60 parent-watch  pt-3 pb-3 radiusLU_4">
                  <div className="text-end">
                    <div class="dropdown">
                      <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <img src="../assets/imgs/drop.png" />
                      </button>
                      <ul class="dropdown-menu dropdown-content">
                        <li id="deviceVersion">{deviceVersion} </li>
                        <li id="batteryLevel">{batteryLevel} </li>

                        <li id="demo">{demo} </li>
                      </ul>
                    </div>
                  </div>
                  <div className="watch-img ">
                    <img
                      src={
                        appimg == false
                          ? "../assets/imgs/wear2.png"
                          : haswearble.length > 0 &&
                            haswearble[0].warable_type_id == 3
                          ? "../assets/imgs/googleFit.png"
                          : "../assets/imgs/fitapp.png"
                      }
                      className="img-fluid"
                    />
                  </div>
                </div>
                {haswearble.length == 0 && (
                  <div className="connect p-2 radiusLD_4 ">
                    <span>
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3.5" cy="3.5" r="3" fill="#FF0000" />
                      </svg>
                    </span>
                    <span className="p-1"> {i18next.t("disconnected")}</span>
                  </div>
                )}
                {haswearble.length > 0 && (
                  <div className="connect p-2 radiusLD_4 ">
                    <span>
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="3.5" cy="3.5" r="3" fill="#27AE60" />
                      </svg>
                    </span>
                    <span className="p-1">{i18next.t("connected")}</span>
                  </div>
                )}
              </div>
              <div className="col-md-7 row radius_4 dist bg_blue level-img">
                <div className="container-fluid  pt-3 pb-3 w-40">
                  <p className="title text-start text-white">
                    {i18next.t("progress")}
                  </p>
                  <p className="activity text-white">
                    {i18next.t("current_level")}
                  </p>
                  <p className="level">{level}</p>
                  <p className="activity text-white mt-5">
                    {i18next.t("next_level")}
                  </p>
                  <p className=" mb-5 text-start mt-4">
                    <span className="heart text-white">{challenge}</span>
                    <span className="divi"> / </span>
                    <span className="all"> 1</span>
                  </p>
                  {isMember == true ? (
                    <div className="d-flex justify-content-start mt-5">
                      <a
                        className="btn yellow-btn"
                        onClick={() => setOpenInprogress(true)}
                      >
                        <span className="p-2">
                          {" "}
                          {i18next.t("view_progress_btn")}
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                              fill="#100E23"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-start mt-5">
                      <a className="btn yellow-btn" onClick={click}>
                        <span className="p-2">
                          {i18next.t("view_challenges_btn")}
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                              fill="#100E23"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  )}
                  {/* {isMember == true ? (
                    <div className="d-flex justify-content-start mt-5">
                      <a
                        className="btn yellow-btn"
                        onClick={() => setOpenInprogress(true)}>
                        <span className="p-2">
                          {" "}
                          {i18next.t("view_progress_btn")}
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                              fill="#100E23"
                            />
                          </svg>
                        </span>
                      </a>
                    </div> */}
                  {/* // ) : ( */}

                  {/* // )} */}
                </div>
                <div className="w-60 ">
                  <div className="text-end mt-3 ">
                    <div class="dropdown">
                      <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        {/* <img src='../assets/imgs/drop.png'/> */}
                        <span className="m-3"></span>
                      </button>
                    </div>
                  </div>
                  <div className="all-char">
                    <div className="level-char">
                      <img src="../assets/imgs/avatar.png" className="" />
                      <img src="../assets/imgs/poly.png" className="poly" />
                      <h6>Techno Mage</h6>
                      <p>{prog}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="container-fluid pdash bg_black  pt-4  m-0"
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            <div className="container-fluid bg-grey p-4">
              <div className="">
                <a>
                  <span className="float-start text-white">
                    {i18next.t("games")}
                  </span>
                </a>
                <a className="float-end yellow">
                  <span> {i18next.t("browse_all_games")}</span>
                </a>
              </div>
              <br></br>
              <br></br>
              {/* Nav pills */}
              <ul
                class="nav nav-pills d-flex justify-content-center"
                role="tablist"
              >
                <li class="nav-item  w-25">
                  <a
                    class="nav-link active"
                    data-bs-toggle="pill"
                    href="#games"
                  >
                    <span className="m-3">{i18next.t("mygame")}</span>
                  </a>
                </li>
                <li class="nav-item  w-25 ">
                  <a class="nav-link" data-bs-toggle="pill" href="#latest">
                    <span className="m-3">{i18next.t("latest_games")}</span>
                  </a>
                </li>
              </ul>

              {/*Tab panes */}
              <div class="tab-content">
                <div
                  id="games"
                  className={
                    cookies.lang === "ar"
                      ? "container-fluid tab-pane active rtl"
                      : "container-fluid tab-pane active "
                  }
                >
                  <br />
                  <div className="row m-auto pre-brows">
                    <Game
                      name="V-Racer"
                      active="1"
                      img="../assets/imgs/game.png"
                      isManager={false}
                      url="https://www.roblox.com/games/16743783458/Vracer-DEMO"
                    />
                    {/*<Game*/}
                    {/*  name="V-Racer"*/}
                    {/*  active="0"*/}
                    {/*  img="../assets/imgs/game.png"*/}
                    {/*  isManager={false}*/}
                    {/*/>*/}
                    {/*<Game*/}
                    {/*  name="V-Racer"*/}
                    {/*  active="1"*/}
                    {/*  img="../assets/imgs/game.png"*/}
                    {/*  isManager={false}*/}
                    {/*/>*/}
                    {/*<Game*/}
                    {/*  name="V-Racer"*/}
                    {/*  active="0"*/}
                    {/*  img="../assets/imgs/game.png"*/}
                    {/*  isManager={false}*/}
                    {/*/>*/}
                    {/*<Game*/}
                    {/*  name="V-Racer"*/}
                    {/*  active="1"*/}
                    {/*  img="../assets/imgs/game.png"*/}
                    {/*  isManager={false}*/}
                    {/*/>*/}
                    {/*<Game*/}
                    {/*  name="V-Racer"*/}
                    {/*  active="0"*/}
                    {/*  img="../assets/imgs/game.png"*/}
                    {/*  isManager={false}*/}
                    {/*/>*/}
                    <br/><br/><br/><br/>
                  </div>
                  <div className="brows mt-0">
                    {/*<a className="btn yellow-outline-btn w-40">*/}
                    {/*  <span className="p-3">*/}
                    {/*    {i18next.t("browse_all_veryality_games")}*/}
                    {/*  </span>*/}
                    {/*  <span>*/}
                    {/*    <svg*/}
                    {/*      width="12"*/}
                    {/*      height="11"*/}
                    {/*      viewBox="0 0 12 11"*/}
                    {/*      fill="none"*/}
                    {/*      xmlns="http://www.w3.org/2000/svg"*/}
                    {/*    >*/}
                    {/*      <path*/}
                    {/*        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"*/}
                    {/*        fill="#EECE52"*/}
                    {/*      />*/}
                    {/*    </svg>*/}
                    {/*  </span>*/}
                    {/*</a>*/}
                    <br/><br/><br/><br/>
                  </div>
                </div>
                <div
                  id="latest"
                  className={
                    cookies.lang === "ar"
                      ? "container-fluid tab-pane fade rtl"
                      : "container-fluid tab-pane fade "
                  }
                >
                  <br />
                  <div className="row m-auto pre-brows">
                    <Game
                      name="V-Racer"
                      active="1"
                      img="../assets/imgs/game.png"
                      isManager={false}
                      url="https://www.roblox.com/games/16743783458/Vracer-DEMO"
                    />
                    {/*<Game*/}
                    {/*  name="V-Racer"*/}
                    {/*  active="0"*/}
                    {/*  img="../assets/imgs/game.png"*/}
                    {/*  isManager={false}*/}
                    {/*/>*/}
                    {/*<Game*/}
                    {/*    name="V-Racer"*/}
                    {/*    active="0"*/}
                    {/*    img="../assets/imgs/game.png"*/}
                    {/*    isManager={false}*/}
                    {/*/>*/}
                    {/*<Game*/}
                    {/*    name="V-Racer"*/}
                    {/*    active="0"*/}
                    {/*    img="../assets/imgs/game.png"*/}
                    {/*    isManager={false}*/}
                    {/*/>*/}
                  </div>
                  <div className="brows mt-0">
                    {/*<a className="btn yellow-outline-btn w-40">*/}
                    {/*  <span className="p-3">*/}
                    {/*    {i18next.t("browse_all_veryality_games")}*/}
                    {/*  </span>*/}
                    {/*  <span>*/}
                    {/*    <svg*/}
                    {/*      width="12"*/}
                    {/*      height="11"*/}
                    {/*      viewBox="0 0 12 11"*/}
                    {/*      fill="none"*/}
                    {/*      xmlns="http://www.w3.org/2000/svg"*/}
                    {/*    >*/}
                    {/*      <path*/}
                    {/*        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"*/}
                    {/*        fill="#EECE52"*/}
                    {/*      />*/}
                    {/*    </svg>*/}
                    {/*  </span>*/}
                    {/*</a>*/}
                    <br/><br/><br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="mobile">
          <section className=" container-fluid p-2 bg_black">
            <Usernav />
            <div className="row container-fluid mt-5 dash pdash m-0">
              <ul class="nav nav-pills ">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="pill"
                    href="#device"
                  >
                    {i18next.t("device")}
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " data-bs-toggle="pill" href="#progress">
                    {i18next.t("progress")}
                  </a>
                </li>
              </ul>
              {/* Tab panes */}
              <div class="tab-content p-0  m-0">
                <div
                  class="tab-pane container-fluid m-0 active mt-3 p-0 "
                  id="device"
                >
                  <div className=" parent-watch  pt-3 pb-3 radiusU_4 ">
                    <div className="  m-3">
                      <div class="dropdown float-end">
                        <button
                          type="button"
                          class="btn dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          <img src="../assets/imgs/drop.png" className="" />
                        </button>
                        <ul
                          className={
                            cookies.lang === "ar"
                              ? "dropdown-menu dropdown-content text-end"
                              : " dropdown-menu dropdown-content"
                          }
                        >
                          <li id="deviceVersion">{deviceVersion} </li>
                          <li id="batteryLevel">{batteryLevel} </li>

                          <li id="demo">{demo} </li>
                        </ul>
                      </div>
                      <p className="title text-start">
                        <span className="text-capitalize">{username}</span>
                        {i18next.t("apostrophe_fitbit")}
                      </p>
                    </div>
                    <div className="watch-img ">
                      <img
                        src={
                          appimg == false
                            ? "../assets/imgs/wear2.png"
                            : "../assets/imgs/fitapp.png"
                        }
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="container-fluid parent-watch pt-3 pb-3 ">
                    <div className="row ">
                      <div className="w-40 ">
                        <p
                          className={
                            cookies.lang === "ar"
                              ? "activity text-end"
                              : " activity "
                          }
                        >
                          {i18next.t("calories_burned")}
                        </p>
                        <p
                          className={
                            cookies.lang === "ar"
                              ? "calory text-end"
                              : " calory "
                          }
                        >
                          {activ.calory}
                        </p>
                      </div>
                      <div className="w-30">
                        <p
                          className={
                            cookies.lang === "ar"
                              ? "activity text-end"
                              : " activity "
                          }
                        >
                          {i18next.t("heart_rate")}
                        </p>
                        <p
                          className={
                            cookies.lang === "ar" ? "heart text-end" : " heart "
                          }
                        >
                          {activ.heart}
                        </p>
                      </div>
                      <div className="w-30">
                        <p
                          className={
                            cookies.lang === "ar"
                              ? "activity text-end"
                              : " activity "
                          }
                        >
                           {i18next.t("TotalSteps")}
                        </p>
                        <p
                          className={
                            cookies.lang === "ar"
                              ? activ.TotalSteps && activ.TotalSteps > 999
                                ? "heart1 text-end"
                                : "heart text-end"
                              : activ.TotalSteps && activ.TotalSteps > 999
                              ? "heart1"
                              : "heart"
                          }
                        >
                          {activ.TotalSteps}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                      {/*<a className="btn blue-btn w-100">*/}
                      {/*  <span className="p-2">*/}
                      {/*    {i18next.t("all_activity_btn")}*/}
                      {/*  </span>*/}
                      {/*  <span>*/}
                      {/*    <svg*/}
                      {/*      width="12"*/}
                      {/*      height="16"*/}
                      {/*      viewBox="0 0 12 16"*/}
                      {/*      fill="none"*/}
                      {/*      xmlns="http://www.w3.org/2000/svg"*/}
                      {/*    >*/}
                      {/*      <path*/}
                      {/*        d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"*/}
                      {/*        fill="white"*/}
                      {/*      />*/}
                      {/*    </svg>*/}
                      {/*  </span>*/}
                      {/*</a>*/}
                    </div>
                  </div>

                  {haswearble.length == 0 && (
                    <div className="connect p-2 radiusLD_4 ">
                      <span>
                        <svg
                          width="7"
                          height="7"
                          viewBox="0 0 7 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="3.5" cy="3.5" r="3" fill="#FF0000" />
                        </svg>
                      </span>
                      <span className="p-1">{i18next.t("disconnected")}</span>
                    </div>
                  )}
                  {haswearble.length > 0 && (
                    <div className="connect p-2 radiusLD_4 ">
                      <span>
                        <svg
                          width="7"
                          height="7"
                          viewBox="0 0 7 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="3.5" cy="3.5" r="3" fill="#27AE60" />
                        </svg>
                      </span>
                      <span className="p-1"> {i18next.t("connected")}</span>
                    </div>
                  )}
                </div>
                <div
                  class="tab-pane container-fluid m-0 fade dist bg_blue level-img mt-3 p-0 radius_4"
                  id="progress"
                >
                  <div className=" p-3">
                    <div class="dropdown float-end">
                      <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        {/* <img src='../assets/imgs/drop.png'/> */}
                        <span className="m-3"></span>
                      </button>
                    </div>
                    <p
                      className={
                        cookies.lang === "ar"
                          ? "title text-end text-white"
                          : " title text-start text-white "
                      }
                    >
                      {i18next.t("progress")}
                    </p>
                  </div>
                  <div className="container-fluid row pt-3 pb-3 ">
                    <div className="w-50">
                      <p
                        className={
                          cookies.lang === "ar"
                            ? "activity text-end text-white"
                            : " activity text-white"
                        }
                      >
                        {i18next.t("current_level")}
                      </p>
                      <p
                        className={
                          cookies.lang === "ar" ? "level text-end" : " level "
                        }
                      >
                        {level}
                      </p>
                    </div>
                    <div className="w-50">
                      <p
                        className={
                          cookies.lang === "ar"
                            ? "activity text-white mb-5 text-end"
                            : " activity text-white mb-5"
                        }
                      >
                        {i18next.t("next_level")}
                      </p>
                      <p
                        className={
                          cookies.lang === "ar"
                            ? "mb-5 mt-4 text-end"
                            : " mb-5 text-start mt-4 "
                        }
                      >
                        <span className="heart text-white">{challenge}</span>
                        <span className="divi"> / </span>
                        <span className="all"> 1</span>
                      </p>
                    </div>
                  </div>
                  <div className="all-char">
                    <div className="level-char">
                      <img
                        src="../assets/imgs/avatar.png"
                        className="img-fluid"
                      />
                      <img
                        src="../assets/imgs/poly.png"
                        className="poly img-fluid"
                      />
                    </div>
                    <h6>Techno Mage</h6>
                    <p>{prog}%</p>
                  </div>
                  {/* <div className="d-flex justify-content-center mt-5 p-4 ">
                    <a className="btn yellow-btn w-100" onClick={click}>
                      <span className="p-2">
                        {i18next.t("view_challenges_btn")}
                      </span>
                      <span>
                        <svg
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                            fill="#100E23"
                          />
                        </svg>
                      </span>
                    </a>
                  </div> */}
                  {role == 6 || role == "6" ? (
                    <div className="d-flex justify-content-center mt-1 p-4 ">
                      <a
                        className="btn yellow-btn w-100"
                        onClick={() => setOpenInprogress(true)}
                      >
                        <span className="p-2">
                          {" "}
                          {i18next.t("view_progress_btn")}
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                              fill="#100E23"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mt-5 p-4 ">
                      <a className="btn yellow-btn w-100" onClick={click}>
                        <span className="p-2">
                          {i18next.t("view_challenges_btn")}
                        </span>
                        <span>
                          <svg
                            width="14"
                            height="15"
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                              fill="#100E23"
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section
            className="container-fluid pdash bg_black pt-4 m-0"
            style={{ paddingLeft: 30, paddingRight: 35 }}
          >
            <div className="container-fluid bg-grey p-4">
              <div className="">
                <a>
                  <span className="float-start text-white">
                    {i18next.t("games")}
                  </span>
                </a>
                <a className="float-end yellow">
                  <span> {i18next.t("browse_all_games")}</span>
                </a>
              </div>
              <br></br>
              <br></br>
              <form className=" form-dark w-100 mb-3">
                <select name="" className="form-select " id="">
                  <option value="44"> {i18next.t("mygame")}</option>
                  <option value="1"> {i18next.t("latest_games")}</option>
                </select>
              </form>
              <div id="games" class="container-fluid p-0 ">
                <div className="row m-auto  pre-brows ">
                  <Game
                    name="V-Racer"
                    active="1"
                    img="../assets/imgs/game.png"
                    isManager={false}
                    url="https://www.roblox.com/games/16743783458/Vracer-DEMO"
                  />
                  {/*<Game*/}
                  {/*  name="V-Racer"*/}
                  {/*  active="0"*/}
                  {/*  img="../assets/imgs/game.png"*/}
                  {/*  isManager={false}*/}
                  {/*/>*/}
                  {/*<Game*/}
                  {/*  name="V-Racer"*/}
                  {/*  active="1"*/}
                  {/*  img="../assets/imgs/game.png"*/}
                  {/*  isManager={false}*/}
                  {/*/>*/}
                  {/*<Game*/}
                  {/*  name="V-Racer"*/}
                  {/*  active="0"*/}
                  {/*  img="../assets/imgs/game.png"*/}
                  {/*  isManager={false}*/}
                  {/*/>*/}
                  {/*<Game*/}
                  {/*  name="V-Racer"*/}
                  {/*  active="1"*/}
                  {/*  img="../assets/imgs/game.png"*/}
                  {/*  isManager={false}*/}
                  {/*/>*/}
                  {/*<Game*/}
                  {/*  name="V-Racer"*/}
                  {/*  active="0"*/}
                  {/*  img="../assets/imgs/game.png"*/}
                  {/*  isManager={false}*/}
                  {/*/>*/}
                </div>
                <div className="brows mt-0">
                  {/*<a className="btn yellow-outline-btn font-13 w-100">*/}
                  {/*  <span className="p-1">*/}
                  {/*    {i18next.t("browse_all_veryality_games")}*/}
                  {/*  </span>*/}
                  {/*  <span>*/}
                  {/*    <svg*/}
                  {/*      width="12"*/}
                  {/*      height="11"*/}
                  {/*      viewBox="0 0 12 11"*/}
                  {/*      fill="none"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path*/}
                  {/*        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"*/}
                  {/*        fill="#EECE52"*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*  </span>*/}
                  {/*</a>*/}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      <Footer bg="bg_black" />
    </div>
  );
}
