import * as React from "react";
import { NavLink } from "react-router-dom";

import { useState, useEffect } from "react";
import en from "../resources/en.json";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import NotifDialog from "./NotifDialog";
import ParentChildChallengesDialog from "./ParentChildChallengesDialog";
import ChildAssigenMissionDialog from "./ChildAssigenMissionDialog";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import { Auth, UserRole, UserLevel } from "../redux/Actions/AuthActions";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";
import logo from "../imgs/logo.png";
import bell from "../imgs/bell.png";
import account from "../imgs/account.png";


export default function Usernav(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const role = useSelector((state) => state.appReducer.role);
  const username = useSelector((state) => state.appReducer.username);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();
  // const id=location.state.id;
  // const [role,setrole]=useState(0);
  var hostName = window.location.pathname;
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  var BaseUrl = config.BaseUrl;
  const [reminds, setreminds] = useState([]);
  let [notifpop, setnotifpop] = useState(false);
  let [childchallengespop, setchildchallengespop] = useState(false);
  let [childassignmissionpop, setchildassignmissionpop] = useState(false);
  let [notif, setnotif] = useState();
  let [child, setchild] = useState();
  const [childNotifications, setchildNotifications] = useState([]);
  const [childNotification_count, setchildNotification_count] = useState(0);
  const [parentNotification_count, setparentNotification_count] = useState(0);
  const level = useSelector((state) => state.appReducer.level);

  const [isChild, setisChild] = useState(false);
  const [childNotification_wait, setchildNotification_wait] = useState(true);
  let [userRole, setuserRole] = useState();
  function goToProfile() {
    if (role == 1) {
      navigate("/profile", { state: {} });
    } else {
      navigate("/profile", { state: {} });
    }
  }
  function goToManagerDash() {
    navigate("/esportDashboard", { state: {} });
  }
  const setLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
  };
  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const changeLangToEn = () => {
    setLanguage("en");
    i18n.changeLanguage("en");
    window.location.reload(false);
  };
  const changeLangToAr = () => {
    setLanguage("ar");
    i18n.changeLanguage("ar");
    window.location.reload(false);
  };
  function goToDashboard() {
    if (userRole == "gamer" || userRole == "child") {
      navigate("/dgamer", { state: {} });
    } else {
      navigate("/parent", { state: {} });
    }
  }
  function goToGamerDashboard() {
    if (userRole == "gamer") {
      navigate("/dgamer", { state: {} });
    } else if (userRole == "child") {
      navigate("/dgamer", { state: {} });
    }
  }
  function goTosignNewChild() {
    navigate("/signchild", { state: {} });
  }
  function goToKidsDashboard() {
    navigate("/parent", { state: {} });
  }
  function goToChildchallenges() {
    setchildchallengespop(true);
  }
  function goToChildAssginMission() {
    setchildassignmissionpop(true);
  }

  function viewnotification(item, child) {
    setnotifpop(true);
    setnotif(item);
    setchild(child);
  }
  function getChildNotif() {
    fetch(`${BaseUrl}UserNotificationGet/${id}/20`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (respChild_notification) {
        if (respChild_notification.status == true) {
          setchildNotifications(respChild_notification.data);
        }
      });
    });

    setchildNotification_count(0);
    fetch(`${BaseUrl}MakeUserNotificationViewed/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (respChild_notification) {
        setchildNotification_wait(false);
      });
    });
  }

  const handleChange = React.useCallback((newValue) => {
    fetch(`${BaseUrl}RemindParentAboutFinishedMissionDate/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (resp_getReminds) {
        if (resp_getReminds.status == true) {
          setreminds(resp_getReminds.data);
          var notifCounts = 0;

          setparentNotification_count(resp_getReminds.data.length);
        }
      });
    });
    props.onChange("david");
  }, []);

  useEffect(() => {
    if (notifpop == true) {
      setTimeout(() => {
        setnotifpop(false);
      }, 1000);
    }
  }, [notifpop]);

  useEffect(() => {
    if (childchallengespop == true) {
      setTimeout(() => {
        setchildchallengespop(false);
      }, 1000);
    }
  }, [childchallengespop]);

  useEffect(() => {
    if (childassignmissionpop == true) {
      setTimeout(() => {
        setchildassignmissionpop(false);
      }, 1000);
    }
  }, [childassignmissionpop]);

  useEffect(() => {
    fetch(`${BaseUrl}users/showinfo/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (userInfo_resp) {
        // we only update level in redux store if it updated
        if(userInfo_resp.data.level && userInfo_resp.data.level != level)
        {
          dispatch(
              UserLevel(
                  userInfo_resp.data.level !== null ? userInfo_resp.data.level : 0
              ));
        }

        if (userInfo_resp.data.roles[0].id == 1) {
          setuserRole("parent");
        } else {
          if (userInfo_resp.data.Parent_id != null) {
            setuserRole("child");
          } else {
            setuserRole("gamer");
          }
        }
        if (
          userInfo_resp.status == true &&
          userInfo_resp.data.Parent_id != null
        ) {
          setisChild(true);
          fetch(`${BaseUrl}NotViewdNotifcationCountGet/${id}`, {
            method: "get",
            body: JSON.stringify(),
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Accept-Language": cookies.lang,
            },
          }).then(function (response) {
            response.json().then(function (respChildNotif_count) {
              if (respChildNotif_count.status == true) {
                setchildNotification_count(respChildNotif_count.data);
              }
            });
          });
        }
      });
    });

    fetch(`${BaseUrl}RemindParentAboutFinishedMissionDate/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (respReminds) {
        if (respReminds.status == true) {
          setreminds(respReminds.data);
          var notifCounts = 0;

          setparentNotification_count(respReminds.data.length);
        }
      });
    });

    if (role == 1 || role == 3) {
    } else {
      var childItemForMobile = document.getElementById("childItemForMobile");
      if (childItemForMobile != undefined && childItemForMobile != null)
        childItemForMobile.parentElement.removeChild(childItemForMobile);
    }
    setLoading(false);

    // fetch(`${BaseUrl}users/userWithRoles/${id}`,{
    //     method:'get',
    //     body:JSON.stringify(),
    //     headers:{
    //         'Accept':'application/json',
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type':'application/json'
    //     }
    //   }).then(function(response){
    //     response.json().then(function(user_roleResp){

    //         setrole(user_roleResp.data[0].roles[0].id)

    //     })
    // })
  }, []);

  if (isLoading) {
    return (
      <div className="load-page ">
        <ReactLoading type="spin" color="#F9AF22" className="load " />
      </div>
    );
  }

  return (
    <section
      className={
        cookies.lang === "ar"
          ? " container-fluid   rtl"
          : " container-fluid   ltr"
      }
    >
      <NotifDialog
        show={notifpop}
        item={notif}
        child={child}
        onChange={handleChange}
      />
      {userRole == "child" ? (
        <ChildAssigenMissionDialog
          show={childassignmissionpop}
          onChange={handleChange}
        />
      ) : (
        ""
      )}
      {userRole !== "child" ? (
        <ParentChildChallengesDialog
          show={childchallengespop}
          onChange={handleChange}
        />
      ) : (
        ""
      )}
      <div className="desktop">
        <div className="row">
          <div className="col-md-1 ">
            <img src={logo} className="" width="166px" height="40px"></img>
          </div>
          <div
            className={
              cookies.lang === "ar"
                ? "col-md-11 text-start font-13 user-nav"
                : "col-md-11 text-end font-13 user-nav"
            }
          >
            <span className="text-white ">
              {i18next.t("usernav_hi")}
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={<Tooltip>{username}</Tooltip>}
              >
                <span className="text-capitalize">
                  {username.length > 27
                    ? username.substring(0, 27) + "..."
                    : username}
                </span>
              </OverlayTrigger>
            </span>
            {role != 5 ? (
              <span className="p-2">
                <div class="dropdown d-inline-block">
                  {isChild == true ? (
                    <button
                      type="button"
                      class="dropdown-toggle drop-account notifs"
                      data-bs-toggle="dropdown"
                      onClick={getChildNotif}
                    >
                      <img src={bell} width="27px" height="27px" className="" />
                      {childNotification_count != 0 ? (
                        <span className="count_notif">
                          {childNotification_count}
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="dropdown-toggle drop-account notifs"
                      data-bs-toggle="dropdown"
                    >
                      <img src={bell} width="27px" height="27px" className="" />
                      {parentNotification_count != 0 ? (
                        <span className="count_notif">
                          {parentNotification_count}
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  )}

                  <ul class="dropdown-menu dropdown-content notif_list ">
                    {isChild == true ? (
                      childNotification_wait == false ? (
                        childNotifications.length != 0 ? (
                          childNotifications.map((item, index) => (
                            <li
                              className={
                                cookies.lang === "ar"
                                  ? item.viewd == 1
                                    ? " no_pointer border-bottom text-end not_viewed"
                                    : " no_pointer border-bottom text-end"
                                  : item.viewd == 1
                                  ? "no_pointer border-bottom text-start not_viewed"
                                  : "no_pointer border-bottom text-start"
                              }
                            >
                              <a class="dropdown-item p-3 ">
                                <span className="notif_tit2">{item.body}</span>
                              </a>
                            </li>
                          ))
                        ) : (
                          <li className="text-center  p-3 pt-5 ">
                            {i18next.t("usernav__noNotification")}
                          </li>
                        )
                      ) : (
                        <li className="text-center p-3 pt-5 ">
                          {i18next.t("usernav_loading")}
                        </li>
                      )
                    ) : reminds.length != 0 ? (
                      reminds.map((item, index) =>
                        item.childs == 0 ? (
                          <li
                            className={
                              cookies.lang === "ar"
                                ? "border-bottom text-end p-3"
                                : "border-bottom text-start p-3"
                            }
                          >
                            {i18next.t("usernav_have")} {item.mission.name}{" "}
                            {i18next.t("usernav_missionExpired")}
                          </li>
                        ) : (
                          <li
                            onClick={() => viewnotification(item, child)}
                            className={
                              cookies.lang === "ar"
                                ? "border-bottom text-end"
                                : "border-bottom text-start"
                            }
                          >
                            <a class="dropdown-item p-3 ">
                              <span className="notif_tit">
                                {i18next.t("usernav_done")}
                              </span>
                              <br />
                              <span className="notif_tit2">
                                {i18next.t("usernav_childFinished")} {item.name}
                                {i18next.t("usernav_mission")}
                              </span>
                            </a>
                          </li>
                        )
                      )
                    ) : (
                      <li className="text-center  p-3 pt-5 ">
                        {i18next.t("usernav__noNotification")}
                      </li>
                    )}
                  </ul>
                </div>
              </span>
            ) : (
              ""
            )}
            {role != 1 && role != 5 ? (
              <span className="level-nav">
                {" "}
                {i18next.t("usernav_level")}
                {level}
              </span>
            ) : (
              ""
            )}
            <span className="profile" onClick={goToProfile}>
              {i18next.t("usernav_profile")}
            </span>
            <span>
              <div class="dropdown d-inline-block">
                <button
                  type="button"
                  class="dropdown-toggle drop-account"
                  data-bs-toggle="dropdown"
                >
                  <img src={account} width="27px" height="27px" />
                </button>
                <ul
                  className={
                    cookies.lang === "ar"
                      ? "menu_rtl dropdown-menu dropdown-content p-1 "
                      : "menu_ltr dropdown-menu dropdown-content p-1"
                  }
                >
                  <li onClick={goToProfile}>
                    <a class="dropdown-item">
                      <svg
                        className="m-1"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.07 16.28C5.5 15.38 8.12 14.5 10 14.5C11.88 14.5 14.51 15.38 14.93 16.28C13.57 17.36 11.86 18 10 18C8.14 18 6.43 17.36 5.07 16.28ZM16.36 14.83C14.93 13.09 11.46 12.5 10 12.5C8.54 12.5 5.07 13.09 3.64 14.83C2.62 13.49 2 11.82 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.82 17.38 13.49 16.36 14.83ZM10 4C8.06 4 6.5 5.56 6.5 7.5C6.5 9.44 8.06 11 10 11C11.94 11 13.5 9.44 13.5 7.5C13.5 5.56 11.94 4 10 4ZM10 9C9.17 9 8.5 8.33 8.5 7.5C8.5 6.67 9.17 6 10 6C10.83 6 11.5 6.67 11.5 7.5C11.5 8.33 10.83 9 10 9Z"
                          fill="#100E23"
                        />
                      </svg>

                      {i18next.t("usernav_profile")}
                    </a>
                  </li>
                  {role == 5 ? (
                    <li onClick={goToManagerDash}>
                      <a class="dropdown-item">
                        <svg
                          className="m-1"
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#100E23"
                          />
                        </svg>

                        {i18next.t("manager_dashboard")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {cookies.lang == "en" ? (
                    <li onClick={changeLangToAr}>
                      <a class="dropdown-item">
                        <svg
                          className="m-1"
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#100E23"
                          />
                        </svg>

                        {i18next.t("ar")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {cookies.lang == "ar" ? (
                    <li onClick={changeLangToEn}>
                      <a class="dropdown-item">
                        <svg
                          className="m-1"
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#100E23"
                          />
                        </svg>

                        {i18next.t("en")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {role != 1 && role != 5 && hostName != "/dgamer" ? (
                    <li onClick={goToGamerDashboard}>
                      <a class="dropdown-item">
                        {hostName == "/parent" ? (
                          <svg
                            className="m-1"
                            width="16"
                            height="22"
                            viewBox="0 0 16 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                              fill="#100E23"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="m-1"
                            width="18"
                            height="22"
                            viewBox="0 0 40 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M39.1599 22.18L36.9799 6.86C36.4199 2.92 33.0399 0 29.0599 0H10.9399C6.95988 0 3.57989 2.92 3.01989 6.86L0.839886 22.18C0.399886 25.26 2.77989 28 5.87989 28C7.23989 28 8.51988 27.46 9.47988 26.5L13.9999 22H25.9999L30.4999 26.5C31.4599 27.46 32.7599 28 34.0999 28C37.2199 28 39.5999 25.26 39.1599 22.18ZM34.9599 23.62C34.7999 23.8 34.5399 24 34.1199 24C33.8199 24 33.5399 23.88 33.3399 23.68L27.6599 18H12.3399L6.65989 23.68C6.45989 23.88 6.17989 24 5.87989 24C5.45989 24 5.19989 23.8 5.03989 23.62C4.87989 23.44 4.71989 23.16 4.77989 22.74L6.95989 7.42C7.25989 5.48 8.95988 4 10.9399 4H29.0599C31.0399 4 32.7399 5.48 33.0199 7.44L35.1999 22.76C35.2599 23.16 35.0999 23.44 34.9599 23.62Z"
                              fill="#100E23"
                            />
                            <path
                              d="M14 6H12V10H8V12H12V16H14V12H18V10H14V6Z"
                              fill="#100E23"
                            />
                            <path
                              d="M30 16C31.1046 16 32 15.1046 32 14C32 12.8954 31.1046 12 30 12C28.8954 12 28 12.8954 28 14C28 15.1046 28.8954 16 30 16Z"
                              fill="#100E23"
                            />
                            <path
                              d="M26 10C27.1046 10 28 9.10457 28 8C28 6.89543 27.1046 6 26 6C24.8954 6 24 6.89543 24 8C24 9.10457 24.8954 10 26 10Z"
                              fill="#100E23"
                            />
                          </svg>
                        )}

                        {hostName == "/parent"
                          ? i18next.t("switch_gamer")
                          : i18next.t("usernav_dashboard")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {role != 2 && role != 5 && hostName != "/parent" ? (
                    <li onClick={goToKidsDashboard}>
                      <a class="dropdown-item">
                        {hostName == "/dgamer" ? (
                          <svg
                            className="m-1"
                            width="16"
                            height="22"
                            viewBox="0 0 16 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                              fill="#100E23"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="m-1"
                            width="17"
                            height="20"
                            viewBox="0 0 17 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.5 0C4.6 0 5.5 0.9 5.5 2C5.5 3.1 4.6 4 3.5 4C2.4 4 1.5 3.1 1.5 2C1.5 0.9 2.4 0 3.5 0ZM12.5 7.5C12.5 8.33 13.17 9 14 9C14.83 9 15.5 8.33 15.5 7.5C15.5 6.67 14.83 6 14 6C13.17 6 12.5 6.67 12.5 7.5ZM15.5 10H12.66C12.08 10.01 11.52 10.32 11.21 10.86L10.29 12.18L6.72 6C6.35 5.37 5.69 5.01 5.01 5H2C0.9 5 0 5.9 0 7V13H1.5V20H6.5V9.61L9.03 14H11.23L12 12.9V20H16V15H17V11.5C17 10.68 16.33 10 15.5 10Z"
                              fill="#100E23"
                            />
                          </svg>
                        )}

                        {hostName == "/dgamer"
                          ? i18next.t("switch_parent")
                          : i18next.t("usernav_kiddashboard")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  {role != 2 && role != 5 ? (
                    <li onClick={goTosignNewChild}>
                      <a class="dropdown-item" id="childItem">
                        <svg
                          className="m-1"
                          width="22"
                          height="16"
                          viewBox="0 0 22 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4ZM10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2C9.1 2 10 2.9 10 4ZM0 14V16H16V14C16 11.34 10.67 10 8 10C5.33 10 0 11.34 0 14ZM2 14C2.2 13.29 5.3 12 8 12C10.69 12 13.78 13.28 14 14H2ZM19 11V8H22V6H19V3H17V6H14V8H17V11H19Z"
                            fill="#100E23"
                          />
                        </svg>
                        {i18next.t("usernav_createChild")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {role != 2 && role != 5 ? (
                    <li onClick={goToChildchallenges}>
                      <a class="dropdown-item" id="childItem">
                        <svg
                          className="m-1"
                          width="21"
                          height="17"
                          viewBox="0 0 21 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.54 7.99999L11 4.45999L12.41 3.04999L14.53 5.16999L18.77 0.929993L20.18 2.33999L14.54 7.99999ZM9 3.99999H0V5.99999H9V3.99999ZM19 10.41L17.59 8.99999L15 11.59L12.41 8.99999L11 10.41L13.59 13L11 15.59L12.41 17L15 14.41L17.59 17L19 15.59L16.41 13L19 10.41ZM9 12H0V14H9V12Z"
                            fill="#100E23"
                          />
                        </svg>
                        {i18next.t("usernav_childchallengesneedapprove")}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {/*{isChild == true ? (*/}
                  {/*  <li onClick={goToChildAssginMission}>*/}
                  {/*    <a class="dropdown-item" id="childItem">*/}
                  {/*      <svg*/}
                  {/*        className="m-1"*/}
                  {/*        width="21"*/}
                  {/*        height="17"*/}
                  {/*        viewBox="0 0 21 17"*/}
                  {/*        fill="none"*/}
                  {/*        xmlns="http://www.w3.org/2000/svg"*/}
                  {/*      >*/}
                  {/*        <path*/}
                  {/*          d="M14.54 7.99999L11 4.45999L12.41 3.04999L14.53 5.16999L18.77 0.929993L20.18 2.33999L14.54 7.99999ZM9 3.99999H0V5.99999H9V3.99999ZM19 10.41L17.59 8.99999L15 11.59L12.41 8.99999L11 10.41L13.59 13L11 15.59L12.41 17L15 14.41L17.59 17L19 15.59L16.41 13L19 10.41ZM9 12H0V14H9V12Z"*/}
                  {/*          fill="#100E23"*/}
                  {/*        />*/}
                  {/*      </svg>*/}
                  {/*      {i18next.t("usernav_childassignmission")}*/}
                  {/*    </a>*/}
                  {/*  </li>*/}
                  {/*) : (*/}
                  {/*  ""*/}
                  {/*)}*/}
                  <li>
                    <a href="/veryalityApp" class="dropdown-item">
                      <svg
                        className="m-1"
                        width="20"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 2V18H2V2H14ZM14 0H2C0.9 0 0 0.9 0 2V20H16V2C16 0.9 15.1 0 14 0ZM11.5 8.5C10.67 8.5 10 9.17 10 10C10 10.83 10.67 11.5 11.5 11.5C12.33 11.5 13 10.83 13 10C13 9.17 12.33 8.5 11.5 8.5Z"
                          fill="#100E23"
                        />
                      </svg>

                      {i18next.t("usernav_logout")}
                    </a>
                  </li>
                </ul>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="row">
          <div className="col-md-6 text-start" style={{maxWidth:"50%"}}>
            <img src={logo} className="" width="160px" height="40px"></img>
          </div>
          <div
            className={
              cookies.lang === "ar"
                ? "col-md-6 mob_ar font-13 user-nav"
                : "col-md-6 mob_en font-13 user-nav"
            }
            style={{maxWidth:"50%"}}
          >
            {role != 5 ? (
              <span className="">
                <div class="dropdown d-inline-block">
                  {isChild == true ? (
                    <button
                      type="button"
                      class="dropdown-toggle drop-account notifs"
                      data-bs-toggle="dropdown"
                      onClick={getChildNotif}
                    >
                      <img src={bell} width="27px" height="27px" className="" />
                      {childNotification_count != 0 ? (
                        <span className="count_notif">
                          {childNotification_count}
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="dropdown-toggle drop-account notifs"
                      data-bs-toggle="dropdown"
                    >
                      <img src={bell} width="27px" height="27px" className="" />
                      {parentNotification_count != 0 ? (
                        <span className="count_notif">
                          {parentNotification_count}
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  )}
                  <ul class="dropdown-menu dropdown-content notif_list ">
                    {isChild == true ? (
                      childNotification_wait == false ? (
                        childNotifications.length != 0 ? (
                          childNotifications.map((item, index) => (
                            <li
                              className={
                                item.viewd == 1
                                  ? "no_pointer border-bottom text-start not_viewed"
                                  : "no_pointer border-bottom text-start"
                              }
                            >
                              <a class="dropdown-item p-3 ">
                                <span className="notif_tit2">{item.body}</span>
                              </a>
                            </li>
                          ))
                        ) : (
                          <li className="text-center  p-3 pt-5 ">
                            {i18next.t("usernav__noNotification")}
                          </li>
                        )
                      ) : (
                        <li className="text-center p-3 pt-5 ">
                          {i18next.t("usernav_loading")}
                        </li>
                      )
                    ) : reminds.length != 0 ? (
                      reminds.map((item, index) =>
                        item.user == null ? (
                          <li className="border-bottom text-start p-3">
                            {i18next.t("usernav_have")}
                            {item.name} {i18next.t("usernav_missionExpired")}
                          </li>
                        ) : (
                          <li
                            onClick={() => viewnotification(item, child)}
                            className="border-bottom text-start"
                          >
                            <a class="dropdown-item p-3 ">
                              <span className="notif_tit">
                                {i18next.t("usernav_done")}
                              </span>
                              <br />
                              <span className="notif_tit2">
                                {i18next.t("usernav_childFinished")}
                                {item.name} {i18next.t("usernav_mission")}
                              </span>
                            </a>
                          </li>
                        )
                      )
                    ) : (
                      <li className="text-center  p-3 pt-5 ">
                        {i18next.t("usernav__noNotification")}
                      </li>
                    )}
                  </ul>
                </div>
              </span>
            ) : (
              ""
            )}
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="dropdown-toggle drop-account"
                data-bs-toggle="dropdown"
              >
                <img src={account} width="27px" height="27px" />
              </button>
              <ul class="dropdown-menu">
                <li onClick={goToProfile}>
                  <a
                    className={
                      cookies.lang === "ar"
                        ? "dropdown-item mob_ar"
                        : "dropdown-item"
                    }
                  >
                    <svg
                      className="m-1"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.07 16.28C5.5 15.38 8.12 14.5 10 14.5C11.88 14.5 14.51 15.38 14.93 16.28C13.57 17.36 11.86 18 10 18C8.14 18 6.43 17.36 5.07 16.28ZM16.36 14.83C14.93 13.09 11.46 12.5 10 12.5C8.54 12.5 5.07 13.09 3.64 14.83C2.62 13.49 2 11.82 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.82 17.38 13.49 16.36 14.83ZM10 4C8.06 4 6.5 5.56 6.5 7.5C6.5 9.44 8.06 11 10 11C11.94 11 13.5 9.44 13.5 7.5C13.5 5.56 11.94 4 10 4ZM10 9C9.17 9 8.5 8.33 8.5 7.5C8.5 6.67 9.17 6 10 6C10.83 6 11.5 6.67 11.5 7.5C11.5 8.33 10.83 9 10 9Z"
                        fill="#100E23"
                      />
                    </svg>

                    {i18next.t("usernav_profile")}
                  </a>
                </li>
                {role == 5 ? (
                  <li onClick={goToManagerDash}>
                    <a
                      className={
                        cookies.lang === "ar"
                          ? "dropdown-item mob_ar"
                          : "dropdown-item"
                      }
                    >
                      <svg
                        className="m-1"
                        width="16"
                        height="22"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                          fill="#100E23"
                        />
                      </svg>

                      {i18next.t("manager_dashboard")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {cookies.lang == "en" ? (
                  <li onClick={changeLangToAr}>
                    <a
                      className={
                        cookies.lang === "ar"
                          ? "dropdown-item mob_ar"
                          : "dropdown-item"
                      }
                    >
                      <svg
                        className="m-1"
                        width="16"
                        height="22"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                          fill="#100E23"
                        />
                      </svg>

                      {i18next.t("ar")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {cookies.lang == "ar" ? (
                  <li onClick={changeLangToEn}>
                    <a
                      className={
                        cookies.lang === "ar"
                          ? "dropdown-item mob_ar"
                          : "dropdown-item"
                      }
                    >
                      <svg
                        className="m-1"
                        width="16"
                        height="22"
                        viewBox="0 0 16 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                          fill="#100E23"
                        />
                      </svg>

                      {i18next.t("en")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {role != 1 && role != 5 && hostName != "/dgamer" ? (
                  <li onClick={goToGamerDashboard}>
                    <a class="dropdown-item">
                      {hostName == "/parent" ? (
                        <svg
                          className="m-1"
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#100E23"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="m-1"
                          width="18"
                          height="22"
                          viewBox="0 0 40 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M39.1599 22.18L36.9799 6.86C36.4199 2.92 33.0399 0 29.0599 0H10.9399C6.95988 0 3.57989 2.92 3.01989 6.86L0.839886 22.18C0.399886 25.26 2.77989 28 5.87989 28C7.23989 28 8.51988 27.46 9.47988 26.5L13.9999 22H25.9999L30.4999 26.5C31.4599 27.46 32.7599 28 34.0999 28C37.2199 28 39.5999 25.26 39.1599 22.18ZM34.9599 23.62C34.7999 23.8 34.5399 24 34.1199 24C33.8199 24 33.5399 23.88 33.3399 23.68L27.6599 18H12.3399L6.65989 23.68C6.45989 23.88 6.17989 24 5.87989 24C5.45989 24 5.19989 23.8 5.03989 23.62C4.87989 23.44 4.71989 23.16 4.77989 22.74L6.95989 7.42C7.25989 5.48 8.95988 4 10.9399 4H29.0599C31.0399 4 32.7399 5.48 33.0199 7.44L35.1999 22.76C35.2599 23.16 35.0999 23.44 34.9599 23.62Z"
                            fill="#100E23"
                          />
                          <path
                            d="M14 6H12V10H8V12H12V16H14V12H18V10H14V6Z"
                            fill="#100E23"
                          />
                          <path
                            d="M30 16C31.1046 16 32 15.1046 32 14C32 12.8954 31.1046 12 30 12C28.8954 12 28 12.8954 28 14C28 15.1046 28.8954 16 30 16Z"
                            fill="#100E23"
                          />
                          <path
                            d="M26 10C27.1046 10 28 9.10457 28 8C28 6.89543 27.1046 6 26 6C24.8954 6 24 6.89543 24 8C24 9.10457 24.8954 10 26 10Z"
                            fill="#100E23"
                          />
                        </svg>
                      )}

                      {hostName == "/parent"
                        ? i18next.t("switch_gamer")
                        : i18next.t("usernav_dashboard")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {role != 2 && role != 5 && hostName != "/parent" ? (
                  <li onClick={goToKidsDashboard}>
                    <a  className={
                      cookies.lang === "ar"
                        ? "dropdown-item mob_ar"
                        : "dropdown-item"
                    }>
                      {hostName == "/dgamer" ? (
                        <svg
                          className="m-1"
                          width="16"
                          height="22"
                          viewBox="0 0 16 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 3V0L4 4L8 8V5C11.31 5 14 7.69 14 11C14 12.01 13.75 12.97 13.3 13.8L14.76 15.26C15.54 14.03 16 12.57 16 11C16 6.58 12.42 3 8 3ZM8 17C4.69 17 2 14.31 2 11C2 9.99 2.25 9.03 2.7 8.2L1.24 6.74C0.46 7.97 0 9.43 0 11C0 15.42 3.58 19 8 19V22L12 18L8 14V17Z"
                            fill="#100E23"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="m-1"
                          width="17"
                          height="20"
                          viewBox="0 0 17 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.5 0C4.6 0 5.5 0.9 5.5 2C5.5 3.1 4.6 4 3.5 4C2.4 4 1.5 3.1 1.5 2C1.5 0.9 2.4 0 3.5 0ZM12.5 7.5C12.5 8.33 13.17 9 14 9C14.83 9 15.5 8.33 15.5 7.5C15.5 6.67 14.83 6 14 6C13.17 6 12.5 6.67 12.5 7.5ZM15.5 10H12.66C12.08 10.01 11.52 10.32 11.21 10.86L10.29 12.18L6.72 6C6.35 5.37 5.69 5.01 5.01 5H2C0.9 5 0 5.9 0 7V13H1.5V20H6.5V9.61L9.03 14H11.23L12 12.9V20H16V15H17V11.5C17 10.68 16.33 10 15.5 10Z"
                            fill="#100E23"
                          />
                        </svg>
                      )}

                      {hostName == "/dgamer"
                        ? i18next.t("switch_parent")
                        : i18next.t("usernav_kiddashboard")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {role != 2 && role != 5 ? (
                  <li onClick={goTosignNewChild}>
                    <a
                      className={
                        cookies.lang === "ar"
                          ? "dropdown-item mob_ar"
                          : "dropdown-item"
                      }
                      id="childItem"
                    >
                      <svg
                        className="m-1"
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4ZM10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2C9.1 2 10 2.9 10 4ZM0 14V16H16V14C16 11.34 10.67 10 8 10C5.33 10 0 11.34 0 14ZM2 14C2.2 13.29 5.3 12 8 12C10.69 12 13.78 13.28 14 14H2ZM19 11V8H22V6H19V3H17V6H14V8H17V11H19Z"
                          fill="#100E23"
                        />
                      </svg>
                      {i18next.t("usernav_createChild")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {role != 2 && role != 5 ? (
                  <li onClick={goToChildchallenges}>
                    <a
                      className={
                        cookies.lang === "ar"
                          ? "dropdown-item mob_ar"
                          : "dropdown-item"
                      }
                      id="childItem"
                    >
                      <svg
                        className="m-1"
                        width="21"
                        height="17"
                        viewBox="0 0 21 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.54 7.99999L11 4.45999L12.41 3.04999L14.53 5.16999L18.77 0.929993L20.18 2.33999L14.54 7.99999ZM9 3.99999H0V5.99999H9V3.99999ZM19 10.41L17.59 8.99999L15 11.59L12.41 8.99999L11 10.41L13.59 13L11 15.59L12.41 17L15 14.41L17.59 17L19 15.59L16.41 13L19 10.41ZM9 12H0V14H9V12Z"
                          fill="#100E23"
                        />
                      </svg>
                      {i18next.t("usernav_childchallengesneedapprove")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {isChild == true ? (
                  <li onClick={goToChildAssginMission}>
                    <a
                      className={
                        cookies.lang === "ar"
                          ? "dropdown-item mob_ar"
                          : "dropdown-item"
                      }
                      id="childItem"
                    >
                      <svg
                        className="m-1"
                        width="21"
                        height="17"
                        viewBox="0 0 21 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.54 7.99999L11 4.45999L12.41 3.04999L14.53 5.16999L18.77 0.929993L20.18 2.33999L14.54 7.99999ZM9 3.99999H0V5.99999H9V3.99999ZM19 10.41L17.59 8.99999L15 11.59L12.41 8.99999L11 10.41L13.59 13L11 15.59L12.41 17L15 14.41L17.59 17L19 15.59L16.41 13L19 10.41ZM9 12H0V14H9V12Z"
                          fill="#100E23"
                        />
                      </svg>
                      {i18next.t("usernav_childassignmission")}
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <a
                    href="/veryalityApp"
                    className={
                      cookies.lang === "ar"
                        ? "dropdown-item mob_ar"
                        : "dropdown-item"
                    }
                  >
                    <svg
                      className="m-1"
                      width="20"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 2V18H2V2H14ZM14 0H2C0.9 0 0 0.9 0 2V20H16V2C16 0.9 15.1 0 14 0ZM11.5 8.5C10.67 8.5 10 9.17 10 10C10 10.83 10.67 11.5 11.5 11.5C12.33 11.5 13 10.83 13 10C13 9.17 12.33 8.5 11.5 8.5Z"
                        fill="#100E23"
                      />
                    </svg>

                    {i18next.t("usernav_logout")}
                  </a>
                </li>{" "}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
