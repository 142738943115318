import * as React from "react";
import { Component } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
export default function DeleteAssignmentDialog(props) {
  const token = useSelector((state) => state.appReducer.token);
  const [cookies, setCookie] = useCookies(['lang']);
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  let [show, setShow] = useState(props.show);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);
  let [showbutton, setshowbutton] = useState(false);

  // const id=props.id;

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    props.onChange("david");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${BaseUrl}DeleteAssignmentByParent/${props.userMissionId}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (resp_deletemission) {
        setLoading(false);
        if (resp_deletemission.status == true) {
          document.getElementById("delete_mission1").innerHTML =
          i18next.t('deletesuccess');
          document.getElementById("delete_mission1").classList.remove("d-none");
          document
            .getElementById("delete_mission1")
            .classList.add("alert-info");
            setshowbutton(true);

        } else if (resp_deletemission.status == false) {
          document.getElementById("delete_mission1").innerHTML =
            resp_deletemission.msg;
          document.getElementById("delete_mission1").classList.remove("d-none");
          document
            .getElementById("delete_mission1")
            .classList.add("alert-danger");
        } else {
          document.getElementById("delete_mission1").innerHTML =
          i18next.t('deletefailed');
          document.getElementById("delete_mission1").classList.remove("d-none");
          document
            .getElementById("delete_mission1")
            .classList.add("alert-info");
        }
      });
    });
  };

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle">
            <h5 className="text-start font p-3">
            {i18next.t('Assignment')}
            </h5>
            <form className=" m-auto form-white">
              {loading == true ? (
                <div className="w-100 mt-3 mb-2 m-auto">
                  <CircularProgress />
                </div>
              ) : (
                <div
                  id="delete_mission1"
                  className="w-100 mt-4 mb-2 m-auto d-none p-3"></div>
              )}
              <Modal.Footer>
                <Button
                  type="submit"
                  className="bg_blue w-40"
                  variant="primary"
                  disabled={showbutton}
                  onClick={handlesubmit}>
                  {i18next.t('confirm')} 
                </Button>
                <Button className=" white-outline-btn1" onClick={handleClose}>
                {i18next.t("close")}
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
