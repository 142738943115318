 import {TOKEN} from './Types'
 import {ROLE} from './Types'
 import {LEVEL} from './Types'
 import {TournamentID} from './Types'

export const Auth = (token,id,username) => {
    return {
      type: TOKEN,
      payload: {
        id:id,
        username: username,
        token:token
      },
    };
  };

  export const UserRole = (role) => {
    return {
      type: ROLE,
      payload: {
        role:role
      },
    };
  };

  export const UserLevel = (level) => {
    return {
      type: LEVEL,
      payload: {
        level:level
      },
    };
  };
  export const SetTournamentID = (tournamentID) => {
    return {
      type: TournamentID,
      payload: {
        TournamentID:tournamentID
      },
    };
  };