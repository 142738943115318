import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import ViewTournametRout from "./ViewTournametRout";

export default function InprogressGamerModal(props) {
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [status, setstatus] = useState(0);
  let [showRoute, setshowRoute] = useState(false);
  let [tournaments, setTournaments] = useState(props.inprogress || []);
  let [show, setShow] = useState(props.show);
  let [RoutePoints, setRoutePoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
      setTournaments(props.inprogress);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    // props.onChange('david');
  };
  const handleShowRoute = () => {
    if (RoutePoints && RoutePoints.length>0){
      setshowRoute(true);
      setTimeout(() => {
        setshowRoute(false);
      }, 1000);
    }


  };
    useEffect(() => {
      setLoading(true);
       fetch(`${BaseUrl}getUserRoute/${id}`, {
      // fetch(`${BaseUrl}getUserRoute/7`, {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Accept-Language": cookies.lang,
        },
      }).then(function (response11) {
        response11.json().then(function (Resp) {
          setLoading(false);
          if (Resp.status == true) {

            setRoutePoints(Resp.data);
          }
        });
      });
    }, []);
  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle p-3">
            <p class="margin_p">{i18next.t("tournament_challenges")}</p>
            {/* <div className="row " id="kid_assign"> */}
            {/* <div className="col-md-12 kid-char pb-0"> */}
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                className={
                  cookies.lang === "ar" ? "rtl row new_p" : "ltr row new_p"
                }
                style={{
                  margin: "1px",
                  justify_content: "center",
                  align_items: "center",
                }}>
                {tournaments && tournaments.length > 0 ? (
                  tournaments.map((item, index) => (
                    <div
                      className={
                        cookies.lang === "ar"
                          ? "col-md-2 m-2 container_card_gamer text-end "
                          : "col-md-2 m-2 container_card_gamer text-start "
                      }
                      style={{ position: "relative", width: "100%" }}>
                      <div className="gamer_card ">
                        <img
                          src={'../assets/imgs/teamback.jpeg'}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}></img>
                      </div>
                      <div className="black-layer-gamer p-3">
                        <div className="row">

                          <div className="col-3">
                            <div className="justify-content-end">
                              {RoutePoints && RoutePoints.length>0?( <a  className="p-3" onClick={handleShowRoute}>
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={<Tooltip>View route</Tooltip>}>
                                  <img
                                      src={"../assets/imgs/route.png"}
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        float: "left",
                                      }}
                                  />
                                </OverlayTrigger>
                              </a>):(
                                  <a  className="p-3">
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={<Tooltip>No route to view</Tooltip>}>
                                      <img
                                          src={"../assets/imgs/route.png"}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            float: "left",
                                          }}
                                      />
                                    </OverlayTrigger>
                                  </a>
                              )}

                            </div>

                          </div>
                          <div className="col-6">
                            <div className="d-flex justify-content-center">
                              <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={<Tooltip>{item.Goal}</Tooltip>}>
                                <p
                                    className="letters_limit text-white "
                                    style={{ marginTop: "20px" }}>
                                  {en.Esport.Goal}
                                  <span className="desc m-2">
                              {item.name.length > 20
                                  ? item.Goal.substring(0, 17) + "..."
                                  : item.Goal}
                            </span>
                                </p>
                              </OverlayTrigger>
                            </div>

                          </div>

                          <div className="col-3" >
                            {item.isQualified ? (
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={<Tooltip>Qualified</Tooltip>}>
                                  <img
                                      src={"../assets/imgs/quality.png"}
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        float: "right",
                                      }}
                                  />
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={<Tooltip>Not Qualified</Tooltip>}>
                                  <img
                                      src={"../assets/imgs/notQualified.png"}
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        float: "right",
                                      }}
                                  />
                                </OverlayTrigger>
                            )}
                          </div>
                        </div>

                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip>{item.name}</Tooltip>}>
                          <p
                            className="letters_limit text-white "
                            style={{ marginTop: "20px" }}>
                            {i18next.t('tournament_name')}
                            <span className="desc m-2">
                              {item.name.length > 20
                                ? item.name.substring(0, 17) + "..."
                                : item.name}
                            </span>
                          </p>
                        </OverlayTrigger>
                        {/* <p className="letters_limit text-white ">
                          Challenges:{" "}
                        </p> */}
                        {item.challenges && item.challenges.length > 0
                          ? item.challenges.map((ch) => (
                              <>
                                <p className="letters_limit text-white ">
                                {i18next.t('challeng_name')} {" "}
                                  <span className="desc ">
                                    {ch.challeng_name}
                                  </span>
                                </p>

                                <p className="letters_limit text-white ">
                                {i18next.t('challeng_startDate')} {" "}
                                  <span className="desc ">
                                    {ch.challeng_startDate}
                                  </span>
                                </p>{" "}
                                <p className="letters_limit text-white">
                                {i18next.t('challeng_endDate')}{" "}
                                  <span className="desc ">
                                    {ch.challeng_endDate}
                                  </span>
                                </p>
                                <LinearProgressWithLabel value={ch.Challeng_Inprogress} />
                              </>
                            ))
                          : i18next.t('no_challenge') }
                        
                        <div className="row " id="kid_assign">
                          <div className="col-md-12  pb-0">
                            <div
                              className="row_new_s row_new_new m-auto "
                              style={{
                                position: "absolute",
                                bottom: 0,
                                width: "inherit",
                              }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="  text-white text-center container  ">
                    {/* {en.dgamer.noReward} */}
                    {i18next.t("noChallenge")}
                  </div>
                )}
              </div>
            )}
            {/* </div> */}
            {/* </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
            </Button>
            <div
              id="finish_mission1"
              className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
          </Modal.Footer>
        </Modal>
        <ViewTournametRout show={showRoute} places={RoutePoints}></ViewTournametRout>



      </div>
    </>
  );
}
