import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import en from '../resources/en.json'
import TournamentchallengesModal from '../ESport/TournamentchallengesModal'
import config from "../config.json";
import ViewTournamentModal from '../ESport/ViewTournamentModal';
import { useDispatch, useSelector } from "react-redux";
import i18next from '../i18n/config';
import { useCookies } from 'react-cookie';
import AssignTeamToTournamentModal from "../ESport/AssignTeamToTournamentModal";
import {patternDotsDef} from "@nivo/core";
// import { useState, useEffect } from "react";


export default function Game(props)  {
    // const navigate = useNavigate();
    let BaseUrl = config.BaseUrl;
    var hostName =window.location.origin;
    // var BaseUrl="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
    let [viewTeamDetailsPop, setViewTeamDetailsPop] = useState(false);
    let [viewPop, setviewPop] = useState(false);
    let [viewassigntournement, setviewassigntournement] = useState(false);
    const [cookies, setCookie] = useCookies(['lang']);
    const role = useSelector((state) => state.appReducer.role);
    const [width, setwidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setwidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
      if (viewPop == true) {
        setTimeout(() => {
          setviewPop(false);
        }, 1000);
      }
    }, [viewPop]);

  function clickviewchallenges(){
    setviewPop(true);
  }

  function clickassigntournament(){
    setviewassigntournement(true);
  }

  useEffect(() => {
    if (viewPop == true) {
      setTimeout(() => {
        setviewPop(false);
      }, 1000);
    }
  }, [viewPop]);
  useEffect(() => {
    if (viewassigntournement == true) {
      setTimeout(() => {
        setviewassigntournement(false);
      }, 1000);
    }
  }, [viewassigntournement]);
  useEffect(() => {
    if (viewTeamDetailsPop == true) {
      setTimeout(() => {
        setViewTeamDetailsPop(false);
      }, 1000);
    }
  }, [viewTeamDetailsPop]);
  const handleChange = React.useCallback((newValue) => {
    // localStorage.removeItem("TournamentID");
    // fetch(`${BaseUrl}teams/${id}`, {
    //   method: "get",
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //     'Accept-Language': 'en',
    //   },
    // }).then(function (response11) {
    //   response11.json().then(function (Resp) {
    //     setTeams(Resp.data);
    //   });
    // });
  });
return (

        <div className= {width>900?'col-md-4   game bg_black m-sm-0 mb-2 m-md-2':'col-lg-2   game bg_black m-sm-0 mb-2 m-md-2'} >

      {role==5?(
        <>
       <TournamentchallengesModal show={viewPop} Tournament_id={props.Tournament_id}/>
       <ViewTournamentModal show={viewTeamDetailsPop} TournamentID={props.Tournament_id} Tournamentname={props.name} logo={props.img} number_of_team_allowed={props.number_of_team_allowed} min_team_level={props.min_team_level} min_team_members_level={props.min_team_members_level}/>
       <AssignTeamToTournamentModal
          show={viewassigntournement}
          tournamentID={props.Tournament_id}
          onChange={handleChange}
        />
      </>
      ):''
    }
    <div class="card remove_index">
       <div class="card-header">
           <span style={{paddingLeft:10,paddingRight:10}} >{props.name}</span>
           { props.isManager==true?
           <a className='float-end'>
               <div class="dropdown">
                      <button
                          type="button"
                           class="btn "
                          data-bs-toggle="dropdown"
                      >
                      <span>
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="32" height="32" rx="16" fill="#100E23" />
                          <path
                              d="M16 12C17.1 12 18 11.1 18 10C18 8.9 17.1 8 16 8C14.9 8 14 8.9 14 10C14 11.1 14.9 12 16 12ZM16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14ZM16 20C14.9 20 14 20.9 14 22C14 23.1 14.9 24 16 24C17.1 24 18 23.1 18 22C18 20.9 17.1 20 16 20Z"
                              fill="#EEE056"
                          />
                        </svg>
                      </span>
                      </button>

                      <ul className={cookies.lang  === 'ar' ? 'dropdown-menu p_l' : 'dropdown-menu'}>
                        <li className="" ><a class="dropdown-item point" onClick={()=>{setViewTeamDetailsPop(true);}}>

                          {/* <svg className="m-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#100E23"/>
                          </svg> */}

                          {i18next.t('view_tournament_details')}
                          </a>
                        </li>
                        <li className="" ><a class="dropdown-item point" onClick={clickviewchallenges}>

                         {/* <svg className="m-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#100E23"/>
                         </svg> */}

                         {i18next.t('view_challenges')}
                         </a>
                       </li>

                       <li className="" ><a class="dropdown-item point" onClick={clickassigntournament}>

                         {/* <svg className="m-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#100E23"/>
                         </svg> */}

                         {i18next.t('Assign_tournament')}
                         </a>
                       </li>
                      </ul>
                </div>
           </a>:''}
       </div>
       <div class="card-body">
           <img src={props.img} className={props.active==1 ?'img-fluid' :'img-fluid notactive'}/>
       </div>
       <div class="card-footer">
           {/*{props.active==1 ? <p className='yellow'>{i18next.t('game_activated')} </p>: <p className='grey'>{i18next.t('game_notactivated')} </p>}*/}
           {props.active==1 ? <a className='btn blue-btn d-block'onClick={()=>window.open(props.url, "_blank")}>
                <span className='p-3' dir={cookies.lang=="ar"?"rtl":"ltr"}>
                {i18next.t('game_powerup')}
                </span>
                <span>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.666016 0.333313V7.66665H2.66602V13.6666L7.33268 5.66665H4.66602L6.66602 0.333313H0.666016Z" fill="white"/>
                    </svg>
                </span>
               </a>
             : <a className='btn yellow-btn d-block'>
                    <span className='p-3'>
                    {i18next.t('activate_btn')}
                    </span>
                     <span>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.66732 1.66665V3.44665L7.00065 4.11331L6.33398 3.44665V1.66665H7.66732ZM12.334 6.33331V7.66665H10.554L9.88732 6.99998L10.554 6.33331H12.334ZM3.44732 6.33331L4.11398 6.99998L3.44732 7.66665H1.66732V6.33331H3.44732ZM7.00065 9.88665L7.66732 10.5533V12.3333H6.33398V10.5533L7.00065 9.88665ZM9.00065 0.333313H5.00065V3.99998L7.00065 5.99998L9.00065 3.99998V0.333313ZM13.6673 4.99998H10.0007L8.00065 6.99998L10.0007 8.99998H13.6673V4.99998ZM4.00065 4.99998H0.333984V8.99998H4.00065L6.00065 6.99998L4.00065 4.99998ZM7.00065 7.99998L5.00065 9.99998V13.6666H9.00065V9.99998L7.00065 7.99998Z" fill="#100E23"/>
                        </svg>
                    </span>
                </a>}


       </div>
    </div>
  </div>

  );

}
