import * as React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Footer from "../layout/Footer";
import en from "../resources/en.json";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";
import Usernav from "../layout/Usernav";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactLoading from "react-loading";
import {
  LinearProgressProps,
  LinearProgress,
  Typography,
  Box,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <Box sx={{ minWidth: 25 }}>
        <Typography variant="body2">{`${Math.round(props.value)}`}</Typography>
      </Box> */}
      <Box className="review_progress" sx={{ width: "80%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35, color: "#F2C94C" }}>
        <Typography variant="body2">
          <span style={{ padding: "5px" }}>{props.value}%</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default function InprogressTeam() {
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  const location = useLocation();
  const username = useSelector((state) => state.appReducer.username);
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  const [teams, setTeams] = useState([]);

  let [refresh, setRefresh] = useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };
  const handleChange = React.useCallback((newValue) => {
    setRefresh(true);
    localStorage.removeItem("TournamentID");
  });
  const params = useParams();
  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: width > 500 ? "absolute" : "relative",
    top: width > 500 ? -80 : 5,
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  }));

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (token === "") {
      navigate("/veryalityApp");
    }
    setLoading(true);
    fetch(`${BaseUrl}Team_InProgress/${params.id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (resp) {
        setLoading(false);
        if (resp.status == true) {
          setTeams(resp.data);
        }
      });
    });
  }, [cookies.lang]);

  if (loading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }
  return (
    <div>
      <Head />

      <section
        className="container-fluid pdash bg_black pt-4 m-0 "
        style={{ paddingLeft: 30, paddingRight: 35 }}
      >
        <Usernav username={username} />

        <div className="container bg-grey  p-4 mt-3">
          <div className="row m-auto ">
            {teams && teams.length > 0 ? (
              teams.map((item) => (
                <div
                  className="col-md-6 col-sm-12 mb-3"
                  style={{ position: "relative" }}
                >
                  <div
                    className="p-3 progress_card2 point"
                    onClick={() =>
                      navigate(
                        `/inprogressTeamDetails/${params.id}/tournament/${item.id}`,
                        {
                          state: {},
                        }
                      )
                    }
                  >
                    <span>
                      <StyledSpeedDial
                        ariaLabel="SpeedDial playground example"
                        hidden={hidden}
                        icon={
                          <img
                            src="../assets/imgs/members.png"
                            className="members_icon m-1"
                            width={"36px"}
                          />
                        }
                        direction={cookies.lang === "en" ? "left" : "right"}
                      >
                        {item && item.members && item.members.length > 0
                          ? item.members.map((mem) => (
                              <SpeedDialAction
                                key={mem.name}
                                icon={
                                  <img
                                    src={mem.logo}
                                    width={"100%"}
                                    height={"100%"}
                                    style={{ borderRadius: "50%" }}
                                  />
                                }
                                tooltipTitle={
                                  <>
                                    {mem.name}
                                    <br /> {mem.percentage}%
                                  </>
                                }
                                sx={{
                                  p: 0,
                                  width: { md: "40px", sm: "30px", xs: "20px" },
                                  height: {
                                    md: "40px",
                                    sm: "30px",
                                    xs: "20px",
                                  },
                                }}
                              />
                            ))
                          : ""}
                      </StyledSpeedDial>
                    </span>
                    {/* <br/><br/> */}
                    <div
                      className={
                        cookies.lang === "en"
                          ? "text-start mb-3 "
                          : "text-end mb-3 rtl"
                      }
                    >
                      <Typography variant="body1">
                        <span className="progress_title">
                          {i18next.t("challenge_type")}
                        </span>
                        <span>
                          {item.challenges.length > 0
                            ? item.challenges[0].challeng_name
                            : ""}
                        </span>
                      </Typography>
                      <Typography variant="body1">
                        <span className="progress_title">
                          {i18next.t("challenge_mode")}
                        </span>
                        <span>
                          {item.challenges.length > 0
                            ? item.challenges[0].challeng_type == "1"
                              ? i18next.t("for_team")
                              : i18next.t("for_gamer")
                            : ""}
                        </span>
                      </Typography>
                      <Typography variant="body1">
                        <span className="progress_title">
                          {i18next.t("challenge_deadline")}
                        </span>
                        <span>
                          {item.challenges.length > 0
                            ? item.challenges[0].DeadLine
                            : ""}{" "}
                          {i18next.t("days")}
                        </span>
                      </Typography>
                    </div>
                    <span className="m-auto  div_hover">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>{item.status}</Tooltip>}
                      >
                        <img
                          src="../assets/imgs/status.png"
                          className="hover_icon  m-1"
                          width={"36px"}
                        />
                      </OverlayTrigger>
                    </span>
                    <span className="m-auto  div_hover">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            {i18next.t("tournament_name") + item.name}
                          </Tooltip>
                        }
                      >
                        <img
                          src="../assets/imgs/challenge.png"
                          className="hover_icon  m-1"
                          width={"36px"}
                        />
                      </OverlayTrigger>
                    </span>
                    <span className="m-auto  div_hover">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            {i18next.t("challengers_numbers") +
                              item.no_challengers}
                          </Tooltip>
                        }
                      >
                        <img
                          src="../assets/imgs/people.png"
                          className="hover_icon  m-1"
                          width={"36px"}
                        />
                      </OverlayTrigger>
                    </span>
                    <span className="m-auto  div_hover">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            {i18next.t("your_rank") + item.Rank}
                          </Tooltip>
                        }
                      >
                        <img
                          src="../assets/imgs/rank.png"
                          className="hover_icon  m-1"
                          width={"36px"}
                        />
                      </OverlayTrigger>
                    </span>
                    <span className="m-auto  div_hover">
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip>
                            {i18next.t("qualified_teams") + item.Qualified}
                          </Tooltip>
                        }
                      >
                        <img
                          src="../assets/imgs/medal.png"
                          className="hover_icon  m-1"
                          width={"36px"}
                        />
                      </OverlayTrigger>
                    </span>
                    <div className="m-auto mt-2">
                      <LinearProgressWithLabel value={item.teamInprogress} />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="m-auto text-white mt-1">{i18next.t("no_result")}</p>
            )}
          </div>
        </div>
      </section>
      <div
        className="container-fluid text-start back p-5"
        style={{ paddingLeft: "25px" }}
      >
        <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
          <span className="p-2">{i18next.t("back")} </span>
          <span>
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </div>
      <Footer bg="bg_black" />
    </div>
  );
}
