import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import en from "../../resources/en.json";
import AlertDialog from "../../layout/AlertDialog";
import Head from "../../layout/Head";
import Nav from "../../layout/Nav";
import Footer from "../../layout/Footer";
import config from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { Auth, UserRole, UserLevel } from "../../redux/Actions/AuthActions";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../../i18n/config";
import icon from "../../imgs/translateIcon.png";
import TermsPDF from '../../TermsAndConditions/TermsAndConditions.pdf';
import { Checkbox } from 'antd';
import { useParams } from 'react-router-dom';
export default function RegisterVoxParentComponent() {

  const parms = useParams();



    const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  //const location = useLocation();
  let [count1, setcount1] = useState(0);
  let [idd1, setid1] = useState(0);
  let [date1, setdate1] = useState({
    dd: "",
    mm: "",
    yy: "",
  });
  let [num1, setnum1] = useState({
    code: "",
    // phone:''
  });
  const [form1, setform1] = useState({
    Parent_id: "",
    name: "",
    email: "",
    password: "",
    //Phone:"",
    //Landline:"",
    Gender: "",
    Weight: "",
    Height: "",
    //DOB:"",
    //Country:"",
    RoleID: "1",
    WithRole: true,
    vox_coupon:""
  });


  let [years1, setyears1] = useState([]);
  const [pop, setpop] = useState(false);
  let [watch1, setwatch1] = useState("");

  var hostName = window.location.origin;
  const backend = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const [loading, setLoading] = useState(false);

  const [EnableLoginBtn, setEnableLoginBtn] = useState(false);
  const [LoginBtnStyle, setLoginBtnStyle] = useState("btn gray-btn mr-5 ");
  const onChangeAgreement = (e) => {
    try {
      setEnableLoginBtn(e.target.checked);
    }catch (e)
    {
      setEnableLoginBtn(false);
    }

  };



  useEffect(() => {


    if (parms && parms.vox_coupon)
    {
      setform1({ ...form1, vox_coupon: parms.vox_coupon })
    }
    EnableLoginBtn ==true?setLoginBtnStyle("btn yellow-btn mr-5 "):setLoginBtnStyle("btn gray-btn mr-5 ");
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [EnableLoginBtn]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "width=500, height=500");
  };
  function getdate1() {
    const d = new Date();
    let max = d.getFullYear();
    let min = max - 60;
    for (var i = max; i >= min; i--) {
      years1.push(i);
    }
    setyears1(years1);
  }


  function submit1() {
    if (EnableLoginBtn ==false)
    {return;}
    //  if (form1.RoleID == "3" && watch1 == "") {
    //    setpop(true);
    //  } else {
    document.getElementById("form_parent").classList.add("was-validated");
    if (
      !document
        .getElementById("password_parent")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      document.getElementById("pass_parent").style.display = "block";
    }
    if (
      document.getElementById("co-password_parent").value !=
      document.getElementById("password_parent").value
    ) {
      document.getElementById("confirm_parent").style.display = "block";
    }

    if (
      document.getElementById("form_parent").checkValidity() &&
      document.getElementById("co-password_parent").value ==
        document.getElementById("password_parent").value &&
      document
        .getElementById("password_parent")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
          setLoading(true);
      fetch(`${BaseUrl}sendEmailVerification`, {
        method: "post",
        body: JSON.stringify(form1),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (check_userResp) {
          if (check_userResp.status == true) {
            navigate("/verfiymail", { state: { form1: form1 } });
          } else if (check_userResp.status == false) {
            setLoading(false);
            document.getElementById("message_parent").style.display = "block";
            document.getElementById("message_parent").innerHTML =
              check_userResp.msg;
          }
        });
      });
    }
    // }
  }
  const setLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
  };
  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const changeLangToEn = () => {
    setLanguage("en");
    i18n.changeLanguage("en");
  };
  const changeLangToAr = () => {
    setLanguage("ar");
    i18n.changeLanguage("ar");
  };
  return (

      <div>
        <Head/>
        <section className="sign container-fluid p-2 pb-5">
          <Nav/>
          <div className="flash1"></div>
          <div className="flash2"></div>
          <div className="flash3"></div>
          <AlertDialog show={pop} Modalcontent={i18next.t("chooseWatch_pop")}/>
          <button
              style={{marginTop: "-60px"}}
              className="btn float-end"
              onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}>
            <img
                src={icon}
                title={i18next.t("changeLanguage")}
                style={{width: "50px", height: "50px"}}
            />
          </button>
          <div className=" justify-content-center   pt-3 pb-3">
            <h2>{i18next.t("sign_parentCaptionRegister")}</h2>
          </div>
          <div className=" m-auto form-white">
            <hr className="my-3 hr-yellow"/>
          </div>

          <form className=" m-auto form-white" id="form_parent">
            <div className="mb-3 ">
              <input
                  type="text"
                  maxLength="50"
                  className={
                    cookies.lang == "ar" ? "form-control text-end" : "form-control"
                  }
                  placeholder={i18next.t("name_placeholder")}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                      (e.target.placeholder = i18next.t("name_placeholder"))
                  }
                  name="name"
                  onChange={(e) => setform1({...form1, name: e.target.value})}
                  required
              />
              <div className="invalid-feedback text-start text-danger">
                {i18next.t("name_validation")}{" "}
              </div>
            </div>
            <div className="mb-3 ">
              <input
                  type="email"
                  className={
                    cookies.lang == "ar" ? "form-control text-end" : "form-control"
                  }
                  id="email"
                  placeholder={i18next.t("mail_placeholder")}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                      (e.target.placeholder = i18next.t("mail_placeholder"))
                  }
                  name="email"
                  onChange={(e) => setform1({...form1, email: e.target.value})}
                  required
              />
              <div className="invalid-feedback text-start text-danger">
                {i18next.t("mail_validation")}{" "}
              </div>
            </div>

            <div className="mb-3">
              <select
                  name="gender"
                  className={
                    cookies.lang == "ar" ? "form-select text-end" : "form-select"
                  }
                  id=""
                  onChange={(e) => setform1({...form1, Gender: e.target.value})}>
                <option>{i18next.t("gender")}</option>
                <option value="MALE">{i18next.t("male")} </option>
                <option value="FEMALE">{i18next.t("female")} </option>
              </select>
            </div>

            <div className="mb-3">
              <input
                  type="password"
                  className={
                    cookies.lang == "ar" ? "form-control text-end" : "form-control"
                  }
                  id="password_parent"
                  placeholder={i18next.t("password_placeholder")}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                      (e.target.placeholder = i18next.t("password_placeholder"))
                  }
                  name="password"
                  onChange={(e) => setform1({...form1, password: e.target.value})}
                  required
              />
              <div className="invalid-feedback text-start text-danger">
                {i18next.t("password_validation")}{" "}
              </div>
              <div
                  className="invalid-feedback text-start text-danger"
                  id="pass_parent">
                {i18next.t("password_validation_contain")}
              </div>
            </div>
            <div className="mb-3">
              <input
                  type="password"
                  className={
                    cookies.lang == "ar" ? "form-control text-end" : "form-control"
                  }
                  id="co-password_parent"
                  placeholder={i18next.t("confirmPassword_placeholder")}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                      (e.target.placeholder = i18next.t(
                          "confirmPassword_placeholder"
                      ))
                  }
                  name=""
                  required
              />
              <div className="invalid-feedback text-start text-danger">
                {i18next.t("confirmpassword_validation")}{" "}
              </div>
              <div
                  className="invalid-feedback text-start text-danger"
                  id="confirm_parent">
                {i18next.t("confirmpassword_match")}{" "}
              </div>
            </div>

            <div className="mb-3 ">
              <input
                  type="text"
                  maxLength="50"
                  className={
                    cookies.lang == "ar" ? "form-control text-end" : "form-control"
                  }
                  placeholder={i18next.t("vox-coupon")}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) =>
                      (e.target.placeholder = i18next.t("vox-coupon"))
                  }
                  name="vox_coupon"
                  onChange={(e) => setform1({...form1, vox_coupon: e.target.value})}
                  value={form1.vox_coupon}
              />

            </div>
            <hr className="my-3 hr-yellow"/>

            <p
                style={
                  cookies.lang == "ar"
                      ? {color: "white", textAlign: "right"}
                      : {color: "white", textAlign: "left"}
                }>
              {i18next.t("sign_parentRegisterSammary")}
            </p>
            <div className=" m-auto form-white">
              <hr className="my-3 hr-yellow"/>
            </div>
            <div className={cookies.lang == "ar" ? "col-lg-12 text-end" : "col-lg-12 text-start"}
                 dir={cookies.lang == "ar" ? "rtl" : "ltr"}>
              <Checkbox onChange={onChangeAgreement}>

                <a
                    onClick={() => window.open("/TermsAndConditions.pdf", '_blank')}
                    className="text-white"
                >
                  <span className=" text-white"> {i18next.t("termsPrefix")} </span>
                  <span className="  text-white link">{i18next.t("terms")}</span>
                </a>


              </Checkbox>


            </div>
            <div className=" m-auto form-white">
              <hr className="my-3 hr-yellow"/>
            </div>
            <a
                className={LoginBtnStyle}
                id="sign_parent"
                onClick={submit1}>
              <span className="m-3">{i18next.t("createparent_btn")}</span>
              <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                    fill="#100E23"
                />
              </svg>
            </a>
          </form>
          {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress/>
              </div>
          ) : (
              <div className="alert alert-danger mt-5" id="message_parent"></div>
          )}
          <p id="demo_parent" className="text-white p-2 mt-3 "></p>
          <NavLink
              className="d-inline-flex p-2 d-flex justify-content-center nav-link text-white link"
              to="/RegisterAccountType">
            {i18next.t("sign_Back")}
          </NavLink>

          <NavLink
              className="d-inline-flex p-2 d-flex justify-content-center nav-link text-white link"
              to="/veryalityApp">
            {i18next.t("have_account")}
          </NavLink>
        </section>

        <Footer bg="bg_blue"/>
      </div>


  );
}
