import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { GoogleMap, useJsApiLoader,DirectionsRenderer,Marker,Polyline  } from '@react-google-maps/api';
export default function ViewTournametRout(props) {
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [status, setstatus] = useState(0);

  let [show, setShow] = useState(props.show);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  const [googleRes, setgoogleRes] = useState(null);
  const [places, setplaces] = useState(null);
  const [waypoints, setwaypoints] = useState(null);
  const [startPosition, setstartPosition] = useState(null);
  const [endPosition, setendPosition] = useState(null);


  const google = window.google;

  const handleClose = () => {
    setShow(false);

  };

  const center = {
    lat: 25.162675738334656, lng: 55.40335690975189 };
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  const options = {
     zoomControl:true,
    streetViewControl:false,
    mapTypeControl:false,
  };



  useEffect(() => {
    if (props.show == true) {
      //setplaces(props.places);
      let wayPoints= props.places.map(p =>({
        lat: parseFloat(p.LatitudeDegrees),
        lng:parseFloat(p.LongitudeDegrees)

      }));

      if (isLoaded)
      {
        setwaypoints(wayPoints);
        // eslint-disable-next-line no-undef
        setstartPosition(wayPoints.shift())   ;
        setendPosition(wayPoints.pop());




      }
      setShow(props.show);

    }
  }, [props.show]);


  const  isLoaded  = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCJWTcpqPkvY7JskiNuDWW5C9HaaOXsaAw"
  });
  return (

    <>
      {   waypoints && waypoints.length>0 &&isLoaded?(<div className="modelround" className="modal">
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle p-3">
            <p className="margin_p">{i18next.t("tournament_Route")}</p>
            <GoogleMap mapContainerStyle={containerStyle}

                       center={startPosition}
                       zoom={15}
                       options={options}
            >
              <Marker position={startPosition}
                      label={'Start'}
                      icon={{
                        url: '../assets/imgs/flag.png',
                        scaledSize: new window.google.maps.Size(30, 30), // Size of the custom icon
                      }}
              />
              <Marker position={endPosition}
                      label={'End'}
                      icon={{
                        url: '../assets/imgs/flag.png',
                        scaledSize: new window.google.maps.Size(30, 30), // Size of the custom icon
                      }}

              />
              { /* Child components, such as markers, info windows, etc. */}
              {/*{googleRes && <DirectionsRenderer directions={googleRes}/>}*/}
              <Polyline
                  path={waypoints}
                  options={{
                    strokeColor: '#ff0000', // Color of the track
                    strokeOpacity: 1,
                    strokeWeight: 3,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                  }}
              />


            </GoogleMap>
          </Modal.Body>
          <Modal.Footer>
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
            </Button>
            <div
                id="finish_mission1"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
          </Modal.Footer>
        </Modal>
      </div>):(<div>ggggggggggggggggggggggg</div>)}

    </>
  );
}
