import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
// import { useCookies } from 'react-cookie';
import i18next from "../i18n/config";

export default function UpdateTeamModal(props) {
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  let [status, setstatus] = useState(0);
  const [image, setimage] = useState();
  const [imgSrc, setimgSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);
  let [show, setShow] = useState(props.show);
  let [team, setTeam] = useState({
    name: "",
    logo: "",
    XP_points: 0,
    user_id: id,
    level_id: 0,
  });

  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
      setimgSrc(props.team.logo);
      setTeam({
        name: props.team.name,
        logo: props.team.logo,
        XP_points: props.team.XP_points,
        user_id: id,
        level_id: props.team.level,
      });
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    props.onChange("david");
  };

  function previewFile() {
    document.getElementById("profile-image-upload").click();
  }

  function changeImage(e) {
    document.getElementById("image_size").classList.add("d-none");
    if (e.target.files[0].size <= 1000000) {
      setimage(e.target.files[0]);
      setTeam({ ...team, logo: e.target.files[0] });
      setimgSrc(URL.createObjectURL(e.target.files[0]));
    } else document.getElementById("image_size").classList.remove("d-none");
  }

  const handlesubmit = (e) => {
    document.getElementById("finish_mission1").innerHTML = "";
    document.getElementById("form-team").classList.add("was-validated");
    if (document.getElementById("form-team").checkValidity()) {
      let data = new FormData();
      data.append("name", team.name);
      data.append("logo", team.logo);
      data.append("XP_points", team.XP_points);
      data.append("user_id", team.user_id);
      data.append("level_id", team.level_id);
      setLoading(true);
      fetch(`${BaseUrl}updateTeam/${props.team.id}`, {
        method: "post",
        body: data,
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resp_finishmission) {
          setLoading(false);
          if (resp_finishmission.status == true) {
            document.getElementById("finish_mission1").innerHTML =
              i18next.t("UpdateSuccess");
            document
              .getElementById("finish_mission1")
              .classList.remove("d-none");
            document
              .getElementById("finish_mission1")
              .classList.add("alert-info");
          } else {
            document.getElementById("finish_mission1").innerHTML =
              i18next.t("UpdateFailed");
            document
              .getElementById("finish_mission1")
              .classList.remove("d-none");
            document
              .getElementById("finish_mission1")
              .classList.add("alert-danger");
          }
        });
      });
    }
  };

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle text-start p-3">
            <form className=" m-auto form-white" id="form-team">
              <div class="mb-3 text-center">
                <div className="m-1  camera_div">
                  {imgSrc == null || imgSrc == "" ? (
                    <img
                      src="../assets/imgs/account_8.png"
                      alt="imgSrc"
                      width="200px"
                      height="200px"
                      className="hover_img"
                    />
                  ) : (
                    <img
                      src={imgSrc}
                      alt="imgSrc"
                      width="200px"
                      height="200px"
                      className="border rounded-circle hover_img"
                    />
                  )}

                  <input
                    type="file"
                    className="w-full px-4 py-2 namestyle d-none"
                    label="image"
                    name="image"
                    id="profile-image-upload"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      changeImage(e);
                    }}
                  />
                  <div className="camera point" onClick={previewFile}>
                    <svg
                      width="55"
                      height="51"
                      viewBox="0 0 23 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21 5H17.83L16 3H10V5H15.12L16.95 7H21V19H5V10H3V19C3 20.1 3.9 21 5 21H21C22.1 21 23 20.1 23 19V7C23 5.9 22.1 5 21 5ZM8 13C8 15.76 10.24 18 13 18C15.76 18 18 15.76 18 13C18 10.24 15.76 8 13 8C10.24 8 8 10.24 8 13ZM13 10C14.65 10 16 11.35 16 13C16 14.65 14.65 16 13 16C11.35 16 10 14.65 10 13C10 11.35 11.35 10 13 10ZM5 5H8V3H5V0H3V3H0V5H3V8H5V5Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div
                    className="text-center text-danger d-none"
                    id="image_size">
                    {i18next.t("image_size")}{" "}
                  </div>
                </div>
              </div>
              <div class="mb-3 ">
                <input
                  type="text"
                  className={
                    cookies.lang === "ar"
                      ? "menu_rtl form-control"
                      : "menu_ltr form-control"
                  }
                  id="name"
                  value={team.name}
                  placeholder={i18next.t("Team_name")}
                  name="name"
                  onChange={(e) => setTeam({ ...team, name: e.target.value })}
                  required
                />
                <div
                  className={
                    cookies.lang === "ar"
                      ? "menu_rtl invalid-feedback text-right text-danger"
                      : "menu_ltr invalid-feedback text-start text-danger"
                  }>
                  {i18next.t("Team_name_validation")}
                </div>
              </div>
              {/* <div class="mb-3">
                                <input type="number" min={0} class="form-control" id="XP_points" value={team.XP_points} placeholder={en.Esport.XP_points} 
                                 name="XP_points" onChange={e=>setTeam({...team,XP_points:e.target.value})} required/>
                                <div className='invalid-feedback text-start text-danger'>{en.Esport.XPpoints_validation} </div>
                            </div> */}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="bg_blue w-40"
              variant="primary"
              onClick={handlesubmit}>
              {i18next.t("update")}
            </Button>
            <Button className="white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
            </Button>
            {loading == true ? (
              <div className="w-100 mt-3 mb-2 m-auto">
                <CircularProgress />
              </div>
            ) : (
              <div
                id="finish_mission1"
                className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
