import * as React from 'react';
import  { Component } from 'react';
 import {useNavigate,useLocation} from 'react-router-dom';
import { useState, useEffect } from "react";
import { Modal,Button} from 'react-bootstrap';
import en from '../resources/en.json';
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';

export default function AlertDialogProfile(props) {
  const navigate = useNavigate();
  let [show, setShow] = useState(props.show);
  const id = useSelector((state) => state.appReducer.id);

   useEffect(() => {
    if(props.show==true){
      setShow(props.show)
    }
    }, [props.show]);

  const handleClose = () => setShow(false);
   const location = useLocation();
  const msgtest=props.msgtest;
  function goToprofile(){
    navigate('/profile', {state:{}});
  }
  return (
    <>
    
    <div class="modelround" className="modal">
    <Modal
       show={show}
      onHide={handleClose}
      backdrop="static" 
      keyboard={false}
    >
      <Modal.Header class="modelstyle" closeButton>
        <Modal.Title class="modelstyle">{en.RewardDialog.veryality}</Modal.Title>
      </Modal.Header>
      <Modal.Body class="modelstyle text-start p-3">

          {msgtest}<a class="text-primary " href="" onClick={goToprofile}>{" profile"}</a> {"to connect to fitbit account!"}

      </Modal.Body>
      <Modal.Footer class="modelstyle">
      <div class="d-flex justify-content-end mt-4 button_right">
        <Button class="blue-btn" variant="primary" onClick={handleClose}>
        {i18next.t("close")}
        </Button>
        </div>

      </Modal.Footer>
    </Modal>
    </div>
  </>
  );
}

