import {useNavigate} from "react-router-dom";

import {useState, useEffect} from "react";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import en from "../resources/en.json";
import ReactLoading from "react-loading";
import config from "../config.json";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import i18next from "../i18n/config";
import icon from "../imgs/translateIcon.png";
import {Collapse, Divider,Pagination } from 'antd';
import  './Faq.css';
import { FloatButton } from 'antd';
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';

import { Button, Modal } from 'antd';
import {

    Form,
    Input,
} from 'antd';
import { Alert, Space } from 'antd';
import { CloseSquareFilled } from '@ant-design/icons';

export default function FAQ(props) {
    const { TextArea } = Input;

    const [form, setform] = useState({
        question_ar: "",
        question_en: "",

    });
    const [open, setOpen] = useState(false);
    const [showSucess, setShowSucess] = useState(false);
    const [showerror, setShowerror] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {


        setConfirmLoading(true);
        fetch(`${BaseUrl}addFAQ`, {
            method: "post",
            body: JSON.stringify(form),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Accept-Language": cookies.lang,
            },
        }).then(function (response) {
            response.json().then(function (Resp) {

                if (Resp.success== true)
                {
                    setShowSucess(true);
                }
                else
                {
                    setShowerror(true);
                }
                setform({ ...form, question_en: "",question_ar:"" })
                setOpen(false);
                setConfirmLoading(false);
            });
        });

    };
    const handleCancel = () => {
        setform({ ...form, question_en: "",question_ar:"" })
        setOpen(false);
    };
    let BaseUrl = config.BaseUrl;
    const [faqs,setFaqs]= useState([]);
    const [current,setcurrent]= useState(1);
    const [total,setTotal]= useState(1);
    const [isLoading, setLoading] = useState(true);
const handleAlterClose=()=>{

    setShowerror(false);
    setShowSucess(false);
}
    const navigate = useNavigate();


    const {i18n} = useTranslation();
    const [cookies, setCookie] = useCookies(["lang"]);




       const getdata=(e)=>{
           let page='?page=1';
           if (e != undefined && e !='undefined')
           {
               page='?page='+e;
           }

            fetch(`${BaseUrl}FAQ`+page, {
                method: "get",

                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Accept-Language": cookies.lang,
                },
            }).then(function (response) {
                response.json().then(function (Resp) {

                    setFaqs(Resp.data.data);
                    setcurrent(Resp.data.pagination.current_page);
                    setTotal(Resp.data.pagination.total);
                    setLoading(false);
                });
            });
        }

    useEffect(() => {

        getdata();
        if (cookies && cookies.lang) {
            i18n.changeLanguage(cookies.lang);
        } else {
            i18n.changeLanguage("en");
        }
    }, []);

    const setLanguage = (lang) => {
        setCookie("lang", lang, {path: "/"});
    };
    useEffect(() => {
        if (cookies && cookies.lang) {
            i18n.changeLanguage(cookies.lang);
        } else {
            i18n.changeLanguage("en");
        }
    }, []);

    const changeLangToEn = () => {
        setLanguage("en");
        i18n.changeLanguage("en");
    };
    const changeLangToAr = () => {
        setLanguage("ar");
        i18n.changeLanguage("ar");
    };
    if (isLoading) {
        return (
            <div className="load-page">
                <ReactLoading type="spin" color="#F9AF22" className="load" />
            </div>
        );
    }
    return (
        <div>
            <FloatButton.Group
                trigger="click"
                type="primary"
                style={{
                    right: 24,
                }}
                icon={<CustomerServiceOutlined />}
            >
                <FloatButton  onClick={showModal} tooltip= {i18next.t("ask")}/>

            </FloatButton.Group>

            <Head/>
            <div className="container-fluid p-2 pb-5 sign vh">
                <Nav/>

                <div className="flash1"></div>
                <div className="flash2"></div>
                <div className="flash3"></div>
                <button
                    style={{marginTop: "-50px"}}
                    className="btn float-end"
                    onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}>
                    <img
                        src={icon}
                        title={i18next.t("changeLanguage")}
                        style={{width: "50px", height: "50px"}}
                    />
                </button>
                <div className="container text-center mt-5 pt-3">

                    <div className="row">

                        <div className="col-lg-12"> <h2 className="text-white">{i18next.t("faq")}</h2></div>
                        {/*<div className="col-lg-1">*/}

                        {/*    <Button type="primary" onClick={showModal} style={{direction:"rtl"}} className="text-left">*/}
                        {/*        {i18next.t("ask")}*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                    {showSucess==true?(<Alert
                        message={i18n.t("successfaq")}
                        type="success"
                        closable
                        onClose={handleAlterClose}
                    />):(<></>)}
                    {showerror==true?(
                        <Alert
                            message={i18n.t("failfaq")}
                            type="error"
                            closable
                            onClose={handleAlterClose}
                        />
                    ):(<></>)}


                    <Modal style={{ direction: cookies.lang == "ar" ? "rtl" : "ltr"}}
                        title={i18next.t("ask")}
                        open={open}
                           okText={i18next.t("send")}
                           cancelText={i18next.t("Cancel")}

                           cancelButtonProps={{
                               danger: true,

                           }}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                    >
                        {cookies.lang == "ar" ?(
                            <form>
                                <Form.Item label="" >
                                    <TextArea rows={10}   required  value={form.question_ar}  onChange={(e) => setform({ ...form, question_ar: e.target.value,question_en:"" })}  />
                                </Form.Item>
                            </form>
                        ):(
                            <form>
                                <Form.Item label="" >
                                    <TextArea rows={10} required value={form.question_en}  onChange={(e) => setform({ ...form, question_en: e.target.value,question_ar:"" })}  />
                                </Form.Item>
                            </form>
                        )}

                    </Modal>
                    <div class=" mt-3 ">
                        <div class="container ">
                            <br/>
                            { faqs.map((item, index) =>
                                <>
                                <Collapse style={{direction:cookies.lang == "ar" ?"rtl":"ltr"}}
                                    size="large"
                                    items={[
                                        {
                                            key: index,
                                            label:   cookies.lang == "ar" ?item.question_ar:item.question_en ,
                                            children: cookies.lang == "ar" ?<p style={{whiteSpace: "pre-line", textAlign:"right"}}>{item.answer_ar}</p>:<p style={{whiteSpace: "pre-line", textAlign:"left"}}>{item.answer_en}</p>,
                                        },
                                    ]}
                                />
                                <br/>
                                </>
                                )
                            }



                    <Divider ></Divider>
                            <Pagination defaultCurrent={1} current={current} total={total} onChange={(e)=>getdata(e)} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer bg="bg_blue"/>
        </div>
    );
}
