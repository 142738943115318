import {
  LinearProgressProps,
  LinearProgress,
  Typography,
  Box,
} from "@mui/material";
import React from 'react';

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <Box sx={{ minWidth: 25 }}>
          <Typography variant="body2">{`${Math.round(props.value)}`}</Typography>
        </Box> */}
        <Box className="review_progress" sx={{ width: "70%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ width: "30%", color: "#F2C94C" }}>
          <Typography variant="body2">
            <span style={{ padding: "5px", color: "#F2C94C",fontSize: "12px" }}>
              {parseInt(props.value)}%
            </span>
          </Typography>
        </Box>
      </Box>
    );
  }
  export default LinearProgressWithLabel;