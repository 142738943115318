import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Usernav from "../layout/Usernav";
import Footer from "../layout/Footer";
import Game from "../layout/Game";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import en from "../resources/en.json";
import config from "../config.json";
import AlertDialog from "../layout/AlertDialog";
import DeleteAccountDialog from "../layout/DeleteAccountDialog";
import RevokAccountDialog from "../layout/RevokAccountDialog";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ConnectWearableDialog from "../layout/ConnectWearableDialog";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
// import config from "../config.json";
import { useTranslation } from "react-i18next";


export default function Profile(props) {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["lang"]);
  const token = useSelector((state) => state.appReducer.token);
  const role = useSelector((state) => state.appReducer.role);
  const [isLoading, setLoading] = useState(true);
  let [deletemissionpop, setdeletemissionpop] = useState(false);
  let [revokpop, setrevokpop] = useState(false);
  let [connectPop, setConnectPop] = useState(false);
  const [show, setshow] = useState(false);
  const [haswearble, sethaswearble] = useState([]);
  let [userRole, setuserRole] = useState();
  const [items, setitems] = useState([]);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [userID, setuserID] = useState("");
  const [Phone, setPhone] = useState("");
  const [Gender, setGender] = useState("");
  const [Weight, setWeight] = useState("");
  const [Height, setHeight] = useState("");
  const [imgSrc, setimgSrc] = useState("");
  const [DOB, setDOB] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // const id=location.state.id;
  const id = useSelector((state) => state.appReducer.id);
  const { i18n } = useTranslation();

  const update = location.state.update;
  // const username=location.state.username;
  const username = useSelector((state) => state.appReducer.username);
  const level = useSelector((state) => state.appReducer.level);
  var hostName = window.location.origin;
  function click() {
    navigate("/resetpassword", { state: {} });
  }
  function clickEditProfile() {
    navigate("/EditProfile", { state: {} });
  }

  let propss = {
    show: show,
    Modalcontent: update !=null && update !=username?update: i18next.t("updateprofile"),
  };

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (update != null) {
      setshow(true);
      if (location.state != null && location.state.parentId != null) {
        navigate("/dgamer", { state: {} });
      }
    }
  }, [update]);

  function clickdeleteProfile() {
    setdeletemissionpop(true);
  }

  useEffect(() => {
    if (deletemissionpop == true) {
      setTimeout(() => {
        setdeletemissionpop(false);
      }, 1000);
    }
  }, [deletemissionpop]);
  useEffect(() => {
    if (revokpop == true) {
      setTimeout(() => {
        setrevokpop(false);
      }, 1000);
    }
  }, [revokpop]);

  useEffect(() => {
    if (connectPop == true) {
      setTimeout(() => {
        setConnectPop(false);
      }, 1000);
    }
  }, [connectPop]);

  let BaseUrl = config.BaseUrl;
  var data_res;
  const arr = [];

  useEffect(() => {
    fetch(`${BaseUrl}users/showinfo/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getshowinfo_response) {
        setname(getshowinfo_response.data.name);
        setemail(getshowinfo_response.data.email);
        setPhone(getshowinfo_response.data.Phone);
        setGender(getshowinfo_response.data.Gender);
        setWeight(getshowinfo_response.data.Weight);
        setHeight(getshowinfo_response.data.Height);
        setDOB(getshowinfo_response.data.DOB);
        setuserID(getshowinfo_response.data.clientid);
        data_res = getshowinfo_response.data;
        setitems(data_res);
        setimgSrc(getshowinfo_response.data.imagePath);
        if (role == 1) {
          setuserRole("parent");
        } else {
          if (getshowinfo_response.data.Parent_id != null) {
            setuserRole("child");
          } else {
            setuserRole("gamer");
          }
        }
        setLoading(false);
      });
    });
  }, []);
  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //  const connect=(e)=>{
  //     fetch(`${BaseUrl}connectToFitbi/${id}`,{
  //       method:'get',
  //       headers:{
  //         'Accept':'application/json',
  //         'Authorization': `Bearer ${token}`,
  //       }
  //     }).then(function(response11){
  //         response11.json().then(function(connect_fitbitResp){
  //           openInNewTab(connect_fitbitResp.data);
  //           var y = setTimeout(function(){
  //             fetch(`${BaseUrl}checkwearableAccess/${id}`,{
  //               method:'get',
  //               headers:{
  //                 'Accept':'application/json',
  //                 'Authorization': `Bearer ${token}`
  //               }
  //             }).then(function(response33){

  //               response33.json().then(function(check_fitbitResp){
  //                 if(Array.isArray(check_fitbitResp) && check_fitbitResp[0]=='user connect to wearable successfully') {
  //                   fetch(`${BaseUrl}users/userWithWearable/${id}`,{
  //                     method:'get',
  //                     headers:{
  //                       'Accept':'application/json',
  //                       'Authorization': `Bearer ${token}`
  //                     }
  //                   }).then(function(response11){

  //                       response11.json().then(function(Resp){
  //                         sethaswearble(Resp.data[0].wearablesaccounts);

  //                       })
  //                     })
  //                 }
  //               })
  //             })
  //            }, 3000);

  //         })

  //       })

  //     }

  const revok = (e) => {
    setrevokpop(true);
  };
  const connectWearable = (e) => {
    setConnectPop(true);
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "width=500, height=500");
  };

  const handleChange = React.useCallback((newValue) => {
    fetch(`${BaseUrl}users/userWithWearable/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        sethaswearble(Resp.data[0].wearablesaccounts);
      });
    });
  });
  useEffect(() => {
    fetch(`${BaseUrl}users/userWithWearable/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        sethaswearble(Resp.data[0].wearablesaccounts);
      });
    });
  }, []);
  if (isLoading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }
  return (
    <div>
      <Head />
      <AlertDialog {...propss} />
      <DeleteAccountDialog show={deletemissionpop} />
      <RevokAccountDialog show={revokpop} onChange={handleChange} />
      <ConnectWearableDialog show={connectPop} onChange={handleChange} />
      {width > 950 ? (
        <div className="desktop">
          <section className=" container-fluid p-2 bg_black">
            <Usernav />
            <div className="row container-fluid mt-5 dash"></div>
          </section>

          <section class="container-fluid p-2 bg_black">
            <div class="container py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100 ">
                <div class="col col-lg-6 mb-4 mb-lg-0 profile">
                  <div class="card mb-3 div11Style">
                    <div class="dropdown profilrRes">
                      {/* <button type="button"  data-bs-toggle="dropdown"> */}
                      <img
                        src="../assets/imgs/drop.png"
                        data-bs-toggle="dropdown"
                        class="reset_pass"
                      />
                      {/* </button> */}
                      <ul class="dropdown-menu">
                        <li className={cookies.lang === "ar" ? "rtl" : ""}>
                          <a
                            class={
                              cookies.lang === "ar"
                                ? "dropdown-item text-end"
                                : "dropdown-item"
                            }
                            onClick={clickEditProfile}>
                            <svg
                              className="m-1"
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z"
                                fill="#100E23"
                              />
                            </svg>
                            {i18next.t("EditProfile")}
                          </a>
                        </li>
                        <li className={cookies.lang === "ar" ? "rtl" : ""}>
                          <a
                            class={
                              cookies.lang === "ar"
                                ? "dropdown-item text-end"
                                : "dropdown-item"
                            }
                            onClick={clickdeleteProfile}>
                            <svg
                              className="m-1"
                              width="19"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
                                fill="#100E23"
                              />
                            </svg>
                            {i18next.t("DeleteAccount")}
                          </a>
                        </li>
                        <li className={cookies.lang === "ar" ? "rtl" : ""}>
                          <a
                            class={
                              cookies.lang === "ar"
                                ? "dropdown-item text-end"
                                : "dropdown-item"
                            }
                            onClick={click}>
                            <svg
                              className="m-1"
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM2.92 16H2V15.08L11.06 6.02L11.98 6.94L2.92 16ZM17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.4 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63V2.63Z"
                                fill="#100E23"
                              />
                            </svg>
                            {i18next.t("resetpass_btn")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="row g-0">
                      <div class="col-md-8 gradient-custom text-center text-white div1Style">
                        {imgSrc == null ? (
                          <img
                            src="../assets/imgs/account_8.png"
                            alt="imgSrc"
                            width="200px"
                            height="200px"
                          />
                        ) : (
                          <img
                            src={imgSrc}
                            alt="imgSrc"
                            width="200px"
                            height="200px"
                            className="border rounded-circle"
                          />
                        )}

                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip>{name}</Tooltip>}>
                          <h5>
                            {name.length > 27
                              ? name.substring(0, 27) + "..."
                              : name}
                          </h5>
                        </OverlayTrigger>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body p-4 tStyle">
                          <h6>{i18next.t("profile_Personal_info")}</h6>
                          <hr class="mt-0 mb-4" />
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6> {i18next.t("profile_gender")}</h6>
                              <p class="idd11 text-muted text-lowercase">
                                {Gender}
                              </p>
                            </div>
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_weight")}</h6>
                              <p class="idd11 text-muted">{Weight}</p>
                            </div>
                          </div>
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_height")}</h6>
                              <p class="idd11 text-muted">{Height}</p>
                            </div>
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_dob")}</h6>
                              <p class="idd11 text-muted">{DOB}</p>
                            </div>
                          </div>
                          <h6>{i18next.t("profile_continfo")}</h6>
                          <hr class="mt-0 mb-4" />
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_mail")}</h6>
                              <p class="idd11 text-muted">{email}</p>
                            </div>
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_phone")}</h6>
                              <p class="idd11 text-muted">{Phone}</p>
                            </div>
                          </div>
                          <h6>{i18next.t("profile_verialityinfo")}</h6>
                          <hr class="mt-0 mb-4" />
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_userID")}</h6>
                              <p class="idd11 text-muted">{userID}</p>
                            </div>
                          </div>

                          {userRole != "parent" &&
                            role != 5 &&
                            haswearble.length == 0 && (
                              <Button
                                type="submit"
                                className="bg_blue  mb-3"
                                variant="primary"
                                onClick={connectWearable}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1_2732)">
                                    <path
                                      d="M14.31 2L14.72 4.48C13.87 4.17 12.96 4 12 4C11.05 4 10.13 4.17 9.29 4.47L9.7 2H14.31ZM14.72 19.52L14.31 22H9.7L9.29 19.53C10.13 19.83 11.05 20 12 20C12.96 20 13.87 19.83 14.72 19.52ZM16 0H8L7.05 5.73C5.19 7.19 4 9.45 4 12C4 14.55 5.19 16.81 7.05 18.27L8 24H16L16.96 18.27C18.81 16.81 20 14.54 20 12C20 9.46 18.81 7.19 16.96 5.73L16 0ZM12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C15.31 6 18 8.69 18 12C18 15.31 15.31 18 12 18Z"
                                      fill="#100E23"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_2732">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {i18next.t("connecttofitbit")}
                              </Button>
                            )}
                          {userRole != "parent" &&
                            role != 5 &&
                            haswearble.length > 0 && (
                              <Button
                                type="submit"
                                className="bg_red mb-3"
                                variant="outlined"
                                onClick={revok}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1_2732)">
                                    <path
                                      d="M14.31 2L14.72 4.48C13.87 4.17 12.96 4 12 4C11.05 4 10.13 4.17 9.29 4.47L9.7 2H14.31ZM14.72 19.52L14.31 22H9.7L9.29 19.53C10.13 19.83 11.05 20 12 20C12.96 20 13.87 19.83 14.72 19.52ZM16 0H8L7.05 5.73C5.19 7.19 4 9.45 4 12C4 14.55 5.19 16.81 7.05 18.27L8 24H16L16.96 18.27C18.81 16.81 20 14.54 20 12C20 9.46 18.81 7.19 16.96 5.73L16 0ZM12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C15.31 6 18 8.69 18 12C18 15.31 15.31 18 12 18Z"
                                      fill="#100E23"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_2732">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {i18next.t("revok")}
                              </Button>
                            )}
                          <div class="d-flex justify-content-center">
                            <a>
                              <i class="fab fa-facebook-f fa-lg me-3"></i>
                            </a>
                            <a>
                              <i class="fab fa-twitter fa-lg me-3"></i>
                            </a>
                            <a>
                              <i class="fab fa-instagram fa-lg"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="mobile">
          <section className=" container-fluid p-2 bg_black">
            <Usernav />
            <div className="row container-fluid mt-5 dash pdash m-0">
              {/* Tab panes */}
            </div>
          </section>

          <section class=" sectionStyle bg_black">
            <div class="container py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col col-lg-6 mb-4 mb-lg-0">
                  <div class="card mb-3 div11Style">
                    <div class="dropdown profilrRes">
                      {/* <button type="button"  data-bs-toggle="dropdown"> */}
                      <img
                        src="../assets/imgs/drop.png"
                        data-bs-toggle="dropdown"
                        class="reset_pass"
                      />
                      {/* </button> */}
                      <ul class="dropdown-menu">
                        <li className={cookies.lang === "ar" ? "rtl" : ""}>
                          <a
                            class={
                              cookies.lang === "ar"
                                ? "dropdown-item text-end"
                                : "dropdown-item"
                            }
                            onClick={click}>
                            <svg
                              className="m-1"
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM2.92 16H2V15.08L11.06 6.02L11.98 6.94L2.92 16ZM17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.4 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63V2.63Z"
                                fill="#100E23"
                              />
                            </svg>
                            {i18next.t("resetpass_btn")}
                          </a>
                        </li>

                        <li
                          className={cookies.lang === "ar" ? "rtl " : ""}>
                          <a
                            class={
                              cookies.lang === "ar"
                                ? "dropdown-item text-end"
                                : "dropdown-item"
                            }
                            onClick={clickEditProfile}>
                            <svg
                              className="m-1"
                              width="19"
                              height="18"
                              viewBox="0 0 19 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11.06 6.02L11.98 6.94L2.92 16H2V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V18H3.75L14.81 6.94L11.06 3.19Z"
                                fill="#100E23"
                              />
                            </svg>
                            {i18next.t("EditProfile")}
                          </a>
                        </li>
                        <li className={cookies.lang === "ar" ? "rtl" : ""}>
                          <a
                            class={
                              cookies.lang === "ar"
                                ? "dropdown-item text-end"
                                : "dropdown-item"
                            }
                            onClick={clickdeleteProfile}>
                            <svg
                              className="m-1"
                              width="19"
                              height="18"
                              viewBox="0 0 14 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z"
                                fill="#100E23"
                              />
                            </svg>
                            {i18next.t("DeleteAccount")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="row g-0">
                      <div class="col-md-4 gradient-custom text-center text-white div1Style">
                        {imgSrc == null ? (
                          <img
                            src="../assets/imgs/account_8.png"
                            alt="imgSrc"
                            width="200px"
                            height="200px"
                          />
                        ) : (
                          <img
                            src={imgSrc}
                            alt="imgSrc"
                            width="200px"
                            height="200px"
                            className="border rounded-circle"
                          />
                        )}

                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip>{name}</Tooltip>}>
                          <h5>
                            {name.length > 27
                              ? name.substring(0, 27) + "..."
                              : name}
                          </h5>
                        </OverlayTrigger>
                      </div>
                      <div class="col-md-8">
                        <div class="card-body p-4">
                          <h6>{i18next.t("profile_continfo")}</h6>
                          <hr class="mt-0 mb-4" />
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_mail")}</h6>
                              <p class="idd12 text-muted">{email}</p>
                            </div>
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_phone")}</h6>
                              <p class="idd12 text-muted">{Phone}</p>
                            </div>
                          </div>
                          <h6>{i18next.t("profile_Personal_info")}</h6>
                          <hr class="mt-0 mb-4" />
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_gender")}</h6>
                              <p class="idd12 text-muted text-lowercase">
                                {Gender}
                              </p>
                            </div>
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_weight")}</h6>
                              <p class="idd12 text-muted">{Weight}</p>
                            </div>
                          </div>
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_height")}</h6>
                              <p class="idd12 text-muted">{Height}</p>
                            </div>
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_dob")}</h6>
                              <p class="idd12 text-muted">{DOB}</p>
                            </div>
                          </div>
                          <h6>{i18next.t("profile_verialityinfo")}</h6>
                          <hr class="mt-0 mb-4" />
                          <div class="row pt-1">
                            <div class="col-6 mb-3">
                              <h6>{i18next.t("profile_userID")}</h6>
                              <p class="idd12 text-muted">{userID}</p>
                            </div>
                          </div>
                          {userRole != "parent" &&
                            role != 5 &&
                            haswearble.length == 0 && (
                              <Button
                                type="submit"
                                className="bg_blue  mb-3"
                                variant="primary"
                                onClick={connectWearable}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1_2732)">
                                    <path
                                      d="M14.31 2L14.72 4.48C13.87 4.17 12.96 4 12 4C11.05 4 10.13 4.17 9.29 4.47L9.7 2H14.31ZM14.72 19.52L14.31 22H9.7L9.29 19.53C10.13 19.83 11.05 20 12 20C12.96 20 13.87 19.83 14.72 19.52ZM16 0H8L7.05 5.73C5.19 7.19 4 9.45 4 12C4 14.55 5.19 16.81 7.05 18.27L8 24H16L16.96 18.27C18.81 16.81 20 14.54 20 12C20 9.46 18.81 7.19 16.96 5.73L16 0ZM12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C15.31 6 18 8.69 18 12C18 15.31 15.31 18 12 18Z"
                                      fill="#100E23"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_2732">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {i18next.t("connecttofitbit")}
                              </Button>
                            )}
                          {userRole != "parent" &&
                            role != 5 &&
                            haswearble.length > 0 && (
                              <Button
                                type="submit"
                                className="bg_red  mb-3"
                                variant="outlined"
                                onClick={revok}>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1_2732)">
                                    <path
                                      d="M14.31 2L14.72 4.48C13.87 4.17 12.96 4 12 4C11.05 4 10.13 4.17 9.29 4.47L9.7 2H14.31ZM14.72 19.52L14.31 22H9.7L9.29 19.53C10.13 19.83 11.05 20 12 20C12.96 20 13.87 19.83 14.72 19.52ZM16 0H8L7.05 5.73C5.19 7.19 4 9.45 4 12C4 14.55 5.19 16.81 7.05 18.27L8 24H16L16.96 18.27C18.81 16.81 20 14.54 20 12C20 9.46 18.81 7.19 16.96 5.73L16 0ZM12 18C8.69 18 6 15.31 6 12C6 8.69 8.69 6 12 6C15.31 6 18 8.69 18 12C18 15.31 15.31 18 12 18Z"
                                      fill="#100E23"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_2732">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {i18next.t("revok")}
                              </Button>
                            )}
                          <div class="d-flex justify-content-center">
                            <a>
                              <i class="fab fa-facebook-f fa-lg me-3"></i>
                            </a>
                            <a>
                              <i class="fab fa-twitter fa-lg me-3"></i>
                            </a>
                            <a>
                              <i class="fab fa-instagram fa-lg"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <div
        className="container-fluid text-start back "
        style={{ paddingLeft: "150px" }}>
        <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
          <span className="p-2">{i18next.t("back")}</span>
          <span>
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </div>

      <Footer bg="bg_black" />
    </div>
  );
}
