import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import ar from "../resources/ar.json";
import { env } from "process";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import Usernav from "../layout/Usernav";
import RemoveTeamModal from "./RemoveTeamModal";
import CreateTeamModal from "./CreateTeamModal";
import UpdateTeamModal from "./UpdateTeamModal";
import ViewInvitationsModal from "./ViewInvitationsModal";
import ViewTeamModal from "./ViewTeamModal";
import InviteModal from "./InviteModal";
import TeamMembersModal from "./TeamMembersModal";
import AssignTournamentToTeamModal from "../ESport/AssignTournamentToTeamModal";
import {
  UsergroupAddOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Dropdown, Space, message } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";
export default function ManagerAssign(props) {
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1-1": //create team
        setCreatePop(true);
        break;
      case "1-2": //update team
        setUpdatePop(true);
        break;
      case "1-3": //delete team
        setRemovePop(true);
        break;
      case "2-1": //'View team details'
        setViewTeamDetailsPop(true);
        break;
      case "2-2": //'Invite member'
        setInvitePop(true);
        break;
      case "2-3": // 'View team members'
        setViewTeamMembersPop(true);
        break;
      case "2-4": //'View invitations'
        setViewInvitePop(true);
        break;
      case "2-5": // 'View Team Inprogress'
        showMembers();
        break;
      case "2-6": // 'Assign tournament'
        setviewPopAssign(true);
        break;

      default:
    }
  };
  const items = [
    {
      label: "",
      key: "1",
      icon: <SettingOutlined />,
      children: [
        {
          key: "1-1",
          label: "Create team",
        },
        {
          key: "1-2",
          label: "Update team",
        },
        {
          key: "1-3",
          label: "Delete team",
          danger: true,
        },
      ],
    },
    {
      label: "",
      key: "2",
      icon: <UsergroupAddOutlined />,
      children: [
        {
          key: "2-1",
          label: "Team details",
        },
        {
          key: "2-2",
          label: "Invite member",
        },
        {
          key: "2-3",
          label: "Team members",
        },
        {
          key: "2-4",
          label: "Invitations",
        },
        {
          key: "2-5",
          label: "Team Inprogress",
        },
        {
          key: "2-6",
          label: "Assign tournament",
        },
      ],
    },
  ];
  const handleOpenChange = (item) => {
    setTeam(item);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
    mode: "horizontal",
  };

  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [isLoading, setLoading] = useState(true);
  const username = useSelector((state) => state.appReducer.username);
  const navigate = useNavigate();
  let [pop, setpop] = useState(false);
  let [createPop, setCreatePop] = useState(false);
  let [removePop, setRemovePop] = useState(false);
  let [updatePop, setUpdatePop] = useState(false);
  let [viewPopAssign, setviewPopAssign] = useState(false);
  let [viewInvitePop, setViewInvitePop] = useState(false);
  let [viewTeamDetailsPop, setViewTeamDetailsPop] = useState(false);
  let [viewTeamMembersPop, setViewTeamMembersPop] = useState(false);
  const [loading, setLoading1] = useState(false);
  let [invitePop, setInvitePop] = useState(false);
  let [team, setTeam] = useState({});
  // const { t } = useTranslation();
  let [teams, setTeams] = useState([]);
  let [members, setMembers] = useState([]);
  const [cookies, setCookie] = useCookies(["lang"]);
  const [width, setwidth] = useState(window.innerWidth);

  const handleChange = React.useCallback((newValue) => {
    localStorage.removeItem("TournamentID");
    fetch(`${BaseUrl}teams/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": "en",
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        setTeams(Resp.data);
      });
    });
  });

  useEffect(() => {
    fetch(`${BaseUrl}teams/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": "en",
      },
    }).then(function (response11) {
      response11.json().then(function (Resp) {
        setTeams(Resp.data);
        setLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (removePop == true) {
      setTimeout(() => {
        setRemovePop(false);
      }, 1000);
    }
  }, [removePop]);

  useEffect(() => {
    if (createPop == true) {
      setTimeout(() => {
        setCreatePop(false);
      }, 1000);
    }
  }, [createPop]);

  useEffect(() => {
    if (updatePop == true) {
      setTimeout(() => {
        setUpdatePop(false);
      }, 1000);
    }
  }, [updatePop]);

  useEffect(() => {
    if (viewInvitePop == true) {
      setTimeout(() => {
        setViewInvitePop(false);
      }, 1000);
    }
  }, [viewInvitePop]);

  useEffect(() => {
    if (viewTeamDetailsPop == true) {
      setTimeout(() => {
        setViewTeamDetailsPop(false);
      }, 1000);
    }
  }, [viewTeamDetailsPop]);

  useEffect(() => {
    if (invitePop == true) {
      setTimeout(() => {
        setInvitePop(false);
      }, 1000);
    }
  }, [invitePop]);

  useEffect(() => {
    if (viewTeamMembersPop == true) {
      setTimeout(() => {
        setViewTeamMembersPop(false);
      }, 1000);
    }
  }, [viewTeamMembersPop]);
  useEffect(() => {
    if (viewPopAssign == true) {
      setTimeout(() => {
        setviewPopAssign(false);
      }, 1000);
    }
  }, [viewPopAssign]);

  function showMembers(id) {
    document.getElementById("manager_dash").style.display = "none";
    document.getElementById("manager_members").style.display = "block";
    if (id) {
      setLoading1(true);
      fetch(`${BaseUrl}GetTeamMembersInprogress/${id}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (resp) {
          setLoading1(false);
          setMembers(resp.data);
          // setLoading(false)
        });
      });
    }
  }
  function showDashboard() {
    document.getElementById("manager_dash").style.display = "block";
    document.getElementById("manager_members").style.display = "none";
  }

  if (isLoading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }
  return (
    <>
      <div id="manager_dash">
        {/*<RemoveTeamModal*/}
        {/*  show={removePop}*/}
        {/*  teamName={team.name}*/}
        {/*  teamID={team.id}*/}
        {/*  onChange={handleChange}*/}
        {/*/>*/}
        <CreateTeamModal show={createPop} onChange={handleChange} />
        {/*<UpdateTeamModal show={updatePop} team={team} onChange={handleChange} />*/}
        {/*<ViewInvitationsModal show={viewInvitePop} teamID={team.id} />*/}
        {/*<ViewTeamModal show={viewTeamDetailsPop} teamID={team.id} />*/}
        {/*<InviteModal*/}
        {/*  show={invitePop}*/}
        {/*  onChange={handleChange}*/}
        {/*  teamID={team.id}*/}
        {/*/>*/}
        {/*<TeamMembersModal show={viewTeamMembersPop} teamID={team.id} />*/}
        <AssignTournamentToTeamModal
          show={viewPopAssign}
          teamID={team.id}
          onChange={handleChange}
        />
        {width > 950 ? (
          <div className="desktop">
            <section className=" container-fluid p-4 bg_black">
              <Usernav username={username} />
              <div className="container-fluid mt-3  bg-grey p-3 mb-3">
                <div className="pb-1">
                  <a>
                    <span
                      className={
                        cookies.lang === "ar"
                          ? "float-end text-white rtl"
                          : "float-start text-white ltr"
                      }>
                      {i18next.t("Team_panel")}
                    </span>
                  </a>
                  <a className="float-end yellow"></a>
                </div>
                <br></br>
                <br></br>
                <div className="kid-manage p-4">
                  <div
                    className="pb-3"
                    // dir={cookies.lang === "ar" ? "ltr" : ""}
                  >
                    <a>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>{username}</Tooltip>}>
                        <span className="float-start yellow kid">
                          {cookies.lang === "ar" ? i18next.t("teams") : ""}
                          {username.length > 27
                            ? username.substring(0, 27) + "..."
                            : username}
                          {cookies.lang === "en" ? i18next.t("teams") : ""}

                          {/* {i18next.t("teams")} */}
                        </span>
                      </OverlayTrigger>
                    </a>

                    <a
                      href="#kid_assign"
                      data-bs-toggle="collapse"
                      className="float-end yellow mt-2 ">
                      <span>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect width="32" height="32" rx="16" fill="#100E23" />
                          <path
                            d="M11.41 19.41L16 14.83L20.59 19.41L22 18L16 12L10 18L11.41 19.41Z"
                            fill="#EEE056"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="row " id="kid_assign">
                    <div className="col-md-12 kid-char pb-0">
                      <div className="row childs_row  pt-3">
                        {teams.length > 0 ? (
                          <>
                            <div className="col-md-2 mt-0 pt-4 pb-3 text-center child_one ">
                              {/* <br/><br/><br/> */}
                              <a
                                class="text-primary point"
                                onClick={() => setCreatePop(true)}>
                                <svg
                                  className="mt-2 mb-4"
                                  width="90"
                                  height="85"
                                  viewBox="0 0 316 322"
                                  fill="#100E23"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                    stroke="#100E23"
                                    stroke-width="0.590604"
                                  />
                                  <g clip-path="url(#clip0_950_3103)">
                                    <path
                                      d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_950_3103">
                                      <rect
                                        width="102.778"
                                        height="100"
                                        fill="white"
                                        transform="translate(107 111)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="font_20 ">
                                  {i18next.t("create_team")}{" "}
                                </p>
                              </a>
                            </div>

                            {teams.map((item, index) => (
                              <div
                                className="col-md-2 m-2 text-center container_card "
                                style={{ position: "relative" }}>
                                <div className="team_card ">
                                  <img
                                    src={
                                      item.logo !== ""
                                        ? item.logo
                                        : "../assets/imgs/kidwatch.png"
                                    }
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}></img>
                                </div>
                                <div className="black-layer ">
                                  {/* <a
                                  className=" yellow "
                                  style={{ top: "-10px" }}>
                                  <Space wrap>
                                    <Dropdown
                                      menu={menuProps}
                                      trigger={["click"]}>
                                      <Button
                                        onClick={() => handleOpenChange(item)}
                                        variant="link">
                                        <Space>
                                          <MenuOutlined />
                                        </Space>
                                      </Button>
                                    </Dropdown>
                                  </Space>
                                </a> */}
                                  {/* <div
                                  className="text-center child_one"
                                  style={{ top: "-25px", left: "-30px" }}> */}
                                  <div
                                    className="hexagon point"
                                    onClick={() =>
                                      navigate("/teamPage", {
                                        state: {
                                          teamId: item.id,
                                          logo:
                                            item.logo !== ""
                                              ? item.logo
                                              : "../assets/imgs/kidwatch.png",
                                        },
                                      })
                                    }>
                                    <img
                                      src={
                                        item.logo !== ""
                                          ? item.logo
                                          : "../assets/imgs/kidwatch.png"
                                      }
                                      className=" team-img"
                                    />
                                  </div>
                                  <OverlayTrigger
                                    key="top"
                                    placement="top"
                                    overlay={<Tooltip>{item.name}</Tooltip>}>
                                    <p
                                      className="letters_limit text-white point"
                                      style={{ marginTop: "20px" }}
                                      onClick={() =>
                                        navigate("/teamPage", {
                                          state: {
                                            teamId: item.id,
                                            logo:
                                              item.logo !== ""
                                                ? item.logo
                                                : "../assets/imgs/kidwatch.png",
                                          },
                                        })
                                      }>
                                      {item.name.length > 7
                                        ? item.name.substring(0, 7) + "..."
                                        : item.name}
                                    </p>
                                  </OverlayTrigger>
                                  {/* <p className="pb-1">Lvl {item.level_id}</p> */}
                                  <button
                                    className="black-btn p-2 mt-3 "
                                    style={{ borderRadius: "10px" }}
                                    onClick={() =>
                                      navigate(`/inprogressTeam/${item.id}`, {
                                        state: {},
                                      })
                                    }>
                                    {i18next.t("Team_Inprogress")}
                                  </button>
                                  {/*<p style={{ color: '#100E23',fontSize:'12px'}} className="pb-0 ">ClientId: </p>*/}
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="w-50 empty_teams text-center container ">
                            <a
                              class="text-primary point"
                              onClick={() => setCreatePop(true)}>
                              <svg
                                className="mt-2 mb-4"
                                width="90"
                                height="85"
                                viewBox="0 0 316 322"
                                fill="#100E23"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                  stroke="#100E23"
                                  stroke-width="0.590604"
                                />
                                <g clip-path="url(#clip0_950_3103)">
                                  <path
                                    d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_950_3103">
                                    <rect
                                      width="102.778"
                                      height="100"
                                      fill="white"
                                      transform="translate(107 111)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p className="font_20 ">
                                {i18next.t("create_team")}{" "}
                              </p>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div className="mobile">
            <section className=" container-fluid p-4 bg_black">
              <Usernav username={username} />
              <div className="container-fluid mt-3  bg-grey p-4 ">
                <div className="pb-3">
                  <a>
                    <span className={
                        cookies.lang === "ar"
                          ? "float-end text-white rtl"
                          : "float-start text-white ltr"
                      } >
                      {i18next.t("Team_panel")}
                    </span>
                  </a>
                  <a className="float-end yellow">
                    <span></span>
                  </a>
                </div>
                <br></br>
                <br></br>
                <div className="kid-manage p-3">
                  <div className="pb-3">
                    <a>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>{username}</Tooltip>}>
                        <span
                          className="float-start yellow kid break letters_limit"
                          style={{}}>
                          {cookies.lang === "ar" ? i18next.t("teams") : ""}
                          {username.length > 27
                            ? username.substring(0, 27) + "..."
                            : username}
                          {cookies.lang === "en" ? i18next.t("teams") : ""}
                        </span>
                      </OverlayTrigger>
                    </a>
                    <a
                      href="#kid_assign1"
                      data-bs-toggle="collapse"
                      className="float-end yellow mt-2">
                      <span>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect width="32" height="32" rx="16" fill="#100E23" />
                          <path
                            d="M11.41 19.41L16 14.83L20.59 19.41L22 18L16 12L10 18L11.41 19.41Z"
                            fill="#EEE056"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <br></br>
                  <br></br>
                  <div id="kid_assign1">
                    <div className="kid-char pdash ">
                      <div
                        class="tab-pane yellow_back container active mt-3 radius_4"
                        id="kid-avatar">
                        {teams.length != 0 ? (
                          <div className="w-100  text-start child_one point">
                            <a
                              class="text-primary "
                              onClick={() => setCreatePop(true)}>
                              <svg
                                className="m-1 pt-0 svg_fill"
                                width="60"
                                height="60"
                                viewBox="0 0 316 322"
                                fill="#100E23"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                  stroke="#100E23"
                                  stroke-width="0.590604"
                                />
                                <g clip-path="url(#clip0_950_3103)">
                                  <path
                                    d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_950_3103">
                                    <rect
                                      width="102.778"
                                      height="100"
                                      fill="white"
                                      transform="translate(107 111)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p
                                className="font_12 "
                                style={{ color: "#100E23" }}>
                                {i18next.t("create_team")}{" "}
                              </p>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="childs_row row text-center p-4">
                          {teams.length > 0 ? (
                            <>
                              {teams.map((item, index) => (
                                // <div className=" mt-3">
                                //   <a
                                //     className=" yellow float-end"
                                //     style={{ marginTop: "-25px" }}>
                                //     <Dropdown
                                //       menu={menuProps}
                                //       trigger={["click"]}>
                                //       <Button
                                //         onClick={() => handleOpenChange(item)}
                                //         variant="link">
                                //         <Space>
                                //           <MenuOutlined />
                                //         </Space>
                                //       </Button>
                                //     </Dropdown>
                                //   </a>
                                //   <div className="w-100  text-center child_one ">
                                //     <div className="hexagon">
                                //       <img
                                //         src={
                                //           item.logo !== ""
                                //             ? item.logo
                                //             : "../assets/imgs/kidwatch.png"
                                //         }
                                //         className=" team-img"
                                //       />
                                //     </div>
                                //     <OverlayTrigger
                                //       key="top"
                                //       placement="top"
                                //       overlay={<Tooltip>{item.name}</Tooltip>}>
                                //       <p
                                //         style={{ color: "#100E23" }}
                                //         className="letters_limit ">
                                //         {item.name.length > 27
                                //           ? item.name.substring(0, 27) + "..."
                                //           : item.name}
                                //       </p>
                                //     </OverlayTrigger>
                                //     <p
                                //       style={{ color: "#100E23" }}
                                //       className="pb-0 ">
                                //       Lvl {item.level_id}
                                //     </p>
                                //     <p
                                //       style={{
                                //         color: "#100E23",
                                //         fontSize: "12px",
                                //       }}
                                //       className="pb-0 ">
                                //       ClientId: {item.clientid}
                                //     </p>
                                //   </div>
                                // </div>
                                <div
                                  className="  text-center container_card mb-3"
                                  style={{ position: "relative" }}>
                                  <div className="team_card">
                                    <img
                                      src={
                                        item.logo !== ""
                                          ? item.logo
                                          : "../assets/imgs/kidwatch.png"
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}></img>
                                  </div>
                                  <div className="black-layer">
                                    {/* <a
                                  className=" yellow "
                                  style={{ top: "-10px" }}>
                                  <Space wrap>
                                    <Dropdown
                                      menu={menuProps}
                                      trigger={["click"]}>
                                      <Button
                                        onClick={() => handleOpenChange(item)}
                                        variant="link">
                                        <Space>
                                          <MenuOutlined />
                                        </Space>
                                      </Button>
                                    </Dropdown>
                                  </Space>
                                </a> */}
                                    {/* <div
                                  className="text-center child_one"
                                  style={{ top: "-25px", left: "-30px" }}> */}
                                    <div
                                      className="hexagon point"
                                      onClick={() =>
                                        navigate("/teamPage", {
                                          state: {
                                            teamId: item.id,
                                            logo:
                                              item.logo !== ""
                                                ? item.logo
                                                : "../assets/imgs/kidwatch.png",
                                          },
                                        })
                                      }>
                                      <img
                                        src={
                                          item.logo !== ""
                                            ? item.logo
                                            : "../assets/imgs/kidwatch.png"
                                        }
                                        className=" team-img"
                                      />
                                    </div>
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={<Tooltip>{item.name}</Tooltip>}>
                                      <p
                                        className="letters_limit text-white point"
                                        style={{ marginTop: "20px" }}
                                        onClick={() =>
                                          navigate("/teamPage", {
                                            state: {
                                              teamId: item.id,
                                              logo:
                                                item.logo !== ""
                                                  ? item.logo
                                                  : "../assets/imgs/kidwatch.png",
                                            },
                                          })
                                        }>
                                        {item.name.length > 7
                                          ? item.name.substring(0, 7) + "..."
                                          : item.name}
                                      </p>
                                    </OverlayTrigger>
                                    {/* <p className="pb-1">Lvl {item.level_id}</p> */}
                                    <button
                                      className="black-btn p-2 mt-5 "
                                      style={{ borderRadius: "10px" }}
                                      onClick={
                                        () =>
                                          navigate(
                                            `/inprogressTeam/${item.id}`,
                                            {
                                              state: {},
                                            }
                                          )
                                        // showMembers(item.id)
                                      }>
                                      {i18next.t("Team_Inprogress")}
                                    </button>
                                    {/*<p style={{ color: '#100E23',fontSize:'12px'}} className="pb-0 ">ClientId: </p>*/}
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="w-100 text-white  text-center container empty_kids ">
                              <a
                                class="text-primary pb-3 point"

                                onClick={() => setCreatePop(true)}>
                                <svg
                                  className="m-1 pt-0 svg_fill"
                                  width="130"
                                  height="130"
                                  viewBox="0 0 316 322"
                                  fill="#100E23"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                    stroke="#100E23"
                                    stroke-width="0.590604"
                                  />
                                  <g clip-path="url(#clip0_950_3103)">
                                    <path
                                      d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_950_3103">
                                      <rect
                                        width="102.778"
                                        height="100"
                                        fill="white"
                                        transform="translate(107 111)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p
                                  className="font_15 "
                                  style={{ color: "#100E23" }}>
                                  {i18next.t("create_team")}{" "}
                                </p>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
            </section>
          </div>
        )}
      </div>
      <div id="manager_members" style={{ display: "none" }}>
        {width > 950 ? (
          <div className="desktop">
            <section className=" container-fluid p-4 pb-1 bg_black ">
              {loading == true ? (
                <div className="w-100 mt-3 mb-2 m-auto">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div
                    className={
                      cookies.lang === "ar"
                        ? "ltr container-fluid mt-3  bg-grey p-1 pb-3 manager_scroll"
                        : "ltr container-fluid mt-3  bg-grey p-1 pb-3 manager_scroll"
                    }>
                    {members && members.length > 0 ? (
                      members.map((item) => (
                        <div className="row container-fluid mt-5 dash ">
                          <p className="text-white text-start">
                            {item.generalDetails.name}
                          </p>
                          <div className="col-md-5 row radius_4">
                            <div className="container-fluid parent-watch pt-3 pb-3 w-40 radiusRU_4">
                              <p className="title">
                                <span className="text-capitalize">
                                  {item.generalDetails.name}
                                </span>
                                {i18next.t("apostrophe_fitbit")}
                              </p>
                              <p className="activity">
                                {i18next.t("calories_burned")}
                              </p>
                              <p className="calory">
                                {item.DeviceDetails.Callories !== null
                                  ? item.DeviceDetails.Callories
                                  : 0}
                              </p>
                              <p className="activity mt-5">
                                {i18next.t("heart_rate")}
                              </p>
                              <p className="heart">
                                {item.DeviceDetails.heartRate !== null
                                  ? item.DeviceDetails.heartRate
                                  : 0}
                              </p>
                              <p className="activity mt-5">{i18next.t("km")}</p>
                              <p
                                className={
                                  item.DeviceDetails.Distance > 999
                                    ? "heart1"
                                    : "heart"
                                }>
                                {item.DeviceDetails.Distance !== null
                                  ? item.DeviceDetails.Distance
                                  : 0}
                              </p>
                              {/* <div className="d-flex justify-content-start mt-5">
                                <a className="btn blue-btn">
                                  <span className="p-2">
                                    {en.dgamer.all_activity_btn}
                                  </span>
                                  <span>
                                    <svg
                                      width="12"
                                      height="16"
                                      viewBox="0 0 12 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </div> */}
                            </div>
                            <div className="w-60 parent-watch  pt-3 pb-3 radiusLU_4">
                              <div className="text-end">
                                <div class="dropdown">
                                  <button
                                    type="button"
                                    class="btn dropdown-toggle"
                                    data-bs-toggle="dropdown">
                                    <img src="../assets/imgs/drop.png" />
                                  </button>
                                  <ul class="dropdown-menu dropdown-content">
                                    <li id="deviceVersion">
                                      {item.DeviceDetails.device
                                        .WearableType !== "null"
                                        ? item.DeviceDetails.device.WearableType
                                        : ""}{" "}
                                    </li>
                                    <li id="deviceName">
                                      {item.DeviceDetails.device
                                        .WearableName !== "null" &&
                                      item.DeviceDetails.device
                                        .isApplication !== "true"
                                        ? item.DeviceDetails.device.WearableName
                                        : ""}{" "}
                                    </li>
                                    <li id="batteryLevel">
                                      {item.DeviceDetails.device
                                        .WearableCharging !== "null" &&
                                      item.DeviceDetails.device
                                        .isApplication !== "true"
                                        ? item.DeviceDetails.device
                                            .WearableCharging
                                        : ""}{" "}
                                    </li>

                                    <li id="demo">
                                      {
                                        // item.DeviceDetails.device
                                        //   .isApplication !== "null" ||
                                        item.DeviceDetails.device
                                          .isApplication !== null
                                          ? item.DeviceDetails.device
                                              .isApplication == "true"
                                            ? i18next.t(
                                                "You_are_using_application"
                                              )
                                            : i18next.t(
                                                "You_are_linking_with_device"
                                              )
                                          : i18next.t(
                                              "Please_link_your_account_with_device"
                                            )
                                      }{" "}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="watch-img ">
                                {item.DeviceDetails.device.WearableType == 1 ? (
                                  <img
                                    src={
                                      item.DeviceDetails.device.isApplication ==
                                      false
                                        ? "../assets/imgs/wear2.png"
                                        : "../assets/imgs/fitapp.png"
                                    }
                                    className="img-fluid"
                                  />
                                ) : item.DeviceDetails.device.WearableType ==
                                  "GoogleFit" ? (
                                  <img
                                    src={
                                      item.DeviceDetails.device.isApplication ==
                                      false
                                        ? "../assets/imgs/NewProject.png"
                                        : "../assets/imgs/googleFit.png"
                                    }
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={
                                      item.DeviceDetails.device.isApplication ==
                                      false
                                        ? "../assets/imgs/NewProject.png"
                                        : "../assets/imgs/garminlogo.png"
                                    }
                                    className="img-fluid"
                                  />
                                )}
                                {/* <img
                                  src={"../assets/imgs/wear2.png"}
                                  className="img-fluid"
                                /> */}
                              </div>
                            </div>
                            {/* {haswearble.length ==0 && (
                            <div className='connect p-2 radiusLD_4 '>
                            <span>
                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3.5" cy="3.5" r="3" fill="#FF0000"/>
                                    </svg>
                            </span>
                            <span className='p-1'>{en.dgamer.disconnect}</span>
                            </div>)}
                            {haswearble.length > 0 && (
                            <div className='connect p-2 radiusLD_4 '>
                            <span>
                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3.5" cy="3.5" r="3" fill="#27AE60"/>
                                    </svg>
                            </span>
                            <span className='p-1'>{en.dgamer.connect}</span>
                            </div>)} */}
                          </div>
                          <div
                            className={
                              cookies.lang === "ar"
                                ? "ltr col-md-7 row radius_4 dist bg_blue level-img"
                                : "ltr col-md-7 row radius_4 dist bg_blue level-img"
                            }>
                            <div className="container-fluid  pt-3 pb-3 w-40">
                              <p className="title text-start text-white">
                                {i18next.t("progress")}
                              </p>
                              <p className="activity text-white">
                                {i18next.t("current_level")}
                              </p>
                              <p className="level">
                                {item.generalDetails.UserLeve !== null
                                  ? item.generalDetails.UserLeve
                                  : 0}
                              </p>
                              <p className="activity text-white mt-5">
                                {i18next.t("next_level")}
                              </p>
                              <p className=" mb-5 text-start mt-4">
                                <span className="heart text-white">0</span>
                                <span className="divi"> / </span>
                                <span className="all"> 1</span>
                              </p>
                              <div className="d-flex justify-content-start mt-5">
                                <a
                                  className="btn yellow-btn"
                                  onClick={() =>
                                    navigate("/memberChallenge", {
                                      state: {
                                        challengeDetails:
                                          item.challengeDetails &&
                                          item.challengeDetails != null &&
                                          item.challengeDetails.length > 0
                                            ? item.challengeDetails
                                            : null,
                                      },
                                    })
                                  }>
                                  <span className="p-2">
                                    {i18next.t("view_challenges_btn")}
                                  </span>
                                  <span>
                                    <svg
                                      width="14"
                                      height="15"
                                      viewBox="0 0 14 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                                        fill="#100E23"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            </div>
                            <div className="w-60 ">
                              <div className="text-end mt-3 ">
                                <div class="dropdown">
                                  <button
                                    type="button"
                                    class="btn dropdown-toggle"
                                    data-bs-toggle="dropdown">
                                    {/* <img src='../assets/imgs/drop.png'/> */}
                                    <span className="m-3"></span>
                                  </button>
                                </div>
                              </div>
                              <div className="all-char">
                                <div className="level-char">
                                  <img
                                    src="../assets/imgs/avatar.png"
                                    className=""
                                  />
                                  <img
                                    src="../assets/imgs/poly.png"
                                    className="poly"
                                  />
                                  <h6>Techno Mage</h6>
                                  <p>
                                    {item.ChallengeInprogress !== null
                                      ? item.ChallengeInprogress
                                      : 0}
                                    %
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-white" style={{ marginTop: "25%" }}>
                        {i18next.t("No_members_added")}
                      </p>
                    )}
                  </div>
                  <div
                    className="container-fluid text-start pt-3 bg-grey "
                    style={{ paddingLeft: "25px" }}>
                    <a
                      className="btn blue-btn back_a"
                      onClick={() => showDashboard()}>
                      <span className="p-2"> {i18next.t("back")}</span>
                      <span>
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </>
              )}
            </section>
          </div>
        ) : (
          <div className="mobile">
            <section className=" container-fluid p-2 bg_black">
              {loading == true ? (
                <div className="w-100 mt-3 mb-2 m-auto">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="container-fluid mt-3  bg-grey p-3  manager_scroll">
                    {members && members.length > 0 ? (
                      members.map((item, index) => (
                        <div className="row container-fluid mt-5 dash pdash m-0">
                          <ul class="nav nav-pills ">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                data-bs-toggle="pill"
                                href={`#device${index}`}>
                                {i18next.t("device")}
                              </a>
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link "
                                data-bs-toggle="pill"
                                href={`#progress${index}`}>
                                {i18next.t("progress")}
                              </a>
                            </li>
                          </ul>
                          {/* Tab panes */}
                          <div class="tab-content p-0  m-0">
                            <div
                              class="tab-pane container-fluid m-0 active mt-3 p-0 "
                              id={`device${index}`}>
                              <div className=" parent-watch  pt-3 pb-3 radiusU_4 ">
                                <div className="  m-3">
                                  <div class="dropdown float-end">
                                    <button
                                      type="button"
                                      class="btn dropdown-toggle"
                                      data-bs-toggle="dropdown">
                                      <img
                                        src="../assets/imgs/drop.png"
                                        className=""
                                      />
                                    </button>
                                    <ul className="dropdown-menu dropdown-content">
                                      <li id="deviceVersion">
                                        {item.DeviceDetails.device
                                          .WearableType !== "null"
                                          ? item.DeviceDetails.device
                                              .WearableType
                                          : ""}{" "}
                                      </li>
                                      <li id="deviceName">
                                        {item.DeviceDetails.device
                                          .WearableName !== "null" &&
                                        item.DeviceDetails.device
                                          .isApplication !== "true"
                                          ? item.DeviceDetails.device
                                              .WearableName
                                          : ""}{" "}
                                      </li>
                                      <li id="batteryLevel">
                                        {item.DeviceDetails.device
                                          .WearableCharging !== "null" &&
                                        item.DeviceDetails.device
                                          .isApplication !== "true"
                                          ? item.DeviceDetails.device
                                              .WearableCharging
                                          : ""}{" "}
                                      </li>

                                      <li id="demo">
                                        {item.DeviceDetails.device
                                          .isApplication !== null
                                          ? item.DeviceDetails.device
                                              .isApplication == "true"
                                            ? i18next.t(
                                                "You_are_using_application"
                                              )
                                            : i18next.t(
                                                "You_are_linking_with_device"
                                              )
                                          : i18next.t(
                                              "Please_link_your_account_with_device"
                                            )}{" "}
                                      </li>
                                    </ul>
                                  </div>
                                  <p className="title text-start">
                                    <span className="text-capitalize">
                                      {item.generalDetails.name}
                                    </span>
                                    {i18next.t("apostrophe_fitbit")}
                                  </p>
                                </div>
                                <div className="watch-img ">
                                  {item.DeviceDetails.device.WearableType ==
                                  1 ? (
                                    <img
                                      src={
                                        item.DeviceDetails.device
                                          .isApplication == false
                                          ? "../assets/imgs/wear2.png"
                                          : "../assets/imgs/fitapp.png"
                                      }
                                      className="img-fluid"
                                    />
                                  ) : item.DeviceDetails.device.WearableType ==
                                    "GoogleFit" ? (
                                    <img
                                      src={
                                        item.DeviceDetails.device
                                          .isApplication == false
                                          ? "../assets/imgs/NewProject.png"
                                          : "../assets/imgs/googleFit.png"
                                      }
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        item.DeviceDetails.device
                                          .isApplication == false
                                          ? "../assets/imgs/NewProject.png"
                                          : "../assets/imgs/garminlogo.png"
                                      }
                                      className="img-fluid"
                                    />
                                  )}
                                  {/* <img
                                    src={"../assets/imgs/wear2.png"}
                                    className="img-fluid"
                                  /> */}
                                </div>
                              </div>
                              <div className="container-fluid parent-watch pt-3 pb-3 ">
                                <div className="row">
                                  <div className="w-40">
                                    <p className="activity">
                                      {i18next.t("calories_burned")}
                                    </p>
                                    <p className="calory">
                                      {item.DeviceDetails.Callories !== null
                                        ? item.DeviceDetails.Callories
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="w-30">
                                    <p className="activity ">
                                      {i18next.t("heart_rate")}
                                    </p>
                                    <p className="heart">
                                      {item.DeviceDetails.heartRate !== null
                                        ? item.DeviceDetails.heartRate
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="w-30">
                                    <p className="activity ">
                                      {i18next.t("km")}
                                    </p>
                                    <p
                                      className={
                                        item.DeviceDetails.Distance > 999
                                          ? "heart1"
                                          : "heart"
                                      }>
                                      {item.DeviceDetails.Distance !== null
                                        ? item.DeviceDetails.Distance
                                        : 0}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="d-flex justify-content-center mt-5">
                                  <a className="btn blue-btn w-100">
                                    <span className="p-2">
                                      {en.dgamer.all_activity_btn}
                                    </span>
                                    <span>
                                      <svg
                                        width="12"
                                        height="16"
                                        viewBox="0 0 12 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                </div> */}
                              </div>

                              {/* {haswearble.length ==0 && (
                                          <div className='connect p-2 radiusLD_4 '>
                                          <span>
                                                  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <circle cx="3.5" cy="3.5" r="3" fill="#FF0000"/>
                                                  </svg>
                                          </span>
                                          <span className='p-1'>{en.dgamer.disconnect}</span>
                                          </div>)}
                                          {haswearble.length > 0 && (
                                          <div className='connect p-2 radiusLD_4 '>
                                          <span>
                                                  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <circle cx="3.5" cy="3.5" r="3" fill="#27AE60"/>
                                                  </svg>
                                          </span>
                                          <span className='p-1'>{en.dgamer.connect}</span>
                                          </div>)} */}
                            </div>
                            <div
                              className={
                                cookies.lang === "ar"
                                  ? "rtl tab-pane container-fluid m-0 fade dist bg_blue level-img mt-3 p-0 radius_4"
                                  : "ltr tab-pane container-fluid m-0 fade dist bg_blue level-img mt-3 p-0 radius_4"
                              }
                              id={`progress${index}`}>
                              <div className=" p-3">
                                <div class="dropdown float-end">
                                  <button
                                    type="button"
                                    class="btn dropdown-toggle"
                                    data-bs-toggle="dropdown">
                                    {/* <img src='../assets/imgs/drop.png'/> */}
                                    <span className="m-3"></span>
                                  </button>
                                </div>
                                <p className="title text-start text-white">
                                  {i18next.t("progress")}
                                </p>
                              </div>
                              <div className="container-fluid row pt-3 pb-3 ">
                                <div className="w-50">
                                  <p className="activity text-white">
                                    {i18next.t("current_level")}
                                  </p>
                                  <p className="level">
                                    {item.generalDetails.UserLeve !== null
                                      ? item.generalDetails.UserLeve
                                      : 0}
                                  </p>
                                </div>
                                <div className="w-50">
                                  <p className="activity text-white mb-5">
                                    {i18next.t("next_level")}
                                  </p>
                                  <p className=" mb-5 text-start mt-4">
                                    <span className="heart text-white">0</span>
                                    <span className="divi"> / </span>
                                    <span className="all"> 1</span>
                                  </p>
                                </div>
                              </div>
                              <div className="all-char">
                                <div className="level-char">
                                  <img
                                    src="../assets/imgs/avatar.png"
                                    className="img-fluid"
                                  />
                                  <img
                                    src="../assets/imgs/poly.png"
                                    className="poly img-fluid"
                                  />
                                </div>
                                <h6>Techno Mage</h6>
                                <p>
                                  {item.ChallengeInprogress !== null
                                    ? item.ChallengeInprogress
                                    : 0}
                                  %
                                </p>
                              </div>
                              <div className="d-flex justify-content-center mt-5 p-4 ">
                                <a
                                  className="btn yellow-btn w-100"
                                  onClick={() =>
                                    navigate("/memberChallenge", {
                                      state: {
                                        challengeDetails:
                                          item.challengeDetails &&
                                          item.challengeDetails != null &&
                                          item.challengeDetails.length > 0
                                            ? item.challengeDetails
                                            : null,
                                      },
                                    })
                                  }>
                                  <span className="p-2">
                                    {i18next.t("view_challenges_btn")}
                                  </span>
                                  <span>
                                    <svg
                                      width="14"
                                      height="15"
                                      viewBox="0 0 14 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M6.99398 0.833332C3.31398 0.833332 0.333984 3.82 0.333984 7.5C0.333984 11.18 3.31398 14.1667 6.99398 14.1667C10.6807 14.1667 13.6673 11.18 13.6673 7.5C13.6673 3.82 10.6807 0.833332 6.99398 0.833332ZM11.9807 5.60667L8.64065 5.32L7.30732 2.18C9.44732 2.30667 11.2473 3.69333 11.9807 5.60667ZM8.60065 9.78L7.00065 8.82L5.40732 9.78L5.82732 7.96667L4.42065 6.74667L6.27398 6.58667L7.00065 4.87333L7.72732 6.58L9.58065 6.74L8.17398 7.96L8.60065 9.78ZM6.69398 2.18L5.36065 5.32667L2.01398 5.61333C2.74732 3.69333 4.54732 2.3 6.69398 2.18ZM1.66732 7.5C1.66732 7.07333 1.72065 6.66 1.82065 6.26L4.34732 8.44667L3.60732 11.6133C2.42065 10.6333 1.66732 9.15333 1.66732 7.5ZM4.22732 12.0467L7.00065 10.3733L9.77398 12.04C8.96065 12.54 8.01398 12.8333 6.99398 12.8333C5.98065 12.8333 5.03398 12.54 4.22732 12.0467ZM10.394 11.6133L9.65398 8.44667L12.1807 6.26C12.274 6.65333 12.334 7.07333 12.334 7.5C12.334 9.15333 11.574 10.6333 10.394 11.6133Z"
                                        fill="#100E23"
                                      />
                                    </svg>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-white" style={{ marginTop: "55%" }}>
                        {i18next.t("No_members_added")}
                      </p>
                    )}
                  </div>
                  <div
                    className="container-fluid text-start  bg-grey pt-3"
                    style={{ paddingLeft: "25px" }}>
                    <a
                      className="btn blue-btn back_a"
                      onClick={() => showDashboard()}>
                      <span className="p-2">{i18next.t("back")}</span>
                      <span>
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </>
              )}
            </section>
          </div>
        )}
      </div>
    </>
  );
}
