import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import en from "../resources/en.json";
import i18next from "../i18n/config";
import { useCookies } from "react-cookie";

export default function Head() {
  const [cookies, setCookie] = useCookies(["lang"]);

  return (
    <section className="head container-fluid p-3">
      <span className="yellow" dir={cookies.lang=="ar"?"rtl":"ltr"}> {i18next.t("head_betatitle")}

      </span>
        <br/>
        <span dir={cookies.lang=="ar"?"rtl":"ltr"}> {i18next.t("head_title")} </span>
    </section>
  );
}
