import * as React from 'react';
import  { Component } from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import { useState, useEffect } from "react";
import { Modal,Button} from 'react-bootstrap';
import en from '../resources/en.json';
import { useDispatch, useSelector } from "react-redux";
import i18next from '../i18n/config';
import { useCookies } from 'react-cookie';
export default function AlertDialog(props) {
  const id = useSelector((state) => state.appReducer.id);

  let [show, setShow] = useState(props.show);

   useEffect(() => {
    if(props.show==true){
      setShow(props.show)
    }
    }, [props.show]);

  const handleClose = () => setShow(false);
  
  const location = useLocation();
  const content=props.Modalcontent;
  const [cookies, setCookie] = useCookies(['lang']);
  return (
    <>
    
    <div class="modelround" className="modal">
    <Modal
       show={show}
      onHide={handleClose}
      backdrop="static" 
      keyboard={false}
    >
      <Modal.Header class="modelstyle" closeButton>
        <Modal.Title class="modelstyle">{en.RewardDialog.veryality}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={cookies.lang  === 'ar' ? 'modelstyle text-end p-3' : 'modelstyle text-start p-3'}>
        {content}
      </Modal.Body>
      <Modal.Footer class="modelstyle">
      <div class="d-flex justify-content-end mt-4 button_right">
        <Button class="blue-btn" variant="primary" onClick={handleClose}>
        {i18next.t("close")}
        </Button>
        </div>
      </Modal.Footer>
    </Modal>
    </div>
  </>
  );
}

