import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import en from "../resources/en.json";
import TournamentchallengesModal from "../ESport/TournamentchallengesModal";
import config from "../config.json";
import ViewTournamentModal from "../ESport/ViewTournamentModal";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../i18n/config";
import AssignTeamToTournamentModal from "../ESport/AssignTeamToTournamentModal";
// import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faUserCircle,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { useCookies } from "react-cookie";
import { Typography } from "antd/lib";

export default function Game_new2(props) {
  // const navigate = useNavigate();
  let BaseUrl = config.BaseUrl;
  var hostName = window.location.origin;
  // var BaseUrl="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  let [viewTeamDetailsPop, setViewTeamDetailsPop] = useState(false);
  let [viewPop, setviewPop] = useState(false);
  let [viewassigntournement, setviewassigntournement] = useState(false);
  const role = useSelector((state) => state.appReducer.role);
  const [cookies, setCookie] = useCookies(["lang"]);
  useEffect(() => {
    if (viewPop == true) {
      setTimeout(() => {
        setviewPop(false);
      }, 1000);
    }
  }, [viewPop]);

  function clickviewchallenges() {
    setviewPop(true);
  }

  function clickassigntournament() {
    setviewassigntournement(true);
  }

  useEffect(() => {
    if (viewPop == true) {
      setTimeout(() => {
        setviewPop(false);
      }, 1000);
    }
  }, [viewPop]);
  useEffect(() => {
    if (viewassigntournement == true) {
      setTimeout(() => {
        setviewassigntournement(false);
      }, 1000);
    }
  }, [viewassigntournement]);
  useEffect(() => {
    if (viewTeamDetailsPop == true) {
      setTimeout(() => {
        setViewTeamDetailsPop(false);
      }, 1000);
    }
  }, [viewTeamDetailsPop]);
  const handleChange = React.useCallback((newValue) => {
    // localStorage.removeItem("TournamentID");
    // fetch(`${BaseUrl}teams/${id}`, {
    //   method: "get",
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //     'Accept-Language': 'en',
    //   },
    // }).then(function (response11) {
    //   response11.json().then(function (Resp) {
    //     setTeams(Resp.data);
    //   });
    // });
  });
  function percent(num, total) {
    var result = (100 * Number(num)) / Number(total);
    return result;
  }
  return (
    <>
      {role == 5 ? (
        <>
          <TournamentchallengesModal
            show={viewPop}
            Tournament_id={props.item.id}
          />
          <ViewTournamentModal
            show={viewTeamDetailsPop}
            TournamentID={props.item.id}
            Tournamentname={props.item.name}
            logo={props.img}
            number_of_team_allowed={props.item.number_of_team_allowed}
            min_team_level={props.item.min_team_level}
            min_team_members_level={props.item.min_team_members_level}
          />
          <AssignTeamToTournamentModal
            show={viewassigntournement}
            tournamentID={props.item.id}
            onChange={handleChange}
          />
        </>
      ) : (
        ""
      )}
      <div className="bg_black p-2 card remove_index">
        <a>
          <div class="dropdown ">
            <button
              type="button"
              data-bs-toggle="dropdown"
              className="float-end btn">
              <span>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect width="32" height="32" rx="16" fill="#100E23" />
                  <path
                    d="M16 12C17.1 12 18 11.1 18 10C18 8.9 17.1 8 16 8C14.9 8 14 8.9 14 10C14 11.1 14.9 12 16 12ZM16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14ZM16 20C14.9 20 14 20.9 14 22C14 23.1 14.9 24 16 24C17.1 24 18 23.1 18 22C18 20.9 17.1 20 16 20Z"
                    fill="#EEE056"
                  />
                </svg>
              </span>
            </button>
            {props.isManager == true ? (
              <ul
                className={
                  cookies.lang === "ar" ? "dropdown-menu p_l" : "dropdown-menu"
                }>
                <li className="p-2">
                  <a
                    class="dropdown-item point"
                    onClick={() => {
                      setViewTeamDetailsPop(true);
                    }}>
                    {/* <svg className="m-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#100E23"/>
                          </svg> */}

                    {i18next.t("view_tournament_details")}
                  </a>
                </li>
                <li className="p-2">
                  <a class="dropdown-item point" onClick={clickviewchallenges}>
                    {/* <svg className="m-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#100E23"/>
                         </svg> */}

                    {i18next.t("view_challenges")}
                  </a>
                </li>

                <li className="p-2">
                  <a
                    class="dropdown-item point"
                    onClick={clickassigntournament}>
                    {/* <svg className="m-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#100E23"/>
                         </svg> */}

                    {i18next.t("Assign_tournament")}
                  </a>
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </a>
        <div class="tournament_item_top ">
          {/* <div class="tournament_item_top_icons tournament_item_top_orange">
            <span
              class="tournament_item_top_price"
              style={{ font_family: "Rogan!important", color: "#f5f5f5" }}>
              <a href="#" class="tournament_item_price_link"></a>
              <FontAwesomeIcon icon={faMedal} className="icon_1" />
              $0
            </span>
          </div> */}

          <div class="tournament_item_top_status ">
            <span
              class={
                props.item.status == "Open to join" ||
                props.item.status == "مفتوح للانضمام"
                  ? "tournament_status_green_bordered "
                  : "bg-danger "
              }>
              {/* <a href="#" class="tournament_item_price_link"></a> */}
              <FontAwesomeIcon icon={faCheckDouble} className="icon_1" />
              {props.item.status}
            </span>
          </div>
          <a class="tournament_item_top_img">
            <span
              class="tournament_item_top_bg"
              style={{ backgroundimage: "url(./imgs/teamBack.jpeg)" }}></span>
          </a>
        </div>
        <div class="tournament_item_btm_title featured_tournament_item_wrap p-2">
          <span class="tournament_item_title">
            <a
              class=" _tooltip"
              title={props.item.name}
              href=""
              style={{
                font_family: "Rogan!important",
                color: "#f5f5f5",
                padding_top: "5px",
              }}>
              {" "}
              {props.item.name}
            </a>
          </span>
          <div className="m-auto">
            <span title={i18next.t("joinedTeams")} class=" _tooltip">
              <LinearProgressWithLabel
                value={percent(
                  props.item.number_of_team_assigned | 0,
                  props.item.number_of_team_allowed | 0
                )}
              />
            </span>
            {/* <div class="tournament_item_progress"><span class="tournament_item_progress_colored" style={{right: '37.5%'}}></span></div>  */}
          </div>
          <p
            className={
              cookies.lang === "ar"
                ? "tournament_item_btm_info  text-end "
                : "tournament_item_btm_info text-start"
            }>
            <span
              title={props.item.challeng_name}
              class="tournament_item_start _tooltip">
              <FontAwesomeIcon icon={faGamepad} style={{ color: "#6b7996" }} />{" "}
              {i18next.t("challenge")}
              <span
                style={{
                  font_family: "Rogan!important",
                  color: "#f5f5f5",
                }}>
                {props.item.challeng_name}
              </span>
            </span>
          </p>
          <p
            className={
              cookies.lang === "ar"
                ? "tournament_item_btm_info text-end "
                : "tournament_item_btm_info text-start"
            }>
            <span
              title={props.item.tornament_date}
              class="tournament_item_start _tooltip">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ color: "#6b7996" }}
              />{" "}
              {i18next.t("date")}
              <span
                style={{
                  font_family: "Rogan!important",
                  color: "#f5f5f5",
                }}>
                {props.item.tornament_date}
              </span>
            </span>
          </p>
          {/* <div
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info  float-end rtl"
              : "tournament_item_btm_info text-start"
          }> */}
          <p
            className={
              cookies.lang === "ar"
                ? "tournament_item_btm_info  text-end"
                : "tournament_item_btm_info text-start"
            }>
            <span
              title={props.item.tournamnt_by}
              class="tournament_item_end _tooltip">
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ color: "#6b7996" }}
              />{" "}
              {i18next.t("tournament_by")}
              <span
                style={{
                  font_family: "Rogan!important",
                  color: "#f5f5f5",
                }}>
                {props.item.tournamnt_by}
              </span>
            </span>
          </p>
          <p
            className={
              cookies.lang === "ar"
                ? "tournament_item_btm_info  text-end"
                : "tournament_item_btm_info text-start"
            }>
            <a
              href={`https://www.google.com/maps/place/${props.item.latitude},${props.item.longitude}`}
              target="_blank"
              style={{ color: "#6b7996" }}>
              <span
                title={props.item.location_name}
                class="tournament_item_end _tooltip">
                <FontAwesomeIcon
                  icon={faMapMarked}
                  style={{ color: "#6b7996" }}
                />{" "}
                {i18next.t("location")}
                <span
                  style={{
                    font_family: "Rogan!important",
                    color: "#f5f5f5",
                  }}>
                  {props.item.location_name}
                </span>
              </span>
            </a>
          </p>
          <p
            className={
              cookies.lang === "ar"
                ? "tournament_item_btm_info  text-end"
                : "tournament_item_btm_info text-start"
            }>
            <a
              href={props.item.tornament_link}
              target="_blank"
              style={{ color: "#6b7996" }}>
              <span
                title={props.item.tornament_link}
                class="tournament_item_end _tooltip">
                <FontAwesomeIcon icon={faGlobe} style={{ color: "#6b7996" }} />{" "}
                {i18next.t("website")}
                <span
                  style={{
                    font_family: "Rogan!important",
                    color: "#f5f5f5",
                  }}>
                  {props.item.tornament_link}
                </span>
              </span>
            </a>
          </p>

          {/* </div> */}
          <div
            className={
              cookies.lang === "ar"
                ? "row text-end   p-1 "
                : "row p-1 text-start "
            }
            style={{ color: "#6b7996", fontWeight: "bold" }}>
            <span class="col-xs-12 col-sm-6 " style={{ fontSize: "12px" }}>
              <span
                style={{ font_family: "Rogan!important", color: "#f5f5f5" }}>
                {props.item.min_team_level}{" "}
              </span>
              {i18next.t("teamLevel")}
            </span>
            <span class="col-xs-12 col-sm-6 " style={{ fontSize: "12px" }}>
              <span
                style={{ font_family: "Rogan!important", color: "#f5f5f5" }}>
                {props.item.min_team_members_level}{" "}
              </span>
              {i18next.t("memberLevel")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
