import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import en from '../resources/en.json'
import TournamentchallengesModal from '../ESport/TournamentchallengesModal'
import config from "../config.json";
import ViewTournamentModal from '../ESport/ViewTournamentModal';
import { useDispatch, useSelector } from "react-redux";
import {SetTournamentID} from '../redux/Actions/AuthActions';
// import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';

export default function Game_small(props)  {
    // const navigate = useNavigate();
    let BaseUrl = config.BaseUrl;
    var hostName =window.location.origin;
    const dispatch = useDispatch();
    let DesktopCheckIcon = document.getElementsByClassName("check_child");
    const [Ischecked, setIschecked] = useState(false);
    // const [TournamentID, setTournamentIDs] =  useState([]);
    var TournamentID =new Array();
    // const [Ischecked, setIschecked] = useState(false);
    const [width, setwidth] = useState(window.innerWidth);
  function selectCard(index, Tournament_id) {

    // setTournamentID(TournamentID => [...TournamentID, Tournament_id]);
    
    let DesktopCheckIcon = document.getElementsByClassName("check_child");
    clearAllChildChcks(DesktopCheckIcon);
    if(TournamentID.indexOf(Tournament_id) > -1){
        var index = TournamentID.indexOf(Tournament_id);
        TournamentID.splice(index, 1);
        DesktopCheckIcon[props.index].classList.add("d-none");
    }
    else{
      DesktopCheckIcon[props.index].classList.remove("d-none");
      TournamentID.push(Tournament_id);
    }

    // dispatch(SetTournamentID(JSON.stringify(TournamentID)));
    // localStorage.setItem('TournamentID', JSON.stringify(TournamentID));
    props.onChange(TournamentID);
  }

  function clearAllChildChcks(
    DesktopCheckIcon
) {
  
if(DesktopCheckIcon && DesktopCheckIcon.length>0)
  {
    for (let i = 0; i <= DesktopCheckIcon.length - 1; i++) {
      DesktopCheckIcon[i].classList.add("d-none");
    }
  }
}


useEffect(() => {
  if (props.SelectedItem != -1 ) {
    let DesktopCheckIcon = document.getElementsByClassName("check_child");
    clearAllChildChcks(DesktopCheckIcon);
  }
  
}, [props.SelectedItem]);
return (
    <div className='col-md-3 game bg_black  mb-2 m-1 p-2 mr-2'
    style={{
      width: '150px',
      height: '200px',
      flex_shrink : -1,
      margin_right: '20px!important',
    }}>
    <div class="card">
    <img
      src="../assets/imgs/checked.png"
      className="check_child d-none"
                                    />
       <div class="card-header">
           <span >{props.name}</span>
       </div>
       <div class="card-body" >
           <img src={props.img} className={props.active==1 ?'img-fluid' :'img-fluid notactive'} style={{
      // width: '100px',
      height: '100px',
      borderRadius: 10,
      flex: 1,
      width: '100%',
      resizeMode: 'contain',
      flexDirection: 'row',


    }}
    onClick={() => selectCard(props.index, props.itemID)}/>
       </div>
    </div>
  </div>

  );
}