import * as React from "react";
import { Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
export default function ChildAssigenMissionDialog(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const [loading, setLoading] = useState(false);

  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  //  const id = props.id;
  const id = useSelector((state) => state.appReducer.id);

  // const username=props.username;
  const username = useSelector((state) => state.appReducer.username);

  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['lang']);
  const [missions, setmissions] = useState([]);
  let [show, setShow] = useState(props.show);
  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  useEffect(() => {
    if (token === "") {
      navigate("/veryalityApp");
    }
    setLoading(true);
    fetch(`${BaseUrl}ChildAssignedMissionsWIthReward/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getChildAssignedMissionsWIthReward_response) {
          setLoading(false);
          if (getChildAssignedMissionsWIthReward_response.data.length != 0) {
            setmissions(getChildAssignedMissionsWIthReward_response.data);
          }
        });
    });
  }, []);

  function selectmission(index, num1) {
    setShow(false);
    navigate("/childmissiondetails", { state: { num1: num1, index: index } });
  }

  const handleClose = () => {
    setShow(false);
    props.onChange("david");
  };

  const location = useLocation();

  return (
    <>
      <div className="modal modelround ">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle text-start p-3">
            <p class="margin_p"> {i18next.t('assignedMission')} </p>
            <small className="text-danger  text-start " id="editreward_alert">
            {i18next.t('note_m')} 
            </small>
            <br />
            <div>
              <div className=" kid-mission pt-3 pb-3">
                {loading == true ? (
                  <div className="w-100 mt-3 mb-2 m-auto">
                    <CircularProgress />
                  </div>
                ) : (
                  <div class="list-group mb-3 pt-2">
                    {missions.length != 0 ? (
                      missions.map((item, index) => (
                        <a
                          class="list-group-item  list-group-item-action mb-2 kid_mis"
                          onClick={() => selectmission(index, item)}>
                          <a className="float-end"></a>

                          <div className="row">
                            <span className="w-10">
                              <img
                                src="../assets/imgs/MicrosoftTeams-image.png"
                                className="icon_miss"
                              />
                            </span>
                            <span className="w-90">
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={<Tooltip>{item.name}</Tooltip>}>
                                <span className="tit">
                                  {item.name.length > 27
                                    ? item.name.substring(0, 27) + "..."
                                    : item.name}
                                </span>
                              </OverlayTrigger>
                              <br></br>
                              <OverlayTrigger
                                key="top"
                                placement="top"
                                overlay={
                                  <Tooltip>{item.rewards[0].name}</Tooltip>
                                }>
                                <span className="desc">
                                  {item.rewards[0].name.length > 27
                                    ? item.rewards[0].name.substring(0, 27) +
                                      "..."
                                    : item.rewards[0].name}
                                </span>
                              </OverlayTrigger>
                            </span>
                          </div>
                          <img
                            src="../assets/imgs/checked.png"
                            className="check_miss d-none"
                          />
                        </a>
                      ))
                    ) : (
                      <div className="pb-3 text-white text-center  pt-5 ">
                        {i18next.t('noMissions')}  <br />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className=" white-outline-btn1" onClick={handleClose}>
            {i18next.t("close")}
            </Button>
            <div
              id="finish_mission1"
              className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
