import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from "react";
import en from '../resources/en.json'
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';

export default function Nav()  {
    var hostName =window.location.origin;
const navigate=useNavigate();

return (
<section className="container-fluid pt-5">
            <div className="row">

                <div className="w-40 text-start">
<a  onClick={()=>navigate("/")}>
                    <img src='../assets/imgs/logo.png' className='nav-logo' width='166px' height='40px'></img>

</a>
                </div>



            </div>

        </section>

  );
}


