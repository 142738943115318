import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Usernav from "../layout/Usernav";
import Footer from "../layout/Footer";
import Kidreward from "../layout/Kidreward";
import en from "../resources/en.json";
import Assign from "./Parentcomponents/Assign";
import ParentAtviceMissions from "./Parentcomponents/ParentAtviceMissions";
import ParentCustomMissions from "./Parentcomponents/ParentCustomMissions";
import Missions from "./Parentcomponents/Missions";
import AlertDialog from "../layout/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
import { useTranslation } from "react-i18next";
import OurGames from './Parentcomponents/OurGames'

export default function Dparent() {
  const navigate = useNavigate();
  const location = useLocation();
  //const username=location.state.username;
  const username = useSelector((state) => state.appReducer.username);

  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  // const id=location.state.id;
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [needrefresh, setneedrefresh] = useState(false);
  const parentId = location?.state?.parentId;
  const challengeId = location.state.challengeId;
  const Modalcontent = i18next.t("child_account_successfully_created");
  const Modalcontentchallenge = en.dgamer.child_challeng_approved_successfully;
  let props = {
    show: show,
    Modalcontent: Modalcontent,
  };

  let propschallenge = {
    show: show2,
    Modalcontent: Modalcontentchallenge,
  };

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    if (parentId != null) {
      setshow(true);

      if (location.state != null && location.state.parentId != null) {
        navigate("/parent", { state: {} });
      }
    }
  }, [parentId]);
  useEffect(() => {
    if (challengeId != null) {
      setshow2(true);

      if (location.state != null && location.state.challengeId != null) {
        navigate("/parent", { state: {} });
      }
    }
  }, [challengeId]);

  const handleChange = React.useCallback((newValue) => {
    if (newValue == "needrefreshEditReward") {
      setneedrefresh(true);
    }
  });

  useEffect(() => {
    if (needrefresh == true) {
      setTimeout(() => {
        setneedrefresh(false);
      }, 1000);
    }
  }, [needrefresh]);
  return (
    <div>
      <Head />
      <AlertDialog {...props} />
      <AlertDialog {...propschallenge} />
      <Assign needrefresh={needrefresh} onChange={handleChange} />
      <ParentAtviceMissions needrefresh={needrefresh} onChange={handleChange} />
      <ParentCustomMissions needrefresh={needrefresh} onChange={handleChange} />
      <OurGames/>
      <Missions needrefresh={needrefresh} onChange={handleChange} />

      <Footer bg="bg_black" />
    </div>
  );
}
