import * as React from "react";
import { Component } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
export default function RevokAccountDialog(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const navigate = useNavigate();
  let [show, setShow] = useState(props.show);
  let [deletemission, setdeletemission] = useState("false");
  let [showbutton, setshowbutton] = useState(false);
  const [cookies, setCookie] = useCookies(['lang']);
  useEffect(() => {
    if (props.show == true) {
      setShow(props.show);
    }
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    props.onChange("david");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${BaseUrl}revokwearableAccess/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (connect_fitbitResp) {
        setLoading(false);
        if (connect_fitbitResp.status == true) {
          document.getElementById("finish_mission1").innerHTML =
          i18next.t('revoksuccessfully') ;
          document.getElementById("finish_mission1").classList.remove("d-none");
          document
            .getElementById("finish_mission1")
            .classList.add("alert-info");
          setshowbutton(true);
        } else {
          document.getElementById("finish_mission1").innerHTML =
          i18next.t('revokfaild');
          document.getElementById("finish_mission1").classList.remove("d-none");
          document
            .getElementById("finish_mission1")
            .classList.add("alert-danger");
        }
      });
    });
  };

  return (
    <>
      <div class="modelround" className="modal">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header class="modelstyle" closeButton>
            <Modal.Title class="modelstyle">
              {en.RewardDialog.veryality}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body class="modelstyle">
            <h5 className="text-start font p-3">{i18next.t('revokaccess')} </h5>

            <Modal.Footer>
              <Button
                type="submit"
                className="bg_blue w-40"
                variant="primary"
                //disabled={showbutton}
                onClick={handlesubmit}>
                {i18next.t('confirm')} 
              </Button>
              <Button className=" white-outline-btn1" onClick={handleClose}>
              {i18next.t("close")}
              </Button>
              {loading == true ? (
                <div className="w-100 mt-3 mb-2 m-auto">
                  <CircularProgress />
                </div>
              ) : (
                <div
                  id="finish_mission1"
                  className="w-100 mt-3 mb-2 m-auto d-none p-3"></div>
              )}
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
