import * as React from "react";
import { useState, useEffect } from "react";
import Game from "../../layout/Game";
import en from "../../resources/en.json";
import i18next from "../../i18n/config";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export default function OurGames() {

  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  const [width, setwidth] = useState(window.innerWidth);


  return (
      <div>




        {width > 950 ? (
            <div className="desktop">

              <section
                  className="container-fluid pdash bg_black  pt-4  m-0"
                  style={{ paddingLeft: 30, paddingRight: 30 }}
              >
                <div className="container-fluid bg-grey p-4">


                  {/* Nav pills */}
                  <ul
                      class="nav nav-pills d-flex justify-content-center"
                      role="tablist"
                      dir={cookies.lang=="ar"?"rtl":"ltr"}
                  >
                    <li class="nav-item  w-25">
                      <a
                          class="nav-link active"
                          data-bs-toggle="pill"
                          href="#games"
                      >
                        <span className="m-3">{i18next.t("mygame")}</span>
                      </a>
                    </li>
                    {/*<li class="nav-item  w-25 ">*/}
                    {/*  <a class="nav-link" data-bs-toggle="pill" href="#latest">*/}
                    {/*    <span className="m-3">{i18next.t("latest_games")}</span>*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                  </ul>

                  {/*Tab panes */}
                  <div class="tab-content">
                    <div
                        id="games"
                        className={
                          cookies.lang === "ar"
                              ? "container-fluid tab-pane active rtl"
                              : "container-fluid tab-pane active "
                        }
                    >
                      <br />
                      <div className="row m-auto pre-brows">
                        <Game
                            name="V-Racer"
                            active="1"
                            img="../assets/imgs/game.png"
                            isManager={false}
                            url="https://www.roblox.com/games/16743783458/Vracer-DEMO"
                        />
                        {/*<Game*/}
                        {/*  name="V-Racer"*/}
                        {/*  active="0"*/}
                        {/*  img="../assets/imgs/game.png"*/}
                        {/*  isManager={false}*/}
                        {/*/>*/}
                        {/*<Game*/}
                        {/*  name="V-Racer"*/}
                        {/*  active="1"*/}
                        {/*  img="../assets/imgs/game.png"*/}
                        {/*  isManager={false}*/}
                        {/*/>*/}
                        {/*<Game*/}
                        {/*  name="V-Racer"*/}
                        {/*  active="0"*/}
                        {/*  img="../assets/imgs/game.png"*/}
                        {/*  isManager={false}*/}
                        {/*/>*/}
                        {/*<Game*/}
                        {/*  name="V-Racer"*/}
                        {/*  active="1"*/}
                        {/*  img="../assets/imgs/game.png"*/}
                        {/*  isManager={false}*/}
                        {/*/>*/}
                        {/*<Game*/}
                        {/*  name="V-Racer"*/}
                        {/*  active="0"*/}
                        {/*  img="../assets/imgs/game.png"*/}
                        {/*  isManager={false}*/}
                        {/*/>*/}
                        <br/><br/><br/><br/>
                      </div>
                      <div className="brows mt-0">
                        {/*<a className="btn yellow-outline-btn w-40">*/}
                        {/*  <span className="p-3">*/}
                        {/*    {i18next.t("browse_all_veryality_games")}*/}
                        {/*  </span>*/}
                        {/*  <span>*/}
                        {/*    <svg*/}
                        {/*      width="12"*/}
                        {/*      height="11"*/}
                        {/*      viewBox="0 0 12 11"*/}
                        {/*      fill="none"*/}
                        {/*      xmlns="http://www.w3.org/2000/svg"*/}
                        {/*    >*/}
                        {/*      <path*/}
                        {/*        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"*/}
                        {/*        fill="#EECE52"*/}
                        {/*      />*/}
                        {/*    </svg>*/}
                        {/*  </span>*/}
                        {/*</a>*/}
                        <br/><br/><br/><br/>
                      </div>
                    </div>
                    <div
                        id="latest"
                        className={
                          cookies.lang === "ar"
                              ? "container-fluid tab-pane fade rtl"
                              : "container-fluid tab-pane fade "
                        }
                    >
                      <br />
                      <div className="row m-auto pre-brows">
                        <Game
                            name="V-Racer"
                            active="1"
                            img="../assets/imgs/game.png"
                            isManager={false}
                            url="https://www.roblox.com/games/16743783458/Vracer-DEMO"
                        />
                        {/*<Game*/}
                        {/*  name="V-Racer"*/}
                        {/*  active="0"*/}
                        {/*  img="../assets/imgs/game.png"*/}
                        {/*  isManager={false}*/}
                        {/*/>*/}
                        {/*<Game*/}
                        {/*    name="V-Racer"*/}
                        {/*    active="0"*/}
                        {/*    img="../assets/imgs/game.png"*/}
                        {/*    isManager={false}*/}
                        {/*/>*/}
                        {/*<Game*/}
                        {/*    name="V-Racer"*/}
                        {/*    active="0"*/}
                        {/*    img="../assets/imgs/game.png"*/}
                        {/*    isManager={false}*/}
                        {/*/>*/}
                      </div>
                      <div className="brows mt-0">
                        {/*<a className="btn yellow-outline-btn w-40">*/}
                        {/*  <span className="p-3">*/}
                        {/*    {i18next.t("browse_all_veryality_games")}*/}
                        {/*  </span>*/}
                        {/*  <span>*/}
                        {/*    <svg*/}
                        {/*      width="12"*/}
                        {/*      height="11"*/}
                        {/*      viewBox="0 0 12 11"*/}
                        {/*      fill="none"*/}
                        {/*      xmlns="http://www.w3.org/2000/svg"*/}
                        {/*    >*/}
                        {/*      <path*/}
                        {/*        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"*/}
                        {/*        fill="#EECE52"*/}
                        {/*      />*/}
                        {/*    </svg>*/}
                        {/*  </span>*/}
                        {/*</a>*/}
                        <br/><br/><br/><br/>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
        ) : (
            <div className="mobile">


              <section
                  className="container-fluid pdash bg_black pt-4 m-0"
                  style={{ paddingLeft: 30, paddingRight: 35 }}
              >
                <div className="container-fluid bg-grey p-4">

                  <form className=" form-dark w-100 mb-3">
                    <select name="" className="form-select "  dir={cookies.lang=="ar"?"rtl":"ltr"}>
                      <option value="44"> {i18next.t("mygame")}</option>
                      {/*<option value="1"> {i18next.t("latest_games")}</option>*/}
                    </select>
                  </form>
                  <div id="games" class="container-fluid p-0 ">
                    <div className="row m-auto  pre-brows ">
                      <Game
                          name="V-Racer"
                          active="1"
                          img="../assets/imgs/game.png"
                          isManager={false}
                          url="https://www.roblox.com/games/16743783458/Vracer-DEMO"
                      />
                      {/*<Game*/}
                      {/*  name="V-Racer"*/}
                      {/*  active="0"*/}
                      {/*  img="../assets/imgs/game.png"*/}
                      {/*  isManager={false}*/}
                      {/*/>*/}
                      {/*<Game*/}
                      {/*  name="V-Racer"*/}
                      {/*  active="1"*/}
                      {/*  img="../assets/imgs/game.png"*/}
                      {/*  isManager={false}*/}
                      {/*/>*/}
                      {/*<Game*/}
                      {/*  name="V-Racer"*/}
                      {/*  active="0"*/}
                      {/*  img="../assets/imgs/game.png"*/}
                      {/*  isManager={false}*/}
                      {/*/>*/}
                      {/*<Game*/}
                      {/*  name="V-Racer"*/}
                      {/*  active="1"*/}
                      {/*  img="../assets/imgs/game.png"*/}
                      {/*  isManager={false}*/}
                      {/*/>*/}
                      {/*<Game*/}
                      {/*  name="V-Racer"*/}
                      {/*  active="0"*/}
                      {/*  img="../assets/imgs/game.png"*/}
                      {/*  isManager={false}*/}
                      {/*/>*/}
                    </div>
                    <div className="brows mt-0">
                      {/*<a className="btn yellow-outline-btn font-13 w-100">*/}
                      {/*  <span className="p-1">*/}
                      {/*    {i18next.t("browse_all_veryality_games")}*/}
                      {/*  </span>*/}
                      {/*  <span>*/}
                      {/*    <svg*/}
                      {/*      width="12"*/}
                      {/*      height="11"*/}
                      {/*      viewBox="0 0 12 11"*/}
                      {/*      fill="none"*/}
                      {/*      xmlns="http://www.w3.org/2000/svg"*/}
                      {/*    >*/}
                      {/*      <path*/}
                      {/*        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"*/}
                      {/*        fill="#EECE52"*/}
                      {/*      />*/}
                      {/*    </svg>*/}
                      {/*  </span>*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              </section>
            </div>
        )}


      </div>
  );
}
