import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Usernav from "../../layout/Usernav";
import Kidreward from "../../layout/Kidreward";
import MissionDialog from "../../layout/MissionDialog";
import AlertDialog from "../../layout/AlertDialog";
import ReactLoading from "react-loading";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import en from "../../resources/en.json";
import EditMissionDialog from "../../layout/EditMissionDialog";
import EditRewardDialog from "../../layout/EditRewardDialog";
import DeleteMissionDialog from "../../layout/DeleteMissionDialog";
import { env } from "process";
import config from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18next from "../../i18n/config";
import { Button } from 'antd';


export default function Assign(props) {
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [isLoading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["lang"]);

  const username = useSelector((state) => state.appReducer.username);
  // const id = props.id;
  const navigate = useNavigate();
  let [pop, setpop] = useState(false);
  let [assignpop, setassignpop] = useState(false);
  let [editmissionpop, seteditmissionpop] = useState(false);
  let [editrewardpop, seteditrewardpop] = useState(false);
  let [deletemissionpop, setdeletemissionpop] = useState(false);
  let [childID, setchildID] = useState(0);

  let [editmissionitem, seteditmissionitem] = useState({
    id: "",
    name: "",
    description: "",
    startDate: "",
    endDate: "",

    ChildIDs: [],
  });
  let [deletemissionitem, setdeletemissionitem] = useState({
    //reward_id: "",
    Mission_id: "",
    RemoveWithRewad: false,
  });
  const [showHintPopUp, setshowHintPopUp] = useState(false);
  const Modalcontentissystem = i18next.t("can_not_assign_mission_by_veryality");
  const [childs, setchilds] = useState([]);
  const [appchilds, setappchilds] = useState([]);
  const [watchchilds, setwatchchilds] = useState([]);

  const [missions, setmissions] = useState([]);
  const [challenges, setchallenges] = useState([]);
  const [popmsg, setpopmsg] = useState("");
  const [Ischecked, setIschecked] = useState(false);
  const [CompletedMissions, setCompletedMissions] = useState([]);
  const [ActiveMission, setActiveMission] = useState([]);
  const [assignmiss, setassignmiss] = useState({
    missionID: 0,
    childID: 0,
    PrentID: id,
  });

  const handleChange = React.useCallback((newValue) => {
    fetch(`${BaseUrl}ParentMissionsWIthReward/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getParentMissionsWIthReward_response) {
        if (getParentMissionsWIthReward_response.data?.length != 0) {
          setmissions(getParentMissionsWIthReward_response.data);
        }
        setLoading(false);
      });
    });

    fetch(
      `${BaseUrl}GetChildAssignedMissionsActiveAndCompletedWIthReward/${childID}`,
      {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }
    ).then(function (response) {
      response
        .json()
        .then(function (
          getChildAssignedMissionsActiveAndCompletedWIthReward_response
        ) {
          if (
            getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
              .CompletedMissions.length != 0
          ) {
            setCompletedMissions(
              getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
                .CompletedMissions
            );
          } else {
            setCompletedMissions([]);
          }
          if (
            getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
              .ActiveMission.length != 0
          ) {
            setActiveMission(
              getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
                .ActiveMission
            );
          } else {
            setActiveMission([]);
          }
          // setLoading(false);
        });
    });

    props.onChange("needrefreshEditReward");
  }, []);

  useEffect(() => {
    if (props.needrefresh == true) {
      fetch(
        `${BaseUrl}GetChildAssignedMissionsActiveAndCompletedWIthReward/${childID}`,
        {
          method: "get",
          body: JSON.stringify(),
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept-Language": cookies.lang,
          },
        }
      ).then(function (response) {
        response
          .json()
          .then(function (
            getChildAssignedMissionsActiveAndCompletedWIthReward_response
          ) {
            if (
              getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
                .CompletedMissions.length != 0
            ) {
              setCompletedMissions(
                getChildAssignedMissionsActiveAndCompletedWIthReward_response
                  .data.CompletedMissions
              );
            } else {
              setCompletedMissions([]);
            }
            if (
              getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
                .ActiveMission.length != 0
            ) {
              setActiveMission(
                getChildAssignedMissionsActiveAndCompletedWIthReward_response
                  .data.ActiveMission
              );
            } else {
              setActiveMission([]);
            }
            // setLoading(false);
          });
      });
    }
  }, [props.needrefresh]);

  useEffect(() => {
    fetch(`${BaseUrl}getChildesChallengedNeedApprove/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getChildesChallengedNeedApprove_response) {
        if (getChildesChallengedNeedApprove_response.data?.length != 0) {
          setchallenges(getChildesChallengedNeedApprove_response.data);
        }
        setLoading(false);
      });
    });
    if (assignpop == true) {
      setTimeout(() => {
        setassignpop(false);
      }, 1000);
    }
  }, [assignpop]);

  useEffect(() => {
    if (pop == true) {
      setTimeout(() => {
        setpop(false);
      }, 1000);
    }
  }, [pop]);
  useEffect(() => {
    if (editmissionpop == true) {
      setTimeout(() => {
        seteditmissionpop(false);
      }, 1000);
    }
  }, [editmissionpop]);

  useEffect(() => {
    if (editrewardpop == true) {
      setTimeout(() => {
        seteditrewardpop(false);
      }, 1000);
    }
  }, [editrewardpop]);

  useEffect(() => {
    if (deletemissionpop == true) {
      setTimeout(() => {
        setdeletemissionpop(false);
      }, 1000);
    }
  }, [deletemissionpop]);

  useEffect(() => {
    if (showHintPopUp == true) {
      setshowHintPopUp(false);
    }
  }, [showHintPopUp]);
  function clearAllChildChcks(
    DesktopCheckIcon,
    ApplicationCheckIcon,
    watchCheckIcon
  ) {
    if (DesktopCheckIcon && DesktopCheckIcon.length > 0) {
      for (let i = 0; i <= childs.length - 1; i++) {
        DesktopCheckIcon[i].classList.add("d-none");
      }
    }
    if (ApplicationCheckIcon && ApplicationCheckIcon.length > 0) {
      for (let i = 0; i <= appchilds.length - 1; i++) {
        ApplicationCheckIcon[i].classList.add("d-none");
      }
    }

    if (watchCheckIcon && watchCheckIcon.length > 0) {
      for (let i = 0; i <= watchchilds.length - 1; i++) {
        watchCheckIcon[i].classList.add("d-none");
      }
    }
  }
  function selectchild(index, num, type) {

    setchildID(num);
    let DesktopCheckIcon = document.getElementsByClassName("check_child");
    let watchCheckIcon = document.getElementsByClassName("check_child");
    let ApplicationCheckIcon = document.getElementsByClassName("check_child2");
    // setassignmiss({...assignmiss,childID:num});
    clearAllChildChcks(DesktopCheckIcon, ApplicationCheckIcon, watchCheckIcon);
    if (type == "all") {
      if (DesktopCheckIcon[index]?.classList.contains("d-none")) {
        setIschecked(true);
        DesktopCheckIcon[index].classList.remove("d-none");
      } else {
        setIschecked(false);
        DesktopCheckIcon[index].classList.add("d-none");
        setCompletedMissions([]);
        setActiveMission([]);
      }
    }

    if (type == "app") {
      if (watchCheckIcon[index].classList.contains("d-none")) {
        setIschecked(true);
        watchCheckIcon[index].classList.remove("d-none");
      } else {
        setIschecked(false);
        watchCheckIcon[index].classList.add("d-none");
        setCompletedMissions([]);
        setActiveMission([]);
      }
    }
    if (type == "watch") {
      if (watchCheckIcon[index].classList.contains("d-none")) {
        setIschecked(true);
        watchCheckIcon[index].classList.remove("d-none");
      } else {
        setIschecked(false);
        watchCheckIcon[index].classList.add("d-none");
        setCompletedMissions([]);
        setActiveMission([]);
      }
    }

    fetch(
      `${BaseUrl}GetChildAssignedMissionsActiveAndCompletedWIthReward/${num}`,
      {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }
    ).then(function (response) {
      response
        .json()
        .then(function (
          getChildAssignedMissionsActiveAndCompletedWIthReward_response
        ) {
          if (
            getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
              .CompletedMissions.length != 0
          ) {
            setCompletedMissions(
              getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
                .CompletedMissions
            );
          } else {
            setCompletedMissions([]);
          }
          if (
            getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
              .ActiveMission.length != 0
          ) {
            setActiveMission(
              getChildAssignedMissionsActiveAndCompletedWIthReward_response.data
                .ActiveMission
            );
          } else {
            setActiveMission([]);
          }
        });
    });
  }

  let propsissystem = {
    show: showHintPopUp,
    Modalcontent: Modalcontentissystem,
  };
  function selectmission(index, num1, item) {
    if (item.isSystemMission == 1) {
      setshowHintPopUp(true);
    } else {
      let mission = document.getElementsByClassName("check_miss");
      let mission1 = document.getElementsByClassName("check_miss1");
      setassignmiss({ ...assignmiss, missionID: num1 });
      for (let i = 0; i <= missions.length - 1; i++) {
        mission[i].classList.add("d-none");
        mission1[i].classList.add("d-none");
      }
      mission[index].classList.remove("d-none");
      mission1[index].classList.remove("d-none");
    }
  }
  function selectchallenge(index, num1) {
    navigate("/childchallengedetails", {
      state: { num1: num1, index: index },
    });
  }

  function assignChildMission() {
    if (assignmiss.childID > 1 && assignmiss.missionID > 0) {
      fetch(`${BaseUrl}AssignMissionToChild`, {
        method: "post",
        body: JSON.stringify(assignmiss),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (respp4) {
          setassignpop(true);
          setpopmsg(respp4.msg);
        });
      });
    } else {
      setassignpop(true);
      setpopmsg(i18next.t("select_mission"));
    }
  }
  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetch(`${BaseUrl}ParentChildsGet/${id}/all`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (respp) {
        if (respp.data?.length != 0) {
          setchilds(respp.data);
        }
      });
    });
    fetch(`${BaseUrl}ParentChildsGet/${id}/watch`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response1) {
      response1.json().then(function (respp1) {
        if (respp1.data?.length != 0) {
          setwatchchilds(respp1.data);
        }
      });
    });
    fetch(`${BaseUrl}ParentChildsGet/${id}/application`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response2) {
      response2.json().then(function (respp2) {
        if (respp2.data?.length != 0) {
          setappchilds(respp2.data);
        }
      });
    });
  }, []);

  function goTosignNewChild() {
    navigate("/signchild", { state: { username: username } });
  }

  if (isLoading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }
  return (
    <div>
      <AlertDialog show={assignpop} Modalcontent={popmsg} />
      <AlertDialog {...propsissystem} />
      <MissionDialog show={pop} username={username} onChange={handleChange} />
      {/* <EditMissionDialog
            show={editmissionpop}
            item={editmissionitem}
            onChange={handleChange}
        />
        <EditRewardDialog show={editrewardpop}  onChange={handleChange} />
        <DeleteMissionDialog
            show={deletemissionpop}
            item={deletemissionitem}
            onChange={handleChange}
        /> */}
      {width > 950 ? (
        <div className="desktop">
          <section className=" container-fluid p-4 bg_black">
            <Usernav username={username} onChange={handleChange} />
            <div className="container-fluid mt-3  bg-grey p-3 mb-3">
              <div className="pb-1">
                <a>
                  <span
                    className={
                      cookies.lang === "ar"
                        ? "ltr float-end text-white"
                        : " float-start text-white"
                    }
                  >
                    {i18next.t("viewMission")}
                  </span>
                </a>
                <a className="float-end yellow"></a>
              </div>
              <br></br>
              <br></br>
              <div className="kid-manage p-4">
                <div className="pb-3">
                  <a>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>{username}</Tooltip>}
                    >
                      <span
                        className={
                          cookies.lang === "ar"
                            ? "ltr float-end yellow kid"
                            : " float-start yellow kid"
                        }
                      >
                        {username.length > 27
                          ? username.substring(0, 27) + "..."
                          : username}
                        {i18next.t("childs")}
                      </span>
                    </OverlayTrigger>
                  </a>
                  <a
                    className={
                      cookies.lang === "ar"
                        ? " float-start yellow "
                        : " float-end yellow "
                    }
                  >
                    <div class="dropdown">
                      <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="32"
                              height="32"
                              rx="16"
                              fill="#100E23"
                            />
                            <path
                              d="M16 12C17.1 12 18 11.1 18 10C18 8.9 17.1 8 16 8C14.9 8 14 8.9 14 10C14 11.1 14.9 12 16 12ZM16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14ZM16 20C14.9 20 14 20.9 14 22C14 23.1 14.9 24 16 24C17.1 24 18 23.1 18 22C18 20.9 17.1 20 16 20Z"
                              fill="#EEE056"
                            />
                          </svg>
                        </span>
                      </button>
                      <ul class="dropdown-menu ">
                        <li
                          className={
                            cookies.lang === "ar"
                              ? " p-2 rtl text-end "
                              : " p-2 "
                          }
                          onClick={() => setpop(true)}
                        >
                          <svg
                            className="m-1"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                              fill="#100E23"
                            />
                          </svg>
                          {i18next.t("newAssignment")}
                        </li>
                      </ul>
                    </div>
                  </a>
                  <a
                    href="#kid_assign"
                    data-bs-toggle="collapse"
                    className={
                      cookies.lang === "ar"
                        ? " float-start yellow mt-2"
                        : " float-end yellow mt-2"
                    }
                  >
                    <span>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="32" height="32" rx="16" fill="#100E23" />
                        <path
                          d="M11.41 19.41L16 14.83L20.59 19.41L22 18L16 12L10 18L11.41 19.41Z"
                          fill="#EEE056"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <br></br>
                <br></br>
                <div className="row " id="kid_assign">
                  <div className="col-md-7 kid-char pb-0">
                    <div className="row childs_row">
                      {childs?.length != 0 || appchilds?.length != 0 ? (
                        childs.length != 0 ? (
                          <>
                            <div className="col-md-3 mt-0 pt-3 pb-3 text-center child_one ">
                              {/* <br/><br/><br/> */}
                              <a
                                class="text-primary "
                                onClick={goTosignNewChild}
                              >
                                <svg
                                  className="mt-2 mb-4"
                                  width="90"
                                  height="85"
                                  viewBox="0 0 316 322"
                                  fill="#100E23"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                    stroke="#100E23"
                                    stroke-width="0.590604"
                                  />
                                  <g clip-path="url(#clip0_950_3103)">
                                    <path
                                      d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_950_3103">
                                      <rect
                                        width="102.778"
                                        height="100"
                                        fill="white"
                                        transform="translate(107 111)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="font_20 glow-on-hover " style={{color:"white"}}>
                                  {i18next.t("createChild")}
                                </p>
                              </a>
                            </div>
                            {childs.map((item, index) => (
                              <div className="col-md-3  text-center child_one">
                                {item.is_assafeer_user?(
                                        <div>

                                          <img
                                              src="../assets/imgs/asafeer.png"
                                              className="img-fluid mb-0 mt-0 pb-0 kid-av"
                                              onClick={() =>
                                                  selectchild(index, item.id, "all")
                                              }
                                          />
                                          <p
                                              style={{ color: "#100E23", fontSize: "12px" }}
                                              className={
                                                cookies.lang === "ar"
                                                    ? "pb-0 rtl"
                                                    : "pb-0"
                                              }
                                          >
                                            {i18next.t("total_stars")} :{item.asafeer?.total_stars}
                                          </p>
                                        </div>

                                ):(
                                    <img
                                        src="../assets/imgs/kidwatch.png"
                                        className="img-fluid mb-0 mt-0 pb-0 kid-av"
                                        onClick={() =>
                                            selectchild(index, item.id, "all")
                                        }
                                    />
                                )}

                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <p className="letters_limit">
                                    {item.name.length > 27
                                      ? item.name.substring(0, 27) + "..."
                                      : item.name}
                                  </p>
                                </OverlayTrigger>
                                <p className="pb-2">
                                  Lvl (
                                  {item.active_level[0]?.levelNumber
                                    ? item.active_level[0]?.levelNumber
                                    : 0}
                                  )
                                </p>
                                <p
                                  style={{ color: "#100E23", fontSize: "12px" }}
                                  className={
                                    cookies.lang === "ar"
                                      ? "pb-0 rtl"
                                      : "pb-0"
                                  }
                                >
                                   {i18next.t("clientId")}{item.clientid}
                                </p>
                                <p
                                    style={{ color: "#100E23", fontSize: "12px" }}
                                    className={
                                      cookies.lang === "ar"
                                          ? "pb-0 rtl"
                                          : "pb-0"
                                    }
                                >
                                  {i18next.t("v_name")} :{item.email}
                                </p>
                                <img
                                  src="../assets/imgs/checked.png"
                                  className="check_child d-none"
                                />
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="w-50 empty_kids text-center container ">
                            <a class="text-primary " onClick={goTosignNewChild}>
                              <svg
                                className="mt-0  mb-4"
                                width="130"
                                height="130"
                                viewBox="0 0 316 322"
                                fill="#100E23"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                  stroke="#100E23"
                                  stroke-width="0.590604"
                                />
                                <g clip-path="url(#clip0_950_3103)">
                                  <path
                                    d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_950_3103">
                                    <rect
                                      width="102.778"
                                      height="100"
                                      fill="white"
                                      transform="translate(107 111)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p className="font_20 glow-on-hover ">
                                {i18next.t("createChild")}
                              </p>
                            </a>
                          </div>
                        )
                      ) : (
                        <div className="w-50 empty_kids text-center container  ">
                          <a class="text-primary " onClick={goTosignNewChild}>
                            <svg
                              className="mt-0 mb-4"
                              width="130"
                              height="130"
                              viewBox="0 0 316 322"
                              fill="#100E23"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                stroke="#100E23"
                                stroke-width="0.590604"
                              />
                              <g clip-path="url(#clip0_950_3103)">
                                <path
                                  d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_950_3103">
                                  <rect
                                    width="102.778"
                                    height="100"
                                    fill="white"
                                    transform="translate(107 111)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <p className="font_20 glow-on-hover">
                              {i18next.t("createChild")}
                            </p>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1 pt-3 pb-3 vector">
                    <svg
                      width="40"
                      height="29"
                      viewBox="0 0 40 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26 0.5L23.18 3.32L32.34 12.5H0V16.5H32.34L23.16 25.68L26 28.5L40 14.5L26 0.5Z"
                        fill="#EECE52"
                      />
                    </svg>
                  </div>
                  <div className="col-md-4 kid-mission pt-3 pb-3">
                    <p className="pt-4"> {i18next.t("childMission")}</p>

                    <div className=" pdash ">
                      <ul class="nav nav-pills p-3">
                        <li class="nav-item w-50">
                          <a
                            class="nav-link active "
                            data-bs-toggle="pill"
                            href="#complete_miss1"
                          >
                            {i18next.t("completed")}
                          </a>
                        </li>
                        <li class="nav-item w-50">
                          <a
                            class="nav-link "
                            data-bs-toggle="pill"
                            href="#active_miss1"
                          >
                            {i18next.t("active")}
                          </a>
                        </li>
                      </ul>

                      {/* Tab panes */}
                      <div class="tab-content ">
                        <div
                          class="tab-pane container active"
                          id="complete_miss1"
                        >
                          {/* <p  className='pt-4'>Completed mission(s)</p> */}
                          <div class="list-group mb-0 pt-1">
                            {Ischecked == true ? (
                              CompletedMissions.length != 0 ? (
                                CompletedMissions.map((item, index) => (
                                  <a class="list-group-item  list-group-item-action mb-2 kid_mis">
                                    {/* <a className='float-end'> */}

                                    {/* </a> */}

                                    <div className="row">
                                      <span className="w-10">
                                        <img
                                          src={item.icon}
                                          style={{ width: 25, height: 30 }}
                                          alt={item.icon}
                                          className="pt-1 "
                                        />
                                      </span>
                                      <span className="w-80">
                                        <div>
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip>{item.name}</Tooltip>
                                            }
                                          >
                                            <span className="tit letters_limit">
                                              {item.name.length > 27
                                                ? item.name.substring(0, 27) +
                                                  "..."
                                                : item.name}
                                            </span>
                                          </OverlayTrigger>
                                          <br></br>
                                        </div>
                                      </span>
                                    </div>
                                  </a>
                                ))
                              ) : (
                                <div className="pb-3 text-white text-center  pt-5 ">
                                  {i18next.t("noCompletedMission")}
                                </div>
                              )
                            ) : (
                              <div className="pb-3 text-white text-center  pt-5 ">
                                {i18next.t("selectChild")}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="tab-pane container " id="active_miss1">
                          <div class="list-group mb-0 pt-1">
                            {Ischecked == true ? (
                              ActiveMission.length != 0 ? (
                                ActiveMission.map((item, index) => (
                                  <a class="list-group-item  list-group-item-action mb-2 kid_mis">
                                    {/* <a className='float-end'> */}

                                    {/* </a> */}

                                    <div className="row">
                                      <span className="w-10">
                                        <img
                                          src={item.icon}
                                          style={{ width: 25, height: 30 }}
                                          alt={item.icon}
                                          className="pt-1 pb-0"
                                        />
                                      </span>
                                      <span className="w-80">
                                        <div>
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip>{item.name}</Tooltip>
                                            }
                                          >
                                            <span className="tit limit_word letters_limit">
                                              {item.name.length > 27
                                                ? item.name.substring(0, 27) +
                                                  "..."
                                                : item.name}
                                            </span>
                                          </OverlayTrigger>
                                          <br></br>
                                        </div>
                                      </span>
                                      {item.isSystemMission != 1 && (
                                        <div class="dropdown  w-10 float-end "></div>
                                      )}
                                    </div>
                                  </a>
                                ))
                              ) : (
                                <div className="pb-3 text-white text-center  pt-5 ">
                                  {i18next.t("noAssignMission")}
                                </div>
                              )
                            ) : (
                              <div className="pb-3 text-white text-center  pt-5 ">
                                {i18next.t("selectChild")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="mobile">
          <section className=" container-fluid p-4 bg_black">
            <Usernav username={username} />
            <div className="container-fluid mt-3  bg-grey p-4 ">
              <div className="pb-3">
                <a>
                  <span className="float-start text-white">
                    {i18next.t("viewMission")}
                  </span>
                </a>
                <a className="float-end yellow">
                  <span></span>
                </a>
              </div>
              <br></br>
              <br></br>
              <div className="kid-manage p-3">
                <div className="pb-3">
                  <a>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>{username}</Tooltip>}
                    >
                      <span
                        className="float-start yellow kid break letters_limit"
                        style={{}}
                      >
                        {username.length > 27
                          ? username.substring(0, 27) + "..."
                          : username}
                        {i18next.t("childs")}
                      </span>
                    </OverlayTrigger>
                  </a>
                  <a className="float-end yellow">
                    <div class="dropdown">
                      <button
                        type="button"
                        class="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="32"
                              height="32"
                              rx="16"
                              fill="#100E23"
                            />
                            <path
                              d="M16 12C17.1 12 18 11.1 18 10C18 8.9 17.1 8 16 8C14.9 8 14 8.9 14 10C14 11.1 14.9 12 16 12ZM16 14C14.9 14 14 14.9 14 16C14 17.1 14.9 18 16 18C17.1 18 18 17.1 18 16C18 14.9 17.1 14 16 14ZM16 20C14.9 20 14 20.9 14 22C14 23.1 14.9 24 16 24C17.1 24 18 23.1 18 22C18 20.9 17.1 20 16 20Z"
                              fill="#EEE056"
                            />
                          </svg>
                        </span>
                      </button>
                      <ul class="dropdown-menu ">
                        <li className={
                            cookies.lang === "ar"
                              ? " p-2 rtl text-end "
                              : " p-2 "
                          }  onClick={() => setpop(true)}>
                          <svg
                            className="m-1"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                              fill="#100E23"
                            />
                          </svg>
                          {i18next.t("newAssignment")}
                        </li>
                      </ul>
                    </div>
                  </a>
                  <a
                    href="#kid_assign1"
                    data-bs-toggle="collapse"
                    className="float-end yellow mt-2"
                  >
                    <span>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="32" height="32" rx="16" fill="#100E23" />
                        <path
                          d="M11.41 19.41L16 14.83L20.59 19.41L22 18L16 12L10 18L11.41 19.41Z"
                          fill="#EEE056"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <br></br>
                <br></br>
                <div id="kid_assign1">
                  <div className="kid-char pdash ">
                    {/*<ul class="nav nav-pills ">*/}
                    {/*  <li class="nav-item">*/}
                    {/*    <a*/}
                    {/*      class="nav-link active"*/}
                    {/*      data-bs-toggle="pill"*/}
                    {/*      href="#kid-avatar"*/}
                    {/*    >*/}
                    {/*      {i18next.t("children")}*/}
                    {/*    </a>*/}
                    {/*  </li>*/}
                    {/*  /!*<li class="nav-item">*!/*/}
                    {/*  /!*  <a*!/*/}
                    {/*  /!*    class="nav-link"*!/*/}
                    {/*  /!*    data-bs-toggle="pill"*!/*/}
                    {/*  /!*    href="#kid-device"*!/*/}
                    {/*  /!*  >*!/*/}
                    {/*  /!*    {i18next.t("application")}*!/*/}
                    {/*  /!*  </a>*!/*/}
                    {/*  /!*</li>*!/*/}
                    {/*</ul>*/}
                    <div className="w-100   child_one glow-on-hover" dir={cookies.lang=="ar"?"rtl":"ltr"} >
                      <a className=" " onClick={goTosignNewChild}>
                        <svg
                            style={cookies.lang=="en"?{
                              marginRight:"-15px"
                            }:{ marginLeft:"-15px"}}
                            className="  svg_fill"
                            width="50"
                            height="50"
                            viewBox="0 0 316 322"
                            fill="#100E23"
                            xmlns="http://www.w3.org/2000/svg"
                        >


                          <path
                              d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                              fill="white"
                          />


                        </svg>


                        <span>  {i18next.t("createChild")}</span>


                      </a>
                    </div>
                    {/* Tab panes */}
                    <div class="tab-content ">
                      <div
                        class="tab-pane yellow_back container active mt-3 pt-2 radius_4"
                        id="kid-avatar"
                      >

                        <div className="childs_row row text-center">

                            <>
                              {childs.map((item, index) => (
                                <div className=" ">
                                  <div className="w-100  text-center child_one  ">
                                    {item.is_assafeer_user?(
                                            <div>
                                              <img
                                                  src="../assets/imgs/asafeer.png"
                                                  className="img-fluid mb-0 mt-0 pb-0 kid-av"
                                                  onClick={() =>
                                                      selectchild(index, item.id, "all")
                                                  }

                                              />

                                            </div>


                                    ):(
                                        <img
                                            src="../assets/imgs/kidwatch.png"
                                            className="img-fluid mb-0 mt-0 pb-0 kid-av"
                                            onClick={() =>
                                                selectchild(index, item.id, "all")
                                            }
                                        />
                                    )}
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={<Tooltip>{item.asafeer?.total_stars}</Tooltip>}
                                    >
                                      <p
                                          style={{ color: "#100E23" }}
                                          className="letters_limit "
                                      >
                                        {item.asafeer?.total_stars}
                                      </p>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={<Tooltip>{item.name}</Tooltip>}
                                    >
                                      <p
                                        style={{ color: "#100E23" }}
                                        className="letters_limit "
                                      >
                                        {item.name.length > 27
                                          ? item.name.substring(0, 27) + "..."
                                          : item.name}
                                      </p>
                                    </OverlayTrigger>
                                    <p
                                      style={{ color: "#100E23" }}
                                      className="pb-0 "
                                    >
                                      Lvl (
                                      {item.active_level[0]?.levelNumber
                                        ? item.active_level[0]?.levelNumber
                                        : 0}
                                      )
                                    </p>
                                    <p
                                      style={{
                                        color: "#100E23",
                                        fontSize: "12px",
                                      }}
                                      className={
                                        cookies.lang === "ar"
                                          ? "pb-0 rtl"
                                          : "pb-0"
                                      }
                                    >
                                      {i18next.t("clientId")} {item.clientid}
                                    </p>
                                    <p
                                        style={{ color: "#100E23", fontSize: "12px" }}
                                        className={
                                          cookies.lang === "ar"
                                              ? "pb-0 rtl"
                                              : "pb-0"
                                        }
                                    >
                                      {i18next.t("Email")} :{item.email}
                                    </p>
                                    <img
                                      src="../assets/imgs/checked.png"
                                      className="check_child d-none"
                                    />
                                  </div>
                                </div>
                              ))}
                            </>

                        </div>
                      </div>
                      <div
                        class="tab-pane container yellow_back mt-3 fade radius_4"
                        id="kid-device"
                      >
                        {appchilds.length != 0 ? (
                          <div className="w-100  text-start child_one add_icon">
                            <a
                              class="text-primary "
                              href=""
                              onClick={goTosignNewChild}
                            >
                              <svg
                                className="m-1 pt-0"
                                width="60"
                                height="60"
                                viewBox="0 0 316 322"
                                fill="#100E23"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"
                                  stroke="#100E23"
                                  stroke-width="0.590604"
                                />
                                <g clip-path="url(#clip0_950_3103)">
                                  <path
                                    d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_950_3103">
                                    <rect
                                      width="102.778"
                                      height="100"
                                      fill="white"
                                      transform="translate(107 111)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <p
                                className="font_12 "
                                style={{ color: "#100E23" }}
                              >
                                {i18next.t("createChild")}
                              </p>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        {/*<div className="childs_row  row text-center">*/}
                        {/*  {appchilds.length != 0 ? (*/}
                        {/*    <>*/}
                        {/*      {appchilds.map((item, index) => (*/}
                        {/*        <div className=" ">*/}
                        {/*          <div className="w-100  text-center child_one mt-3">*/}
                        {/*            <img*/}
                        {/*              src="../assets/imgs/fitapp.png"*/}
                        {/*              className="img-fluid mb-1 mt-4 kid-av"*/}
                        {/*              onClick={() =>*/}
                        {/*                selectchild(index, item.id, "app")*/}
                        {/*              }*/}
                        {/*            />*/}
                        {/*            <OverlayTrigger*/}
                        {/*              key="top"*/}
                        {/*              placement="top"*/}
                        {/*              overlay={<Tooltip>{item.name}</Tooltip>}*/}
                        {/*            >*/}
                        {/*              <p*/}
                        {/*                style={{ color: "#100E23" }}*/}
                        {/*                className="letters_limit "*/}
                        {/*              >*/}
                        {/*                {item.name.length > 27*/}
                        {/*                  ? item.name.substring(0, 27) + "..."*/}
                        {/*                  : item.name}*/}
                        {/*              </p>*/}
                        {/*            </OverlayTrigger>*/}
                        {/*            <p*/}
                        {/*              style={{ color: "#100E23" }}*/}
                        {/*              className="pb-1 "*/}
                        {/*            >*/}
                        {/*              Lvl (*/}
                        {/*              {item.active_level[0]?.levelNumber*/}
                        {/*                ? item.active_level[0]?.levelNumber*/}
                        {/*                : 0}*/}
                        {/*              )*/}
                        {/*            </p>*/}
                        {/*            <p*/}
                        {/*              style={{*/}
                        {/*                color: "#100E23",*/}
                        {/*                fontSize: "12px",*/}
                        {/*              }}*/}
                        {/*              className={*/}
                        {/*                cookies.lang === "ar"*/}
                        {/*                  ? "pb-0 rtl"*/}
                        {/*                  : "pb-0"*/}
                        {/*              }*/}
                        {/*            >*/}
                        {/*              {i18next.t("clientId")} {item.clientid}*/}
                        {/*            </p>*/}
                        {/*            <p*/}
                        {/*                style={{ color: "#100E23", fontSize: "12px" }}*/}
                        {/*                className={*/}
                        {/*                  cookies.lang === "ar"*/}
                        {/*                      ? "pb-0 rtl"*/}
                        {/*                      : "pb-0"*/}
                        {/*                }*/}
                        {/*            >*/}
                        {/*              {i18next.t("Email")} :{item.email}*/}
                        {/*            </p>*/}
                        {/*            <img*/}
                        {/*              src="../assets/imgs/checked.png"*/}
                        {/*              className="check_child2 d-none"*/}
                        {/*            />*/}
                        {/*          </div>*/}
                        {/*        </div>*/}
                        {/*      ))}*/}
                        {/*    </>*/}
                        {/*  ) : (*/}
                        {/*    <div className="w-100 text-center text-white container pt-5 ">*/}
                        {/*      <a*/}
                        {/*        class="text-primary "*/}
                        {/*        href=""*/}
                        {/*        onClick={goTosignNewChild}*/}
                        {/*      >*/}
                        {/*        <svg*/}
                        {/*          className="m-1 pt-0"*/}
                        {/*          width="130"*/}
                        {/*          height="130"*/}
                        {/*          viewBox="0 0 316 322"*/}
                        {/*          fill="#100E23"*/}
                        {/*          xmlns="http://www.w3.org/2000/svg"*/}
                        {/*        >*/}
                        {/*          <path*/}
                        {/*            d="M164.444 4.13815L288.129 76.9854C292.004 79.2677 294.383 83.4295 294.383 87.9267V234.073C294.383 238.57 292.004 242.732 288.129 245.015L164.444 317.862C160.467 320.204 155.533 320.204 151.556 317.862L27.8711 245.015C23.996 242.732 21.6172 238.57 21.6172 234.073V87.9267C21.6172 83.4295 23.996 79.2677 27.8711 76.9854L151.556 4.13816C155.533 1.7959 160.467 1.79589 164.444 4.13815Z"*/}
                        {/*            stroke="#100E23"*/}
                        {/*            stroke-width="0.590604"*/}
                        {/*          />*/}
                        {/*          <g clip-path="url(#clip0_950_3103)">*/}
                        {/*            <path*/}
                        {/*              d="M162.671 144.333C162.671 135.125 155.006 127.667 145.541 127.667C136.077 127.667 128.412 135.125 128.412 144.333C128.412 153.542 136.077 161 145.541 161C155.006 161 162.671 153.542 162.671 144.333ZM154.106 144.333C154.106 148.917 150.252 152.667 145.541 152.667C140.831 152.667 136.977 148.917 136.977 144.333C136.977 139.75 140.831 136 145.541 136C150.252 136 154.106 139.75 154.106 144.333ZM111.282 186V194.333H179.801V186C179.801 174.917 156.976 169.333 145.541 169.333C134.107 169.333 111.282 174.917 111.282 186ZM119.847 186C120.704 183.042 133.979 177.667 145.541 177.667C157.061 177.667 170.294 183 171.236 186H119.847ZM192.648 173.5V161H205.495V152.667H192.648V140.167H184.083V152.667H171.236V161H184.083V173.5H192.648Z"*/}
                        {/*              fill="white"*/}
                        {/*            />*/}
                        {/*          </g>*/}
                        {/*          <defs>*/}
                        {/*            <clipPath id="clip0_950_3103">*/}
                        {/*              <rect*/}
                        {/*                width="102.778"*/}
                        {/*                height="100"*/}
                        {/*                fill="white"*/}
                        {/*                transform="translate(107 111)"*/}
                        {/*              />*/}
                        {/*            </clipPath>*/}
                        {/*          </defs>*/}
                        {/*        </svg>*/}
                        {/*        <p*/}
                        {/*          style={{ color: "#100E23" }}*/}
                        {/*          className="font_15 "*/}
                        {/*        >*/}
                        {/*          {i18next.t("createChild")}*/}
                        {/*        </p>*/}
                        {/*      </a>*/}
                        {/*    </div>*/}
                        {/*  )}*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>

                  <div className="kid-mission pt-3 pb-3 mt-3">
                    <p className="pt-4"> {i18next.t("childMission")}</p>

                    <div className=" pdash ">
                      <ul class="nav nav-pills p-3">
                        <li class="nav-item w-50">
                          <a
                            class="nav-link active "
                            data-bs-toggle="pill"
                            href="#complete_miss"
                          >
                            {i18next.t("completed")}
                          </a>
                        </li>
                        <li class="nav-item w-50">
                          <a
                            class="nav-link "
                            data-bs-toggle="pill"
                            href="#active_miss"
                          >
                            {i18next.t("active")}
                          </a>
                        </li>
                      </ul>

                      {/* Tab panes */}
                      <div class="tab-content ">
                        <div
                          class="tab-pane container active"
                          id="complete_miss"
                        >
                          {/* <p  className='pt-4'>Completed mission(s)</p> */}
                          <div class="list-group mb-0 pt-1">
                            {Ischecked == true ? (
                              CompletedMissions.length != 0 ? (
                                CompletedMissions.map((item, index) => (
                                  <a class="list-group-item  list-group-item-action mb-2 kid_mis">
                                    <div className="row">
                                      <span className="w-10">
                                        <img
                                          src={item.icon}
                                          style={{ width: 19, height: 25 }}
                                          alt={item.icon}
                                          className=" pb-0"
                                        />
                                      </span>
                                      <span className="w-80">
                                        <div>
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip>{item.name}</Tooltip>
                                            }
                                          >
                                            <span className="tit letters_limit">
                                              {item.name.length > 27
                                                ? item.name.substring(0, 27) +
                                                  "..."
                                                : item.name}
                                            </span>
                                          </OverlayTrigger>
                                          <br></br>
                                        </div>
                                      </span>
                                    </div>
                                  </a>
                                ))
                              ) : (
                                <div className="pb-3 text-white text-center  pt-5 ">
                                  {i18next.t("noCompletedMission")}
                                </div>
                              )
                            ) : (
                              <div className="pb-3 text-white text-center  pt-5 ">
                                {i18next.t("selectChild")}
                              </div>
                            )}
                          </div>
                        </div>

                        <div class="tab-pane container " id="active_miss">
                          {/* <p  className='pt-4'>Active mission(s)</p> */}
                          <div class="list-group mb-0 pt-1">
                            {Ischecked == true ? (
                              ActiveMission.length != 0 ? (
                                ActiveMission.map((item, index) => (
                                  <a class="list-group-item  list-group-item-action mb-2 kid_mis">
                                    <div className="row">
                                      <span className="w-10">
                                        <img
                                          src={item.icon}
                                          style={{ width: 19, height: 25 }}
                                          alt={item.icon}
                                          className=" pb-0"
                                        />
                                      </span>
                                      <span className="w-80">
                                        <div>
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip>{item.name}</Tooltip>
                                            }
                                          >
                                            <span className="tit letters_limit">
                                              {item.name.length > 27
                                                ? item.name.substring(0, 27) +
                                                  "..."
                                                : item.name}
                                            </span>
                                          </OverlayTrigger>
                                          <br></br>
                                        </div>
                                      </span>
                                      {item.isSystemMission != 1 && (
                                        <div class="dropdown  w-10 float-end "></div>
                                      )}
                                    </div>
                                  </a>
                                ))
                              ) : (
                                <div className="pb-3 text-white text-center  pt-5 ">
                                  {i18next.t("noAssignMission")}
                                </div>
                              )
                            ) : (
                              <div className="pb-3 text-white text-center  pt-5 ">
                                {i18next.t("selectChild")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
          </section>
        </div>
      )}
    </div>
  );
}
