import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Kidreward from "../../layout/Kidreward";
import en from "../../resources/en.json";
import RewardDialog from "../../layout/RewardDialog";
import EditRewardDialog from "../../layout/EditRewardDialog";
import config from "../../config.json";
import MissionCard from "../../layout/MissionCard";
import MissionManagmentDialog from "../../layout/MissionManagmentDialog";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import i18next from "../../i18n/config";

export default function ParentAtviceMissions(props) {
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const [isLoading, setLoading] = useState(true);
  const username = props.username;
  const [cookies, setCookie] = useCookies(["lang"]);

  // const id=props.id;
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);

  const navigate = useNavigate();
  let [OpenAddMissionDialog, setOpenAddMissionDialog] = useState(false);

  const [Missions, setMissions] = useState([]);
  const [AddedMissions, setAddedMissions] = useState(0);
  const [AssignedMissions, setAssignedMissions] = useState(0);

  useEffect(() => {
    if (OpenAddMissionDialog == true) {
      setTimeout(() => {
        setOpenAddMissionDialog(false);
      }, 1000);
    }
  }, [OpenAddMissionDialog]);

  const handleChange = React.useCallback((newValue) => {
    fetch(`${BaseUrl}GetParentActiveCustomMissions/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {

      response.json().then(function (CustomMission_response) {
        if (CustomMission_response.data.length != 0) {
          setMissions(CustomMission_response.data.missions);
          setAddedMissions(CustomMission_response.data.addedMissions);
          setAssignedMissions(CustomMission_response.data.assignedMissions);
        }
        setLoading(false);
      });
    });
    props.onChange("needrefreshEditReward");
  }, []);

  useEffect(() => {
    fetch(`${BaseUrl}GetParentActiveCustomMissions/${id}`, {
      method: "get",
      body: JSON.stringify(),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (CustomMission_response) {

        if (CustomMission_response.data.length != 0) {
          setMissions(CustomMission_response.data.missions);
          setAddedMissions(CustomMission_response.data.addedMissions);
          setAssignedMissions(CustomMission_response.data.assignedMissions);
        }
        setLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    if (props.needrefresh == true) {
      fetch(`${BaseUrl}GetParentActiveCustomMissions/${id}`, {
        method: "get",
        body: JSON.stringify(),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (CustomMission_response) {

          if (CustomMission_response.data.length != 0) {
            setMissions(CustomMission_response.data.missions);
            setAddedMissions(CustomMission_response.data.addedMissions);
            setAssignedMissions(CustomMission_response.data.assignedMissions);
          }
          setLoading(false);
        });
      });
    }
  }, [props.needrefresh]);
  return (
    <div>
      <MissionManagmentDialog
        show={OpenAddMissionDialog}
        onChange={handleChange}
        FromEdit={false}
      />

      <div className="desktop">
        <section className="container-fluid p-4 bg_black">
          <div className=" container-fluid mt-3  p-4 kidreward reward-img ">
            <div className="pb-3">
              <a>
                <span
                  className={
                    cookies.lang === "ar"
                      ? " float-end text-white"
                      : " float-start text-white"
                  }
                >
                  {i18next.t("ComponentTitle")}
                </span>
              </a>
            </div>
            <br></br>
            <br></br>
            <div className="row">
              <div className="w-auto text-start p-2 text-white">
                {/*<p className="retit">{i18next.t("Added_missions")} </p>*/}
                {/*<p className="added">{AddedMissions}</p>*/}
                <p className="retit mt-5">{i18next.t("AssignedMissions")}</p>
                <p className="comp">{AssignedMissions}</p>
                {/*<a*/}
                {/*  className={*/}
                {/*    cookies.lang === "ar"*/}
                {/*      ? " btn white-btn mt-5 w-auto rtl text-end "*/}
                {/*      : " btn white-btn mt-5 w-auto "*/}
                {/*  }*/}
                {/*  onClick={() => setOpenAddMissionDialog(true)}*/}
                {/*>*/}
                {/*  <span>*/}
                {/*    <svg*/}
                {/*      width="34"*/}
                {/*      height="34"*/}
                {/*      viewBox="0 0 34 34"*/}
                {/*      fill="none"*/}
                {/*      xmlns="http://www.w3.org/2000/svg"*/}
                {/*    >*/}
                {/*      <path*/}
                {/*        d="M17.0007 0.333313C7.80065 0.333313 0.333984 7.79998 0.333984 17C0.333984 26.2 7.80065 33.6666 17.0007 33.6666C26.2007 33.6666 33.6673 26.2 33.6673 17C33.6673 7.79998 26.2007 0.333313 17.0007 0.333313ZM25.334 18.6666H18.6673V25.3333H15.334V18.6666H8.66732V15.3333H15.334V8.66665H18.6673V15.3333H25.334V18.6666Z"*/}
                {/*        fill="#2A49B6"*/}
                {/*      />*/}
                {/*    </svg>*/}
                {/*  </span>*/}
                {/*  <span className="p-3">{i18next.t("BtnAddMission")}</span>*/}
                {/*</a>*/}
              </div>
              <div className="w-80  row m-auto reward_hero">
                {Missions.length != 0 ? (
                  Missions.map((item, index) => (
                    <MissionCard item={item} onChange={handleChange} />
                  ))
                ) : (
                  <div className="  text-white text-center container  ">
                    {i18next.t("NoItem")}
                  </div>
                )}
              </div>
              <div className="all-rewards d-none">
                <a className="btn white-outline-btn mt-5 w-25">
                  <span className="p-3"> {i18next.t("seeMissions")}</span>
                  <span>
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33268 8.83337H0.666016V10.1667H7.33268V8.83337ZM11.3327 3.50004H0.666016V4.83337H11.3327V3.50004ZM0.666016 7.50004H11.3327V6.16671H0.666016V7.50004ZM0.666016 0.833374V2.16671H11.3327V0.833374H0.666016Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mobile">
        <section className=" container-fluid p-4 bg_black ">
          <div className="container-fluid mt-3  p-4 kidreward reward-img">
            <div className="pb-3">
              <a>
                <span
                  className={
                    cookies.lang === "ar"
                      ? " float-end text-white"
                      : " float-start text-white"
                  }
                >
                  {i18next.t("ComponentTitle")}
                </span>
              </a>
            </div>
            <br></br>
            <br></br>
            <div className="">
              <div
                className={
                  cookies.lang === "ar"
                    ? " row text-end p-2 text-white"
                    : " row text-start p-2 text-white"
                }
              >
                {/*<div className="w-50">*/}
                {/*  <p className="retit">{i18next.t("AddedMissions")}</p>*/}
                {/*  <p className="added">{AddedMissions}</p>*/}
                {/*</div>*/}
                <div className="w-100">
                  <p className="retit">{i18next.t("ComponentTitle")}</p>
                  <p className="comp">{AssignedMissions}</p>
                </div>
              </div>
              <div className="reward_hero_m  row m-auto ">
                {Missions.length != 0 ? (
                  Missions.map((item, index) => (
                    <>
                      <MissionCard item={item} onChange={handleChange} />
                    </>
                  ))
                ) : (
                  <div className="text-white text-center container    ">
                    {i18next.t("NoItem")}
                  </div>
                )}
              </div>
              <div className="all-rewards-m row">
                {/*<a className={*/}
                {/*      cookies.lang === "ar"*/}
                {/*        ? " btn white-btn w-100 rtl "*/}
                {/*        : " btn white-btn w-100 "*/}
                {/*    }*/}
                {/*  onClick={() => setOpenAddMissionDialog(true)}*/}
                {/*>*/}
                {/*  <span>*/}
                {/*    <svg*/}
                {/*      width="21"*/}
                {/*      height="21"*/}
                {/*      viewBox="0 0 34 34"*/}
                {/*      fill="none"*/}
                {/*      xmlns="http://www.w3.org/2000/svg"*/}
                {/*    >*/}
                {/*      <path*/}
                {/*        d="M17.0007 0.333313C7.80065 0.333313 0.333984 7.79998 0.333984 17C0.333984 26.2 7.80065 33.6666 17.0007 33.6666C26.2007 33.6666 33.6673 26.2 33.6673 17C33.6673 7.79998 26.2007 0.333313 17.0007 0.333313ZM25.334 18.6666H18.6673V25.3333H15.334V18.6666H8.66732V15.3333H15.334V8.66665H18.6673V15.3333H25.334V18.6666Z"*/}
                {/*        fill="#2A49B6"*/}
                {/*      />*/}
                {/*    </svg>*/}
                {/*  </span>*/}
                {/*  <span className="p-1">{i18next.t("BtnAddMission")}</span>*/}
                {/*</a>*/}
                <a className="text-white  w-50 mt-2 d-none">
                  <span className="p-1">{i18next.t("View_all")}</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
