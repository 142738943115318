import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import { NavLink } from "react-router-dom";
import en from "../resources/en.json";
import config from "../config.json";
import { Auth, UserRole, UserLevel } from "../redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import { redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";
import icon from "../imgs/translateIcon.png";
import TermsPDF from '../TermsAndConditions/TermsAndConditions.pdf';
import { Checkbox } from 'antd';

export default function Login(props) {
  const navigate = useNavigate();
  const [items, setitems] = useState([]);
  var hostName = window.location.origin;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const [cookies, setCookie] = useCookies(["lang"]);
  let BaseUrl = config.BaseUrl;
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [form, setform] = useState({
    email: "",
    password: "",
  });
  const [EnableLoginBtn, setEnableLoginBtn] = useState(false);
  const [LoginBtnStyle, setLoginBtnStyle] = useState("btn gray-btn mr-5 mt-4");
  const onChangeAgreement = (e) => {
    try {
      setEnableLoginBtn(e.target.checked);
    }catch (e)
    {
      setEnableLoginBtn(false);
    }

  };
  useEffect(() => {

    EnableLoginBtn ==true?setLoginBtnStyle("btn yellow-btn mr-5 mt-4"):setLoginBtnStyle("btn gray-btn mr-5 mt-4");
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
    let message = params.get("message");

    if (message && message=="permissions_not_selected")
    {
      var messageItem=document.getElementById("message1");
      if (messageItem)
      {
        messageItem.classList.add("alert-danger");
        messageItem.innerHTML = i18next.t('permissions_not_selected');
        messageItem.style.display = "block";

      }



    }
  }, [EnableLoginBtn]);

  function loginByFitbit() {
    if (EnableLoginBtn ==true)
    {
      fetch(`${BaseUrl}connectUserTowearable/0/1`, {
        method: "get",
        headers: {
          Accept: "application/json",

          "Accept-Language": cookies.lang,
        },
      }).then(function (response11) {
        response11.json().then(function (connect_fitbitResp) {
          window.location.replace(connect_fitbitResp.data);
        });
      });
    }

  }

  function LoginByGoogle() {
    fetch(`${BaseUrl}connectUserTowearable/0/3`, {
      method: "get",
      headers: {
        Accept: "application/json",

        "Accept-Language": cookies.lang,
      },
    }).then(function (response11) {
      response11.json().then(function (connect_fitbitResp) {
        window.location.replace(connect_fitbitResp.data);
      });
    });
  }

  function submit() {
    document.getElementById("message1").innerHTML = "";
    if (
      document.getElementById("message1").classList.contains("alert-danger")
    ) {
      document.getElementById("message1").classList.remove("alert-danger");
    }
    if (document.getElementById("message1").classList.contains("alert-info")) {
      document.getElementById("message1").classList.remove("alert-info");
    }
    document.getElementById("form-log").classList.add("was-validated");
    if (document.getElementById("form-log").checkValidity()) {
      setLoading(true);
      fetch(`${BaseUrl}loginn`, {
        method: "post",
        body: JSON.stringify(form),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (loginResp) {
          setLoading(false);
          document.getElementById("message1").style.display = "block";
          if (loginResp.status == true) {
            if (!cookies.lang) {
              setCookie("lang", "en", { path: "/" });
            }
            dispatch(
              Auth(loginResp.user.token, loginResp.user.id, loginResp.user.name)
            );
            dispatch(UserRole(loginResp.user.role.role_id));
            dispatch(
              UserLevel(
                loginResp.user.level !== null ? loginResp.user.level : 0
              )
            );
            document.getElementById("message1").classList.add("alert-info");
            document.getElementById("message1").innerHTML =
              i18next.t("login_successmsg");
            if (loginResp.user.role.role_id == 1) {
              navigate("/parent", { state: {} });
            } else if (loginResp.user.role.role_id == 5) {
              navigate("/esportDashboard", { state: {} });
            } else {
              navigate("/dgamer", { state: {} });
            }
          } else {
            document.getElementById("message1").classList.add("alert-danger");
            if (loginResp.errNum == "E0002") {
              document.getElementById("message1").innerHTML = loginResp.msg;
            } else {
              document.getElementById("message1").innerHTML =
                i18next.t("login_check");
            }
          }
        });
      });
    }
  }
  const setLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
  };
  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const changeLangToEn = () => {
    setLanguage("en");
    i18n.changeLanguage("en");
  };
  const changeLangToAr = () => {
    setLanguage("ar");
    i18n.changeLanguage("ar");
  };
  return (
    <div>
      <Head />
      <div className="container-fluid p-2 pb-5 sign vh">
        <Nav />
        <div className="flash1"></div>
        <div className="flash2"></div>
        <div className="flash3"></div>
        <div className="container text-center mt-5 pt-3">
          <button
            style={{ marginTop: "-110px" }}
            className="btn float-end"
            onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}
          >
            <img
              src={icon}
              title={i18next.t("changeLanguage")}
              style={{ width: "50px", height: "50px" }}
            />
          </button>
          <h2 className="text-white"> {i18next.t("login_title")}</h2>
          <div class=" mt-3 ">
            <div class="container ">
              <br />

              <form className=" m-auto form-white" id="form-log">
                {loading == true ? (
                    <div className="w-100 mt-3 mb-2 m-auto">
                      <CircularProgress />
                    </div>
                ) : (
                    <div
                        id="message1"
                        className="w-100 mt-4 mb-5 m-auto p-3"
                    ></div>
                )}
                <div class="mb-3 ">
                  <input
                    type="email"
                    className={"form-control"}
                    id="email"
                    placeholder={i18next.t("mail_placeholder")}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = i18next.t("mail_placeholder"))
                    }
                    name="email"
                    onChange={(e) =>
                      setform({ ...form, email: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {i18next.t("mail_validation")}
                  </div>
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    className={"form-control"}
                    id="password"
                    placeholder={i18next.t("password_placeholder")}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = i18next.t("password_placeholder"))
                    }
                    name="password"
                    onChange={(e) =>
                      setform({ ...form, password: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {i18next.t("password_validation")}
                  </div>
                </div>
                <a
                  className="btn yellow-btn mr-5 mt-4"
                  id="sign"
                  onClick={submit}
                  style={{ backgroundColor: "#EECE52 !important" }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                      fill="#100E23"
                    />
                  </svg>
                  <span className="m-3">{i18next.t("login_btn")}</span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                      fill="#100E23"
                    />
                  </svg>
                </a>
                <div className="  text-white">
                  <hr></hr>{i18next.t("or")}
                </div>
                <div className="row">
                  {/*<div className="col-lg-6">*/}
                  {/*  <a*/}
                  {/*    className="btn yellow-btn mr-5 mt-4"*/}
                  {/*    id="sign"*/}
                  {/*    onClick={LoginByGoogle}*/}
                  {/*    style={{ backgroundColor: "#EECE52 !important" }}*/}
                  {/*  >*/}
                  {/*    <svg*/}
                  {/*      width="14"*/}
                  {/*      height="14"*/}
                  {/*      viewBox="0 0 14 14"*/}
                  {/*      fill="none"*/}
                  {/*      xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    >*/}
                  {/*      <path*/}
                  {/*        d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"*/}
                  {/*        fill="#100E23"*/}
                  {/*      />*/}
                  {/*    </svg>*/}
                  {/*    <span className="m-3">{i18next.t("FitBitLogin")}</span>*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                  <div className={  cookies.lang == "ar" ?"col-lg-12 text-end":"col-lg-12 text-start"} dir={cookies.lang == "ar" ? "rtl" : "ltr"}>
                    <Checkbox onChange={onChangeAgreement}  >

                      <a
                          onClick={() => window.open("/TermsAndConditions.pdf",'_blank')}
                            className="text-white"
                      >
                        <span className=" text-white"> {i18next.t("termsPrefix")} </span>
                           <span className="  text-white link">{i18next.t("terms")}</span>
                      </a>


                    </Checkbox>




                  </div>
                  <div className="col-lg-12">
                    <a
                      className={LoginBtnStyle}
                      id="sign"
                      onClick={loginByFitbit}
                      style={{ backgroundColor: "#EECE52 !important" }}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                          fill="#100E23"
                        />
                      </svg>
                      <span className="m-3">{i18next.t("GoogleLogin")}</span>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col p-1">
                    <NavLink
                      className="nav-link text-white link"
                      to="/RegisterAccountType"
                    >
                      {i18next.t("login_noaccount")}
                    </NavLink>
                  </div>
                  <div className="col p-1">
                    <NavLink className="nav-link text-white link" to="/forget">
                      {i18next.t("forget_password")}
                    </NavLink>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer bg="bg_blue" />
    </div>
  );
}
