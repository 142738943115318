import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import en from "../resources/en.json";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DeleteRewardDialog from "../layout/DeleteRewardDialog";
import { useDispatch, useSelector } from "react-redux";
import config from "../config.json";
import { useCookies } from "react-cookie";
import i18next from "../i18n/config";
export default function TournamentchallengItemNew(props) {
  const img_name = "../assets/imgs/" + props.imageName + ".png";
  let BaseUrl = config.BaseUrl;
  let [deletemissionpop, setdeletemissionpop] = useState(false);
  const token = useSelector((state) => state.appReducer.token);
  const id = useSelector((state) => state.appReducer.id);
  const [cookies, setCookie] = useCookies(["lang"]);
  let [teams, setTeams] = useState([]);
  useEffect(() => {
    if (deletemissionpop == true) {
      setTimeout(() => {
        setdeletemissionpop(false);
      }, 1000);
    }
  }, [deletemissionpop]);

  const handleChange = React.useCallback((newValue) => {
    props.onChange("david");
  });

  // useEffect(() => {
  //   fetch(`${BaseUrl}teams/${id}`, {
  //     method: "get",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //       "Accept-Language": "en",
  //     },
  //   }).then(function (response11) {
  //     response11.json().then(function (Resp) {
  //       setTeams(Resp.data);
  //     });
  //   });
  // }, []);

  return (
    <div
      className="col-md-2 m-2 text-center container_card "
      style={{ position: "relative", width: "100%" }}>
      <div className="team_card ">
        <img
          src={"../assets/imgs/teamBack.jpeg"}
          style={{
            width: "100%",
            height: "100%",
          }}></img>
      </div>
      <div className="black-layer ">
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip>{props.challengeName}</Tooltip>}>
          <p
            className="letters_limit text-white point"
            style={{ marginTop: "20px" }}>
            <span className="desc m-2">
              {props.challengeName.length > 20
                ? props.challengeName.substring(0, 17) + "..."
                : props.challengeName}
            </span>
          </p>
        </OverlayTrigger>

        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip>{props.Favorite_destination}</Tooltip>}>
          <p
            className="letters_limit text-white point"
            style={{ marginTop: "20px" }}>
            {props.Favorite_destination}
            {/*<span className="desc ">*/}
            {/*You should achieve 5000 step*/}
            {/*                    </span>*/}
          </p>
        </OverlayTrigger>
        <OverlayTrigger
          key="top"
          placement="top"
          overlay={<Tooltip>{props.Goal}</Tooltip>}>
          <p
            className="letters_limit text-white point"
            style={{ marginTop: "20px" }}>
            {props.Goal}
            {/*<span className="desc ">*/}
            {/*You should achieve 5000 step*/}
            {/*                    </span>*/}
          </p>
        </OverlayTrigger>

        <div className="row " id="kid_assign">
          <div className="col-md-12  pb-0">
            <div
              className="row_new_s row_new_new m-auto "
              style={{
                position: "absolute",
                bottom: 0,
                width: "inherit",
              }}>
              {/* {teams.map((item, index) => (
        <div
          className="hexagonnew point"
          
        >
          <img
            src={
              item.logo !== ""
                ? item.logo
                : "../assets/imgs/kidwatch.png"
            }
            className=" team-img"
          />
        </div>
         ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div
    //   className="col-md-6 col-sm-12 reward mt-2 kidreward "
    //   style={{ height: "250px" }}>
    //   <div class="card pb-4 m-auto" style={{ height: "220px" }}>
    //     <div class="card-body pb-4">
    //       <p  className={cookies.lang  === 'ar' ? 'menu_rtl tit' : 'menu_ltr tit'}>{i18next.t('challenge_name')}</p>
    //       <OverlayTrigger
    //         key="top"
    //         placement="top"
    //         overlay={<Tooltip>{props.challengeName}</Tooltip>}>
    //         <span className="desc m-2">
    //           {props.challengeName.length > 20
    //             ? props.challengeName.substring(0, 17) + "..."
    //             : props.challengeName}
    //         </span>
    //       </OverlayTrigger>
    //       <br />
    //       <br />

    //       <p className={cookies.lang  === 'ar' ? 'menu_rtl tit' : 'menu_ltr tit'}> {i18next.t('challenge_Favorite_destination')}</p>
    //       <OverlayTrigger
    //         key="top"
    //         placement="top"
    //         overlay={<Tooltip>{props.Favorite_destination}</Tooltip>}>
    //         <span className="desc m-2">
    //           {props.Favorite_destination.length > 20
    //             ? props.Favorite_destination.substring(0, 17) + "..."
    //             : props.Favorite_destination}
    //         </span>
    //       </OverlayTrigger>
    //     </div>
    //   </div>
    // </div>
  );
}
