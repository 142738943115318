import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import { NavLink } from "react-router-dom";
import en from "../resources/en.json";
import config from "../config.json";
import { Auth, UserRole, UserLevel } from "../redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from 'react-cookie';
import { redirect } from "react-router-dom";
export default function Login(props) {
  const navigate = useNavigate();
  const [items, setitems] = useState([]);
  var hostName = window.location.origin;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const [cookies, setCookie] = useCookies(['lang']);
  let BaseUrl = config.BaseUrl;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [form, setform] = useState({
    email: params.get("username"),
    password: params.get("password"),
  });

  useEffect( ()=>{
    let email1 = params.get("username");
    let password1 = params.get("password");
    let token = params.get("token");
    setform({ ...form, email: email1, password: email1 });
    setLoading(true);
    fetch(`${BaseUrl}loginBytoken`, {
      method: "post",
      body: JSON.stringify(form),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    }).then(function (response) {
      response.json().then(function (loginResp) {
        setLoading(false);

        if (loginResp.status == true) {

          if (!cookies.lang) {
            setCookie('lang', 'en', { path: '/' });
          }
          dispatch(
              Auth(loginResp.user.token, loginResp.user.id, loginResp.user.name)
          );
          dispatch(UserRole(loginResp.user.role.role_id));
          dispatch(
              UserLevel(
                  loginResp.user.level !== null ? loginResp.user.level : 0
              )
          );


          if (loginResp.user.role.role_id == 1) {
            navigate("/parent", { state: {} });
          } else if (loginResp.user.role.role_id == 5) {
            navigate("/esportDashboard", { state: {} });
          } else {
            navigate("/dgamer", { state: {} });
          }
        } else {
          navigate("/", { state: {} });
        }
      });
    });
  },[])

  return (
   <></>
  );
}
