import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import { NavLink } from "react-router-dom";
import en from "../resources/en.json";
import config from "../config.json";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";
import icon from "../imgs/translateIcon.png";

export default function ResetPass(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const id = useSelector((state) => state.appReducer.id);
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);

  // const id=location.state.id;
  const name = useSelector((state) => state.appReducer.username);
  const [items, setitems] = useState([]);
  var hostName = window.location.origin;
  const backEnd = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";

  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const [form, setform] = useState({
    password: "",
  });

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  
   const setLanguage = (lang) => {
     setCookie("lang", lang, { path: "/" });
   };
   useEffect(() => {
     if (cookies && cookies.lang) {
       i18n.changeLanguage(cookies.lang);
     } else {
       i18n.changeLanguage("en");
     }
   }, []);

   const changeLangToEn = () => {
     setLanguage("en");
     i18n.changeLanguage("en");
   };
   const changeLangToAr = () => {
     setLanguage("ar");
     i18n.changeLanguage("ar");
   };
  function submit() {
    document.getElementById("form-reset").classList.add("was-validated");
    if (
      !document
        .getElementById("password1")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      document.getElementById("pass1").style.display = "block";
    }
    if (
      document.getElementById("co-password1").value !=
      document.getElementById("password1").value
    ) {
      document.getElementById("confirm1").style.display = "block";
    }
    if (
      document.getElementById("form-reset").checkValidity() &&
      document.getElementById("co-password1").value ==
        document.getElementById("password1").value &&
      document
        .getElementById("password1")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      fetch(`${BaseUrl}users/completeinfo/${id}`, {
        method: "put",
        body: JSON.stringify(form),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (changepassResp) {
          if (changepassResp.status == true) {
            if (changepassResp.data.id === 1) {
              navigate("/parent", { state: {} });
            } else if (
              changepassResp.data.id === 2 ||
              changepassResp.data.id == 3 ||
              changepassResp.data.id == 6
            )
              navigate("/dgamer", { state: {} });
            else if (changepassResp.data.id === 5) {
              navigate("/esportDashboard", { state: {} });
            }
          }
        });
      });
    }
  }

  return (
    <div>
      <Head />
      <div className="container-fluid p-2 pb-5 sign vh">
        <Nav />
        <div className="flash1"></div>
        <div className="flash2"></div>
        <div className="flash3"></div>
        <div className="container text-center mt-5 pt-3">
          <button
            style={{ marginTop: "-110px" }}
            className="btn float-end"
            onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}>
            <img
              src={icon}
              title={i18next.t("changeLanguage")}
              style={{ width: "50px", height: "50px" }}
            />
          </button>
          <h2 className="text-white">{i18next.t("reset_tit")}</h2>
          <div class=" mt-3 ">
            <div class="container ">
              <br />
              <form className=" m-auto form-white" id="form-reset">
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="password1"
                    placeholder={en.sign.password_placeholder}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = en.sign.password_placeholder)
                    }
                    name="password"
                    onChange={(e) =>
                      setform({ ...form, password: e.target.value })
                    }
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {en.sign.password_validation}{" "}
                  </div>
                  <div
                    className="invalid-feedback text-start text-danger"
                    id="pass1">
                    {en.sign.password_validation_contain}
                  </div>
                </div>
                <div class="mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="co-password1"
                    placeholder={en.sign.confirmPassword_placeholder}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder =
                        en.sign.confirmPassword_placeholder)
                    }
                    name=""
                    required
                  />
                  <div className="invalid-feedback text-start text-danger">
                    {en.sign.confirmpassword_validation}{" "}
                  </div>
                  <div
                    className="invalid-feedback text-start text-danger"
                    id="confirm1">
                    {en.sign.confirmpassword_match}{" "}
                  </div>
                </div>

                <a
                  className="btn yellow-btn mr-5 mt-4"
                  id="sign"
                  onClick={submit}>
                  <span className="m-3">{en.reset.reset_btn}</span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                      fill="#100E23"
                    />
                  </svg>
                </a>
                <div
                  id="message1"
                  className="w-100 mt-4 mb-5 m-auto alert-info p-3"></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer bg="bg_blue" />
    </div>
  );
}
