import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Head from "../layout/Head";
import Usernav from "../layout/Usernav";
import Footer from "../layout/Footer";
import Game from "../layout/Game";
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import en from "../resources/en.json";
import config from "../config.json";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {Auth} from "../redux/Actions/AuthActions";
import { debounce } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import i18next from '../i18n/config';
import { useTranslation } from "react-i18next";

export default function EditProfile(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const role = useSelector((state) => state.appReducer.role);
    const [cookies, setCookie] = useCookies(["lang"]);
  const [isLoading, setLoading] = useState(true);
  const [items, setitems] = useState([]);
  // const [role,setrole]=useState(0);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [userID, setuserID] = useState("");
  const [Phone, setPhone] = useState("");
  const [Gender, setGender] = useState("");
  const [Weight, setWeight] = useState("");
  const [Height, setHeight] = useState("");
  const [picture, setPicture] = useState("");
  const [image, setimage] = useState();
  const [imgSrc, setimgSrc] = useState("");
  const { i18n } = useTranslation();

  const [DOB, setDOB] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [form1, setform1] = useState({
    name: name,
    email: email,
    Phone: Phone,
    Gender: Gender,
    Weight: Weight,
    Height: Height,
    DOB: DOB,
    image: image,
  });
  // const id=location.state.id;
  const id = useSelector((state) => state.appReducer.id);

  const username = useSelector((state) => state.appReducer.username);
  const level = useSelector((state) => state.appReducer.level);

  var hostName = window.location.origin;

  const backend = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  // const backend="http://127.0.0.1:8000/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;

  var data_res;
  const arr = [];

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    fetch(`${BaseUrl}users/showinfo/${id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-Language": cookies.lang,
      },
    }).then(function (response) {
      response.json().then(function (getshowinfo_response) {
        setname(getshowinfo_response.data.name); //id, jarvaniv (I prefer Zac)
        setemail(getshowinfo_response.data.email);
        setPhone(getshowinfo_response.data.Phone);
        setGender(getshowinfo_response.data.Gender);
        setWeight(getshowinfo_response.data.Weight);
        setHeight(getshowinfo_response.data.Height);
        setDOB(getshowinfo_response.data.DOB);
        setuserID(getshowinfo_response.data.clientid);
        data_res = getshowinfo_response.data;
        setitems(data_res);
        setimgSrc(getshowinfo_response.data.imagePath);
        setform1({
          name: getshowinfo_response.data.name,
          email: getshowinfo_response.data.email,
          Phone: getshowinfo_response.data.Phone,
          Gender: getshowinfo_response.data.Gender,
          DOB: getshowinfo_response.data.DOB,
          Weight: getshowinfo_response.data.Weight,
          Height: getshowinfo_response.data.Height,
        });
        setLoading(false);
      });
    });
  }, []);
  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <div className="load-page">
        <ReactLoading type="spin" color="#F9AF22" className="load" />
      </div>
    );
  }

  function previewFile() {
    document.getElementById("profile-image-upload").click();
  }

  function changeImage(e) {
    document.getElementById("image_size").classList.add("d-none");
    if (e.target.files[0].size <= 16000000) {
      setimage(e.target.files[0]);
      setform1({ ...form1, image: e.target.files[0] });
      setimgSrc(URL.createObjectURL(e.target.files[0]));
    } else document.getElementById("image_size").classList.remove("d-none");
  }

  function submit() {
    document.getElementById("form-reset").classList.add("was-validated");
    let data = new FormData();
    data.append("image", form1.image);
    data.append("name", form1.name);
    data.append("email", form1.email);
    data.append("DOB", form1.DOB);
    data.append("Height", form1.Height);
    data.append("Weight", form1.Weight);
    data.append("Phone", form1.Phone);
    console.log(data, data.get(Phone));
    if (form1.name.length > 50) {
      document.getElementById("profile_length").classList.remove("d-none");
    } else if (
      document.getElementById("form-reset").checkValidity() &&
      form1.name.length <= 50
    ) {
      setLoading(true);
      fetch(`${BaseUrl}users/update/${id}`, {
        method: "post",
        body: data,
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
          "Accept-Language": cookies.lang,
        },
      }).then(function (response) {
        response.json().then(function (Resp) {
          if (Resp.status == true) {
            dispatch(Auth(token, id, form1.name));
            setLoading(false);
            navigate("/profile", { state: { update: username } });
          }
          else
          {
            setLoading(false);
            navigate("/profile", { state: { update: Resp.msg } });
          }
        });
      });
    }
  }
  return (
    <div>
      <Head />
      {width > 950 ? (
        <div className="desktop">
          <section className=" container-fluid p-2 bg_black">
            <Usernav />
            <div className="row container-fluid mt-5 dash"></div>
          </section>

          <section class="container-fluid p-2 bg_black">
            <div class="container py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100 ">
                <div class="col col-lg-6 mb-4 mb-lg-0 profile">
                  <div class="card mb-3 div11Style">
                    <form
                      id="form-reset"
                      method="post"
                      enctype="multipart/form-data">
                      <div class="row g-0">
                        <div class="col-md-8 gradient-custom text-center text-white div1Style">
                          {/* <div class="app-sidebar__user clearfix"> */}
                          <div class="container ">
                            <div className="m-5  camera_div">
                              {imgSrc == null ? (
                                <img
                                  src="../assets/imgs/account_8.png"
                                  alt="imgSrc"
                                  width="200px"
                                  height="200px"
                                  className="hover_img"
                                />
                              ) : (
                                <img
                                  src={imgSrc}
                                  alt="imgSrc"
                                  width="200px"
                                  height="200px"
                                  className="border rounded-circle hover_img"
                                />
                              )}

                              <input
                                type="file"
                                className="w-full px-4 py-2 namestyle d-none"
                                label="image"
                                name="image"
                                id="profile-image-upload"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => {
                                  changeImage(e);
                                }}
                              />
                              <div
                                className="camera point"
                                onClick={previewFile}>
                                <svg
                                  width="55"
                                  height="51"
                                  viewBox="0 0 23 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M21 5H17.83L16 3H10V5H15.12L16.95 7H21V19H5V10H3V19C3 20.1 3.9 21 5 21H21C22.1 21 23 20.1 23 19V7C23 5.9 22.1 5 21 5ZM8 13C8 15.76 10.24 18 13 18C15.76 18 18 15.76 18 13C18 10.24 15.76 8 13 8C10.24 8 8 10.24 8 13ZM13 10C14.65 10 16 11.35 16 13C16 14.65 14.65 16 13 16C11.35 16 10 14.65 10 13C10 11.35 11.35 10 13 10ZM5 5H8V3H5V0H3V3H0V5H3V8H5V5Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>
                              <div
                                className="text-center text-danger d-none"
                                id="image_size">
                                {i18next.t("image_size")}{" "}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex justify-content-center align-items-center">
                            <input
                              className="namestyle form-control idd11 p-3"
                              type="text"
                              value={name}
                              maxLength="50"
                              // onChange={(e) => {}}
                              onChange={(e) => {
                                setname(e.target.value);
                                setform1({ ...form1, name: e.target.value });
                              }}
                              name="name"
                              required
                            />
                            <div className="invalid-feedback text-start text-danger">
                              {i18next.t("name_validation")}{" "}
                            </div>
                          </div>
                          <div
                            className="text-center text-danger d-none"
                            id="profile_length">
                            {i18next.t("length_validation")}{" "}
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="card-body p-4 tStyle">
                            <h6>{i18next.t("profile_Personal_info")}</h6>
                            <hr class="mt-0 mb-4" />
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_gender")}</h6>
                                <select
                                  name="gender"
                                  className="form-select idd11 p-3"
                                  id=""
                                  onChange={(e) => {
                                    setGender(e.target.value);
                                    setform1({
                                      ...form1,
                                      Gender: e.target.value,
                                    });
                                  }}>
                                  <option>{Gender}</option>
                                  <option value="MALE">
                                    {i18next.t("male")}{" "}
                                  </option>
                                  <option value="FEMALE">
                                    {i18next.t("female")}{" "}
                                  </option>
                                </select>
                                {/* <p class="idd11 text-muted text-lowercase">{Gender}</p> */}
                              </div>
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_weight")}</h6>
                                <input
                                  type="number"
                                  min={10}
                                  max={500}
                                  class="form-control idd11 p-3"
                                  value={Weight}
                                  onChange={(e) => {
                                    setWeight(e.target.value);
                                    setform1({
                                      ...form1,
                                      Weight: e.target.value,
                                    });
                                  }}
                                  placeholder={en.sign.profile_weight}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.profile.profile_weight)
                                  }
                                  name="weight"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {i18next.t("weight_validation")}
                                </div>
                              </div>
                            </div>
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_height")}</h6>
                                <input
                                  type="number"
                                  min={10}
                                  max={4000}
                                  class="form-control idd11 p-3"
                                  value={Height}
                                  onChange={(e) => {
                                    setHeight(e.target.value);
                                    setform1({
                                      ...form1,
                                      Height: e.target.value,
                                    });
                                  }}
                                  placeholder={en.profile.profile_height}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.profile.profile_height)
                                  }
                                  name="height"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {i18next.t("height_validation")}
                                </div>
                              </div>
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_dob")}</h6>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}>
                                  <MobileDatePicker
                                    className="form-control point "
                                    inputFormat="MM/DD/YYYY"
                                    value={DOB}
                                    // placeholder={en.profile.profile_dob}
                                    onChange={(e) => {
                                      setDOB(
                                        e?.$y + "-" + (e?.$M + 1) + "-" + e?.$D
                                      );
                                      setform1({
                                        ...form1,
                                        DOB:
                                          e?.$y +
                                          "-" +
                                          (e?.$M + 1) +
                                          "-" +
                                          e?.$D,
                                      });
                                    }}
                                    required
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="mm/dd/yyyy"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <h6>{i18next.t("profile_continfo")}</h6>
                            <hr class="mt-0 mb-4" />
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_mail")}</h6>
                                <input
                                  type="text"
                                  maxLength="200"
                                  class="form-control idd11 p-3"
                                  value={email}
                                  onChange={(e) => {
                                    setemail(e.target.value);
                                    setform1({
                                      ...form1,
                                      email: e.target.value,
                                    });
                                  }}
                                  placeholder={en.sign.mail_placeholder}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.sign.mail_placeholder)
                                  }
                                  name="name"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {i18next.t("mail_validation")}
                                </div>
                              </div>
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_phone")}</h6>
                                <input
                                  type="text"
                                  maxLength="200"
                                  class="form-control idd11 p-3"
                                  value={Phone}
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                    setform1({
                                      ...form1,
                                      Phone: e.target.value,
                                    });
                                  }}
                                  placeholder={en.profile.profile_phone}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.profile.profile_phone)
                                  }
                                  name="phone"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {i18next.t("phone_validation")}
                                </div>
                              </div>
                            </div>
                            <h6>{i18next.t("profile_verialityinfo")}</h6>
                            <hr class="mt-0 mb-4" />
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{i18next.t("profile_userID")}</h6>
                                <p class="idd11 text-muted">{userID}</p>
                              </div>
                            </div>

                            <div class="d-flex justify-content-center">
                              <a>
                                <i class="fab fa-facebook-f fa-lg me-3"></i>
                              </a>
                              <a>
                                <i class="fab fa-twitter fa-lg me-3"></i>
                              </a>
                              <a>
                                <i class="fab fa-instagram fa-lg"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        className="btn yellow-btn mr-5 mt-4"
                        id="sign"
                        onClick={submit}
                        style={{ backgroundColor: "#EECE52 !important" }}>
                        <span className="m-3">{i18next.t("update_btn")}</span>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                            fill="#100E23"
                          />
                        </svg>
                      </a>
                      <div
                        id="message1"
                        className="w-100 mt-4 mb-5 m-auto alert-info p-3"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div className="mobile">
          <section className=" container-fluid p-2 bg_black">
            <Usernav />
            <div className="row container-fluid mt-5 dash pdash m-0">
              {/* Tab panes */}
            </div>
          </section>

          <section class=" sectionStyle bg_black">
            <div class="container py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col col-lg-6 mb-4 mb-lg-0">
                  <div class="card mb-3 div11Style">
                    <form
                      id="form-reset"
                      method="post"
                      enctype="multipart/form-data">
                      <div class="row g-0">
                        <div class="col-md-4 gradient-custom text-center text-white div1Style">
                          <div class="container">
                            <div className="m-5 camera_div p-0">
                              {imgSrc == null ? (
                                <img
                                  src="../assets/imgs/account_8.png"
                                  alt="imgSrc"
                                  width="200px"
                                  height="200px"
                                />
                              ) : (
                                <img
                                  src={imgSrc}
                                  alt="imgSrc"
                                  width="200px"
                                  height="200px"
                                  className="border rounded-circle"
                                />
                              )}

                              {/* </div>
                                <div> */}
                              <input
                                type="file"
                                className="w-full px-4 py-2 namestyle d-none"
                                label="image"
                                name="image"
                                id="profile-image-upload"
                                accept=".png, .jpg, .jpeg"
                                // onChange={e => { setimage(e.target.files[0]); setform1({...form1,image:e.target.files[0]}); setimgSrc(URL.createObjectURL(e.target.files[0]))}}
                                onChange={(e) => {
                                  changeImage(e);
                                }}
                              />
                              <div
                                className="camera point "
                                onClick={previewFile}>
                                <svg
                                  width="50"
                                  height="46"
                                  viewBox="0 0 23 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M21 5H17.83L16 3H10V5H15.12L16.95 7H21V19H5V10H3V19C3 20.1 3.9 21 5 21H21C22.1 21 23 20.1 23 19V7C23 5.9 22.1 5 21 5ZM8 13C8 15.76 10.24 18 13 18C15.76 18 18 15.76 18 13C18 10.24 15.76 8 13 8C10.24 8 8 10.24 8 13ZM13 10C14.65 10 16 11.35 16 13C16 14.65 14.65 16 13 16C11.35 16 10 14.65 10 13C10 11.35 11.35 10 13 10ZM5 5H8V3H5V0H3V3H0V5H3V8H5V5Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>
                              <div
                                className="text-center text-danger d-none"
                                id="image_size">
                                {en.sign.image_size}{" "}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex justify-content-center align-items-center">
                            <input
                              className="namestyle form-control idd12 p-3"
                              type="text"
                              value={name}
                              maxLength="50"
                              onChange={(e) => {
                                setname(e.target.value);
                                setform1({ ...form1, name: e.target.value });
                              }}
                              name="name"
                              required
                            />
                            <div
                              className="invalid-feedback text-start text-danger"
                              id="Vname">
                              {en.sign.name_validation}{" "}
                            </div>
                          </div>
                          <div
                            className="text-center text-danger d-none"
                            id="profile_length">
                            {en.sign.length_validation}{" "}
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="card-body p-4">
                            <h6>{en.profile.profile_continfo}</h6>
                            <hr class="mt-0 mb-4" />
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_mail}</h6>
                                <input
                                  type="text"
                                  maxLength="200"
                                  class="form-control idd12 p-3"
                                  value={email}
                                  onChange={(e) => {
                                    setemail(e.target.value);
                                    setform1({
                                      ...form1,
                                      email: e.target.value,
                                    });
                                  }}
                                  // onChange={(e) => {setemail(e.target.value )}}
                                  placeholder={en.sign.mail_placeholder}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.sign.mail_placeholder)
                                  }
                                  name="name"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {en.sign.mail_validation}{" "}
                                </div>
                              </div>
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_phone}</h6>
                                <input
                                  type="text"
                                  maxLength="200"
                                  class="form-control idd12 p-3"
                                  value={Phone}
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                    setform1({
                                      ...form1,
                                      Phone: e.target.value,
                                    });
                                  }}
                                  placeholder={en.profile.profile_phone}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.profile.profile_phone)
                                  }
                                  name="phone"
                                  required
                                />
                              </div>
                            </div>
                            <h6>{i18next.t("profile_Personal_info")}</h6>
                            <hr class="mt-0 mb-4" />
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_gender}</h6>
                                <select
                                  name="gender"
                                  className="form-select idd12 p-3"
                                  id=""
                                  onChange={(e) => {
                                    setGender(e.target.value);
                                    setform1({
                                      ...form1,
                                      Gender: e.target.value,
                                    });
                                  }}>
                                  <option>{Gender}</option>
                                  <option value="MALE">{en.sign.male} </option>
                                  <option value="FEMALE">
                                    {en.sign.female}{" "}
                                  </option>
                                </select>
                              </div>
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_weight}</h6>
                                <input
                                  type="number"
                                  min={10}
                                  max={500}
                                  class="form-control idd12 p-3"
                                  value={Weight}
                                  onChange={(e) => {
                                    setWeight(e.target.value);
                                    setform1({
                                      ...form1,
                                      Weight: e.target.value,
                                    });
                                  }}
                                  placeholder={en.profile.profile_weight}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.sign.profile_weight)
                                  }
                                  name="weight"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {en.profile.weight_validation}{" "}
                                </div>
                              </div>
                            </div>
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_height}</h6>
                                <input
                                  type="number"
                                  min={10}
                                  max={4000}
                                  class="form-control idd12 p-3"
                                  value={Height}
                                  onChange={(e) => {
                                    setHeight(e.target.value);
                                    setform1({
                                      ...form1,
                                      Height: e.target.value,
                                    });
                                  }}
                                  placeholder={en.profile.profile_height}
                                  onFocus={(e) => (e.target.placeholder = "")}
                                  onBlur={(e) =>
                                    (e.target.placeholder =
                                      en.profile.profile_height)
                                  }
                                  name="height"
                                  required
                                />
                                <div className="invalid-feedback text-start text-danger">
                                  {en.profile.height_validation}{" "}
                                </div>
                              </div>
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_dob}</h6>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}>
                                  <MobileDatePicker
                                    className="form-control point "
                                    inputFormat="MM/DD/YYYY"
                                    value={DOB}
                                    // placeholder={en.profile.profile_dob}
                                    onChange={(e) => {
                                      setDOB(
                                        e?.$y + "-" + (e?.$M + 1) + "-" + e?.$D
                                      );
                                      setform1({
                                        ...form1,
                                        DOB:
                                          e?.$y +
                                          "-" +
                                          (e?.$M + 1) +
                                          "-" +
                                          e?.$D,
                                      });
                                    }}
                                    required
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="mm/dd/yyyy"
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            <h6>{en.profile.profile_verialityinfo}</h6>
                            <hr class="mt-0 mb-4" />
                            <div class="row pt-1">
                              <div class="col-6 mb-3">
                                <h6>{en.profile.profile_userID}</h6>
                                <p class="idd12 text-muted">{userID}</p>
                              </div>
                            </div>
                            <div class="d-flex justify-content-start">
                              <a>
                                <i class="fab fa-facebook-f fa-lg me-3"></i>
                              </a>
                              <a>
                                <i class="fab fa-twitter fa-lg me-3"></i>
                              </a>
                              <a>
                                <i class="fab fa-instagram fa-lg"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        className="btn yellow-btn mr-5 mt-4"
                        id="sign"
                        onClick={submit}>
                        <span className="m-3">{en.reset.update_btn}</span>
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                            fill="#100E23"
                          />
                        </svg>
                      </a>
                      <div
                        id="message1"
                        className="w-100 mt-4 mb-5 m-auto alert-info p-3"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <div
        className="container-fluid text-start back "
        style={{ paddingLeft: "150px" }}>
        <a className="btn blue-btn back_a" onClick={() => navigate(-1)}>
          <span className="p-2">
            {i18next.t("back")}
          </span>
          <span>
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.99245 3.65333C8.72578 3.65333 9.32578 3.05333 9.32578 2.32C9.32578 1.58667 8.72578 0.986668 7.99245 0.986668C7.25911 0.986668 6.65911 1.58667 6.65911 2.32C6.65911 3.05333 7.25911 3.65333 7.99245 3.65333ZM5.59245 12.92L6.25911 9.98667L7.65911 11.32V15.32H8.99245V10.32L7.59245 8.98667L7.99245 6.98667C8.85911 7.98667 10.1924 8.65333 11.6591 8.65333V7.32C10.3924 7.32 9.32578 6.65333 8.79245 5.72L8.12578 4.65333C7.85911 4.25333 7.45911 3.98667 6.99245 3.98667C6.79245 3.98667 6.65911 4.05333 6.45911 4.05333L2.99245 5.52V8.65333H4.32578V6.38667L5.52578 5.92L4.45911 11.32L1.19245 10.6533L0.925781 11.9867L5.59245 12.92Z"
                fill="white"
              />
            </svg>
          </span>
        </a>
      </div>

      <Footer bg="bg_black" />
    </div>
  );
}
