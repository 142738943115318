import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import en from "../resources/en.json";
import TournamentchallengesModal from "../ESport/TournamentchallengesModal";
import config from "../config.json";
import ViewTournamentModal from "../ESport/ViewTournamentModal";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../i18n/config";
import AssignTeamToTournamentModal from "../ESport/AssignTeamToTournamentModal";
// import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
// import { faGamepadAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faUserCircle,
  faMapMarked,
} from "@fortawesome/free-solid-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import { useCookies } from "react-cookie";
import { Typography } from "antd/lib";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export default function Game_small2(props) {
  // const navigate = useNavigate();
  let BaseUrl = config.BaseUrl;
  var hostName = window.location.origin;
  var TournamentID = new Array();
  // var BaseUrl="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  let [viewTeamDetailsPop, setViewTeamDetailsPop] = useState(false);
  let [viewPop, setviewPop] = useState(false);
  let [viewassigntournement, setviewassigntournement] = useState(false);
  const role = useSelector((state) => state.appReducer.role);
  const [cookies, setCookie] = useCookies(["lang"]);
  useEffect(() => {
    if (viewPop == true) {
      setTimeout(() => {
        setviewPop(false);
      }, 1000);
    }
  }, [viewPop]);

  function clickviewchallenges() {
    setviewPop(true);
  }

  function clickassigntournament() {
    setviewassigntournement(true);
  }

  function selectCard(index, Tournament_id) {
    // setTournamentID(TournamentID => [...TournamentID, Tournament_id]);
    let DesktopCheckIcon = document.getElementsByClassName("check_child3");
    clearAllChildChcks(DesktopCheckIcon);
    if (TournamentID.indexOf(Tournament_id) > -1) {
      var index = TournamentID.indexOf(Tournament_id);
      TournamentID.splice(index, 1);
      DesktopCheckIcon[props.index].classList.add("d-none");
    } else {
      DesktopCheckIcon[props.index].classList.remove("d-none");
      TournamentID.push(Tournament_id);
    }
    props.onChange(TournamentID);
  }

  function clearAllChildChcks(DesktopCheckIcon) {
    if (DesktopCheckIcon && DesktopCheckIcon.length > 0) {
      for (let i = 0; i <= DesktopCheckIcon.length - 1; i++) {
        DesktopCheckIcon[i].classList.add("d-none");
      }
    }
  }

  useEffect(() => {
    if (viewPop == true) {
      setTimeout(() => {
        setviewPop(false);
      }, 1000);
    }
  }, [viewPop]);
  useEffect(() => {
    if (viewassigntournement == true) {
      setTimeout(() => {
        setviewassigntournement(false);
      }, 1000);
    }
  }, [viewassigntournement]);
  useEffect(() => {
    if (viewTeamDetailsPop == true) {
      setTimeout(() => {
        setViewTeamDetailsPop(false);
      }, 1000);
    }
  }, [viewTeamDetailsPop]);

  useEffect(() => {
    if (props.SelectedItem != -1) {
      let DesktopCheckIcon = document.getElementsByClassName("check_child3");
      clearAllChildChcks(DesktopCheckIcon);
    }
  }, [props.SelectedItem]);

  const handleChange = React.useCallback((newValue) => {
    // localStorage.removeItem("TournamentID");
    // fetch(`${BaseUrl}teams/${id}`, {
    //   method: "get",
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${token}`,
    //     'Accept-Language': 'en',
    //   },
    // }).then(function (response11) {
    //   response11.json().then(function (Resp) {
    //     setTeams(Resp.data);
    //   });
    // });
  });
    function percent(num, total) {
      var result = (100 * Number(num)) / Number(total);
      return result;
    }
  return (
    <div
      className="bg_black p-2 card "
      onClick={() => selectCard(props.index, props.item.id)}
      style={{ position: "relative" }}>
      <img src="../assets/imgs/checked.png" className="check_child3 d-none" />
      <div class="tournament_item_top">
        {/* <div class="tournament_item_top_icons tournament_item_top_orange">
          <span
            class="tournament_item_top_price"
            style={{
              font_family: "Rogan!important",
              color: "#f5f5f5",
              fontSize: "12px",
            }}>
            <a href="#" class="tournament_item_price_link"></a>
            <FontAwesomeIcon icon={faMedal} className="icon_1" />
            $0
          </span>
        </div> */}

        <div class="tournament_item_top_status ">
          <span
            class={
              props.item.status == "Open to join" ||
              props.item.status == "مفتوح للانضمام"
                ? "tournament_status_green_bordered p-0"
                : "bg-danger p-0"
            }>
            <a class="tournament_item_price_link"></a>
            {/* <FontAwesomeIcon
              icon={faCheckDouble}
              className="icon_1"
              style={{ fontSize: "8px" }}
            /> */}
            <span style={{ fontSize: "8px", padding: "4px" }}>
              {" "}
              {props.item.status}
            </span>
          </span>
        </div>
        <a class="tournament_item_top_img">
          <span
            class="tournament_item_top_bg"
            style={{ backgroundimage: "url(./imgs/teamBack.jpeg)" }}></span>
        </a>
      </div>
      <div class="tournament_item_btm_title featured_tournament_item_wrap p-1">
        <span class="tournament_item_title">
          <a
            class=" _tooltip"
            title={props.item.name}
            style={{
              font_family: "Rogan!important",
              color: "#f5f5f5",
              padding_top: "5px",
              fontSize: "12px",
            }}>
            {" "}
            {props.item.name}
          </a>
        </span>
        <div className="m-auto">
          <span title={i18next.t("joinedTeams")} class=" _tooltip">
            <LinearProgressWithLabel
              value={percent(
                props.item.number_of_team_assigned | 0,
                props.item.number_of_team_allowed | 0
              )}
            />
          </span>{" "}
          {/* <div class="tournament_item_progress"><span class="tournament_item_progress_colored" style={{right: '37.5%'}}></span></div>  */}
        </div>
        <p
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info  text-end "
              : "tournament_item_btm_info text-start"
          }>
          <span
            title={props.item.challeng_name}
            class="tournament_item_start _tooltip"
            style={{ fontSize: "10px" }}>
            <FontAwesomeIcon
              icon={faGamepad}
              style={{ color: "#6b7996", fontSize: "10px" }}
            />{" "}
            {i18next.t("challenge")}
            <span
              style={{
                font_family: "Rogan!important",
                color: "#f5f5f5",
                fontSize: "10px",
              }}>
              {props.item.challeng_name}
            </span>
          </span>
        </p>
        <p
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info text-end "
              : "tournament_item_btm_info text-start"
          }>
          <span
            title={props.item.tornament_date}
            class="tournament_item_start _tooltip"
            style={{ fontSize: "10px" }}>
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{ color: "#6b7996", fontSize: "10px" }}
            />{" "}
            {i18next.t("date")}
            <span
              style={{
                font_family: "Rogan!important",
                color: "#f5f5f5",
                fontSize: "10px",
              }}>
              {props.item.tornament_date}
            </span>
          </span>
        </p>
        <p
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info text-end "
              : "tournament_item_btm_info text-start"
          }>
          <span
            title={props.item.tournamnt_by}
            class="tournament_item_start _tooltip"
            style={{ fontSize: "10px" }}>
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{ color: "#6b7996", fontSize: "10px" }}
            />{" "}
            {i18next.t("tournament_by")}
            <span
              style={{
                font_family: "Rogan!important",
                color: "#f5f5f5",
                fontSize: "10px",
              }}>
              {props.item.tournamnt_by}
            </span>
          </span>
        </p>

        {/* <div
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info  float-end rtl"
              : "tournament_item_btm_info text-start"
          }> */}
        <p
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info  text-end"
              : "tournament_item_btm_info text-start"
          }>
          <span
            title={props.item.location_name}
            class="tournament_item_end _tooltip"
            style={{ fontSize: "10px" }}>
            <a
              href={`https://www.google.com/maps/place/${props.item.latitude},${props.item.longitude}`}
              target="_blank"
              style={{ color: "#6b7996" }}>
              <FontAwesomeIcon
                icon={faMapMarked}
                style={{ color: "#6b7996", fontSize: "10px" }}
              />
            </a>{" "}
            {i18next.t("location")}
            <span
              style={{
                font_family: "Rogan!important",
                color: "#f5f5f5",
                fontSize: "10px",
              }}>
              {props.item.location_name}
            </span>
          </span>
        </p>

        <p
          className={
            cookies.lang === "ar"
              ? "tournament_item_btm_info  text-end"
              : "tournament_item_btm_info text-start"
          }>
          <span
            title={props.item.tornament_link}
            class="tournament_item_end _tooltip"
            style={{ fontSize: "10px" }}>
            <a
              href={props.item.tornament_link}
              target="_blank"
              style={{ color: "#6b7996" }}>
              <FontAwesomeIcon
                icon={faGlobe}
                style={{ color: "#6b7996", fontSize: "10px" }}
              />
            </a>{" "}
            {i18next.t("website")}
            <span
              style={{
                font_family: "Rogan!important",
                color: "#f5f5f5",
                fontSize: "10px",
              }}>
              {props.item.tornament_link}
            </span>
          </span>
        </p>
        {/* </div> */}

        <div
          className={
            cookies.lang === "ar"
              ? "row text-end   p-1 "
              : "row p-1 text-start "
          }
          style={{ color: "#6b7996", fontWeight: "bold" }}>
          <span class="col-xs-12 col-sm-6 " style={{ fontSize: "8px" }}>
            <span style={{ font_family: "Rogan!important", color: "#f5f5f5" }}>
              {props.item.min_team_level}{" "}
            </span>
            {i18next.t("teamLevel")}
          </span>
          <span class="col-xs-12 col-sm-6 " style={{ fontSize: "8px" }}>
            <span style={{ font_family: "Rogan!important", color: "#f5f5f5" }}>
              {props.item.min_team_members_level}{" "}
            </span>
            {i18next.t("memberLevel")}
          </span>
        </div>
      </div>
    </div>
  );
}
