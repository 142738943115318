import * as React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";

import en from "../resources/en.json";
import AlertDialog from "../layout/AlertDialog";
import Head from "../layout/Head";
import Nav from "../layout/Nav";
import Footer from "../layout/Footer";
import config from "../config.json";
import InfoDialog from "../layout/InfoDialog";
import { useDispatch, useSelector } from "react-redux";
import { Auth, UserRole, UserLevel } from "../redux/Actions/AuthActions";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";
import icon from "../imgs/translateIcon.png";
import TermsPDF from '../TermsAndConditions/TermsAndConditions.pdf';
import { Checkbox } from 'antd';

export default function TeamMemberRegistrationComponent() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["lang"]);
  const navigate = useNavigate();
  //const location = useLocation();
  let [count1, setcount1] = useState(0);
  let [idd1, setid1] = useState(0);
  let [date1, setdate1] = useState({
    dd: "",
    mm: "",
    yy: "",
  });
  let [num1, setnum1] = useState({
    code: "",
    // phone:''
  });
  const [form1, setform1] = useState({
    Parent_id: "",
    name: "",
    email: "",
    password: "",
    Gender: "",
    Weight: "",
    Height: "",
    RoleID: "6",
    WithRole: true,
    teamId: 0,
  });
  let [years1, setyears1] = useState([]);
  const [pop, setpop] = useState(false);
  let [watch1, setwatch1] = useState(0);
  const [infoPop, setinfoPop] = useState(false);
  const [wearableTypes, setwearableTypes] = useState([]);

  var hostName = window.location.origin;
  const backend = "https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
  const front = "http://127.0.0.1:8000/api/";
  let BaseUrl = config.BaseUrl;
  const [loading, setLoading] = useState(false);
  const [EnableLoginBtn, setEnableLoginBtn] = useState(false);
  const [LoginBtnStyle, setLoginBtnStyle] = useState("btn gray-btn mr-5");
  const onChangeAgreement = (e) => {
    try {
      setEnableLoginBtn(e.target.checked);
    }catch (e)
    {
      setEnableLoginBtn(false);
    }

  };
  useEffect(() => {
    EnableLoginBtn ==true?setLoginBtnStyle("btn yellow-btn mr-5"):setLoginBtnStyle("btn gray-btn mr-5");
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, [EnableLoginBtn]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "width=500, height=500");
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    let TeamId = params.get("teamId");
    let Email = params.get("email");
    setform1({ ...form1, teamId: TeamId, email: Email });
  }, []);
  // useEffect(() => {
  //   fetch(`${BaseUrl}wearabletype`, {
  //     method: "get",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   }).then(function (response) {
  //     response.json().then(function (wearablesresp) {
  //       if (wearablesresp.status == true) setwearableTypes(wearablesresp.data);
  //     });
  //   });
  // }, []);

  useEffect(() => {
    if (pop == true) {
      setTimeout(() => {
        setpop(false);
      }, 1000);
    }
  }, [pop]);
  useEffect(() => {
    if (infoPop == true) {
      setTimeout(() => {
        setinfoPop(false);
      }, 1000);
    }
  }, [infoPop]);

  // function connect1() {
  //   document.getElementById("sign_parent").style.display = "none";
  //   document.getElementById("connect_parent").classList.add("disabled");
  //   // setcount1(++count1);
  //   document.getElementById("message_parent").style.display = "none";
  //   document.getElementById("message_parent").innerHTML = "";
  //   setLoading(true);
  //   fetch(`${BaseUrl}loginn`, {
  //     method: "post",
  //     body: JSON.stringify({
  //       email: form1.email,
  //       password: form1.password,
  //     }),
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       'Accept-Language': 'en',
  //     },
  //   }).then(function (response) {
  //     response.json().then(function (loginresp) {
  //       setid1(loginresp.user.id);
  //       dispatch(
  //         Auth(loginresp.user.token, loginresp.user.id, loginresp.user.name)
  //       );
  //       dispatch(UserRole(loginresp.user.role.role_id));
  //       dispatch(
  //         UserLevel(loginresp.user.level !== null ? loginresp.user.level : 0)
  //       );
  //       // fetch(
  //       //   `${BaseUrl}connectUserTowearable/${loginresp.user.id}/${watch1}`,
  //       //   {
  //       //     method: "get",
  //       //     headers: {
  //       //       Accept: "application/json",
  //       //       Authorization: `Bearer ${loginresp.user.token}`,
  //       //       'Accept-Language': cookies.lang,
  //       //     },
  //       //   }
  //       // ).then(function (response11) {
  //       //   response11.json().then(function (connect_fitbitResp) {
  //       //     openInNewTab(connect_fitbitResp.data);
  //       //   });
  //       // });
  //       // var seconds = 30;
  //       // // Update the count down every 1 second
  //       // var x = setInterval(function () {
  //       //   // Time calculations for days, hours, minutes and seconds
  //       //   --seconds;
  //       //
  //       //   // Display the result in the element with id="demo"
  //       //   document.getElementById("demo_parent").innerHTML =
  //       //     en.sign.watchconnect_within + seconds + "s ";
  //       //
  //       //   // If the count down is finished, write some text
  //       //   if (seconds < 0) {
  //       //     clearInterval(x);
  //       //     document.getElementById("demo_parent").innerHTML =
  //       //       en.sign.time_expired;
  //       //   }
  //       // }, 1000);
  //       var y = setTimeout(function () {
  //         fetch(`${BaseUrl}checkwearableAccess/${loginresp.user.id}/true`, {
  //           method: "get",
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: `Bearer ${loginresp.user.token}`,
  //             'Accept-Language': cookies.lang,
  //           },
  //         }).then(function (response33) {
  //           response33.json().then(function (check_fitbitResp) {
  //             setLoading(false);
  //             if (
  //               check_fitbitResp[0][0] ==
  //               "user connect to wearable successfully"
  //             ) {
  //              // clearInterval(x);
  //               document.getElementById("message_parent").style.display =
  //                 "block";
  //               document.getElementById("message_parent").innerHTML =
  //                 "Thank you, the registration process has been completed successfully, the team manager will contact you.";
  //               navigate("/dgamer", { state: {} });
  //             }
  //             if ("UserError" in check_fitbitResp[0]) {
  //               document.getElementById("connect_parent").style.display =
  //                 "block";
  //               document
  //                 .getElementById("connect_parent")
  //                 .classList.remove("disabled");
  //               document.getElementById("message_parent").style.display =
  //                 "block";
  //               if (
  //                 check_fitbitResp[0].UserError.status ==
  //                 "This user does not have a wearable account"
  //               ) {
  //                // clearInterval(x);
  //                 document.getElementById("message_parent").style.display =
  //                   "block";
  //                 document.getElementById("message_parent").innerHTML =
  //                   "Thank you, some thing went wrong, The team manager will contact you";
  //                 navigate("/dgamer", { state: {} });
  //
  //                 // document.getElementById("message_parent").innerHTML = en.sign.watchlongtime_msg;
  //               } else {
  //                 //clearInterval(x);
  //                 document.getElementById("message_parent").style.display =
  //                   "block";
  //                 document.getElementById("message_parent").innerHTML =
  //                   "Thank you, some thing went wrong, The team manager will contact you";
  //                 navigate("/dgamer", { state: {} });
  //               }
  //             }
  //           });
  //         });
  //       }, 2000);
  //       // }
  //     });
  //   });
  // }

  function loginByFitbit() {
    if (EnableLoginBtn==false){return;}
    fetch(`${BaseUrl}connectUserTowearable/0/1/` + params.get("email"), {
      method: "get",
      headers: {
        Accept: "application/json",
      },
    }).then(function (response11) {
      response11.json().then(function (connect_fitbitResp) {
        window.location.replace(connect_fitbitResp.data);
      });
    });
  }

  function LoginByGoogle() {
    fetch(`${BaseUrl}connectUserTowearable/0/3`, {
      method: "get",
      headers: {
        Accept: "application/json",
      },
    }).then(function (response11) {
      response11.json().then(function (connect_fitbitResp) {
        window.location.replace(connect_fitbitResp.data);
      });
    });
  }

  // function submit1() {
  //   // if (form1.RoleID == "6" && watch1 == "") {
  //   //   setpop(true);
  //   // } else {
  //     document.getElementById("form_parent").classList.add("was-validated");
  //     if (
  //       !document
  //         .getElementById("password_parent")
  //         .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
  //     ) {
  //       document.getElementById("pass_parent").style.display = "block";
  //     }
  //     if (
  //       document.getElementById("co-password_parent").value !=
  //       document.getElementById("password_parent").value
  //     ) {
  //       document.getElementById("confirm_parent").style.display = "block";
  //     }
  //
  //     if (
  //       document.getElementById("form_parent").checkValidity() &&
  //       document.getElementById("co-password_parent").value ==
  //         document.getElementById("password_parent").value &&
  //       document
  //         .getElementById("password_parent")
  //         .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
  //     ) {
  //       fetch(`${BaseUrl}RegisterEsportUser`, {
  //         method: "post",
  //         body: JSON.stringify(form1),
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           'Accept-Language': cookies.lang,
  //         },
  //       }).then(function (response) {
  //         response.json().then(function (signresp) {
  //           if (signresp.msg == "Failed") {
  //             document.getElementById("message_parent").style.display = "block";
  //             if (signresp && signresp.data && signresp.data.email[0]) {
  //               document.getElementById("message_parent").innerHTML =
  //                 signresp.data.email[0];
  //             } else {
  //               document.getElementById("message_parent").innerHTML =
  //                 en.sign.fail_createaccount;
  //             }
  //           } else if (signresp.msg == "That e-mail has taken, Try another") {
  //             document.getElementById("message_parent").style.display = "block";
  //             document.getElementById("message_parent").innerHTML =
  //               signresp.msg;
  //           } else if (signresp.errNum == "S0000") {
  //             if (form1.RoleID == "6") {
  //               connect1();
  //             }
  //           }
  //         });
  //       });
  //     }
  //  // }
  // }
  // function connect1() {
  //   document.getElementById("sign_parent").style.display = "none";
  //   document.getElementById("connect_parent").classList.add("disabled");
  //   // setcount1(++count1);
  //   document.getElementById("message_parent").style.display = "none";
  //   document.getElementById("message_parent").innerHTML = "";
  //   setLoading(true);
  //   fetch(`${BaseUrl}loginn`, {
  //     method: "post",
  //     body: JSON.stringify({
  //       email: form1.email,
  //       password: form1.password,
  //     }),
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   }).then(function (response) {
  //     response.json().then(function (loginresp) {
  //       setid1(loginresp.user.id);
  //       dispatch(
  //           Auth(loginresp.user.token, loginresp.user.id, loginresp.user.name)
  //       );
  //       dispatch(UserRole(loginresp.user.role.role_id));
  //       dispatch(
  //           UserLevel(loginresp.user.level !== null ? loginresp.user.level : 0)
  //       );
  //       // fetch(
  //       //   `${BaseUrl}connectUserTowearable/${loginresp.user.id}/${watch1}`,
  //       //   {
  //       //     method: "get",
  //       //     headers: {
  //       //       Accept: "application/json",
  //       //       Authorization: `Bearer ${token}`,
  //       //     },
  //       //   }
  //       // ).then(function (response11) {
  //       //   response11.json().then(function (connect_fitbitResp) {
  //       //     openInNewTab(connect_fitbitResp.data);
  //       //   });
  //       // });
  //       // var seconds = 30;
  //       // // Update the count down every 1 second
  //       // var x = setInterval(function () {
  //       //   // Time calculations for days, hours, minutes and seconds
  //       //   --seconds;
  //       //
  //       //   // Display the result in the element with id="demo"
  //       //   document.getElementById("demo_parent").innerHTML =
  //       //     en.sign.watchconnect_within + seconds + "s ";
  //       //
  //       //   // If the count down is finished, write some text
  //       //   if (seconds < 0) {
  //       //     clearInterval(x);
  //       //     document.getElementById("demo_parent").innerHTML =
  //       //       en.sign.time_expired;
  //       //   }
  //       // }, 1000);
  //       var y = setTimeout(function () {
  //         fetch(`${BaseUrl}checkwearableAccess/${loginresp.user.id}`, {
  //           method: "get",
  //           headers: {
  //             Accept: "application/json",
  //             Authorization: `Bearer ${loginresp.user.token}`,
  //           },
  //         }).then(function (response33) {
  //           response33.json().then(function (check_fitbitResp) {
  //             setLoading(false);
  //             if (
  //                 check_fitbitResp[0][0] ==
  //                 "user connect to wearable successfully"
  //             ) {
  //               // clearInterval(x);
  //               document.getElementById("connect_parent").style.display =
  //                   "none";
  //               document.getElementById("demo_parent").innerHTML =
  //                   en.sign.account_prepare;
  //               navigate("/dgamer", { state: {} });
  //             }
  //             if ("UserError" in check_fitbitResp[0]) {
  //               document.getElementById("connect_parent").style.display =
  //                   "block";
  //               document
  //                   .getElementById("connect_parent")
  //                   .classList.remove("disabled");
  //               document.getElementById("message_parent").style.display =
  //                   "block";
  //               if (
  //                   check_fitbitResp[0].UserError.status ==
  //                   "This user does not have a wearable account"
  //               ) {
  //                 // clearInterval(x);
  //                 navigate("/dgamer", { state: { msgtest: en.sign.donthave } });
  //                 // document.getElementById("message_parent").innerHTML = en.sign.watchlongtime_msg;
  //               } else {
  //                 // clearInterval(x);
  //                 // document.getElementById("message_parent").innerHTML = en.sign.watchfailed_msg;
  //                 navigate("/dgamer", {
  //                   state: { msgtest: en.sign.watchfailed_msg },
  //                 });
  //               }
  //             }
  //           });
  //         });
  //       }, 2000);
  //       // }
  //     });
  //   });
  // }

  function submit1() {
    if (EnableLoginBtn==false){return;}
    setLoading(true);
    //  if (form1.RoleID == "3" && watch1 == "") {
    //    setpop(true);
    //  } else {
    document.getElementById("form_parent").classList.add("was-validated");
    if (
      !document
        .getElementById("password_parent")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      document.getElementById("pass_parent").style.display = "block";
    }
    if (
      document.getElementById("co-password_parent").value !=
      document.getElementById("password_parent").value
    ) {
      document.getElementById("confirm_parent").style.display = "block";
    }

    if (
      document.getElementById("form_parent").checkValidity() &&
      document.getElementById("co-password_parent").value ==
        document.getElementById("password_parent").value &&
      document
        .getElementById("password_parent")
        .value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)
    ) {
      fetch(`${BaseUrl}sendEmailVerification`, {
        method: "post",
        body: JSON.stringify(form1),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then(function (response) {
        response.json().then(function (check_userResp) {
          if (check_userResp.status == true) {
            navigate("/verfiymail", { state: { form1: form1 } });
          } else if (check_userResp.status == false) {
            setLoading(false);
            document.getElementById("message_parent").style.display = "block";
            document.getElementById("message_parent").innerHTML =
              check_userResp.msg;
          }
        });
      });
    }
    // }
  }
  const setLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
  };

  const changeLangToEn = () => {
    setLanguage("en");
    i18n.changeLanguage("en");
  };
  const changeLangToAr = () => {
    setLanguage("ar");
    i18n.changeLanguage("ar");
  };
  return (
    <div>
      <Head />
      <section className="sign container-fluid p-2 pb-5">
        <Nav />
        <div className="flash1"></div>
        <div className="flash2"></div>
        <div className="flash3"></div>
        <AlertDialog show={pop} Modalcontent={en.sign.chooseWatch_pop} />
        <InfoDialog show={infoPop} />
        <button
          style={{ marginTop: "-60px" }}
          className="btn float-end"
          onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}
        >
          <img
            src={icon}
            title={i18next.t("changeLanguage")}
            style={{ width: "50px", height: "50px" }}
          />
        </button>
        <div className="d-inline-flex p-2 d-flex justify-content-center  pt-3 pb-3">
          <h2>
            {en.sign.JoinEsportTeam}
            {i18next.t("sign_GamerCaptionRegister")}
            <svg
              onClick={() => setinfoPop(true)}
              className="m-2 point"
              width="34"
              height="34"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_516)">
                <path
                  d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                  fill="#EECE52"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_516">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </h2>
        </div>
        <div className=" m-auto form-white">
          <hr className="my-3 hr-yellow" />
        </div>
        <form className=" m-auto form-white" id="form_parent">
          <div class="mb-3 ">
            <input
              type="text"
              maxLength="50"
              class="form-control"
              placeholder={en.sign.name_placeholder}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = en.sign.name_placeholder)}
              name="name"
              onChange={(e) => setform1({ ...form1, name: e.target.value })}
              required
            />
            <div className="invalid-feedback text-start text-danger">
              {i18next.t("name_validation")}
            </div>
          </div>
          <div class="mb-3 ">
            <input
              type="email"
              className="form-control"
              readOnly
              id="email"
              placeholder={i18next.t("mail_placeholder")}
              value={form1.email}
              required
            />
            <div className="invalid-feedback text-start text-danger">
              {i18next.t("mail_validation")}
            </div>
          </div>

          <div class="mb-3">
            <select
              name="gender"
              className="form-select "
              id=""
              onChange={(e) => setform1({ ...form1, Gender: e.target.value })}
            >
              <option>{i18next.t("gender")}</option>
              <option value="MALE">{i18next.t("male")} </option>
              <option value="FEMALE"> {i18next.t("female")}</option>
            </select>
          </div>

          <div class="mb-3">
            <input
              type="password"
              class="form-control"
              id="password_parent"
              placeholder={i18next.t("password_placeholder")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) =>
                (e.target.placeholder = i18next.t("password_placeholder"))
              }
              name="password"
              onChange={(e) => setform1({ ...form1, password: e.target.value })}
              required
            />
            <div className="invalid-feedback text-start text-danger">
              {i18next.t("password_validation")}
            </div>
            <div
              className="invalid-feedback text-start text-danger"
              id="pass_parent"
            >
              {i18next.t("password_validation_contain")}
            </div>
          </div>
          <div class="mb-3">
            <input
              type="password"
              class="form-control"
              id="co-password_parent"
              placeholder={i18next.t("confirmPassword_placeholder")}
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) =>
                (e.target.placeholder = i18next.t("confirmPassword_placeholder"))
              }
              name=""
              required
            />
            <div className="invalid-feedback text-start text-danger">
              {i18next.t("confirmpassword_validation")}
            </div>
            <div
              className="invalid-feedback text-start text-danger"
              id="confirm_parent"
            >
              {i18next.t("confirmpassword_match")}
            </div>
          </div>

          {/*<div class="mb-3 input-group " id="watch_parent">*/}
          {/*  <span class="input-group-text select-watch">*/}
          {/*    <img src="../assets/imgs/watch.png" className=""></img>*/}
          {/*  </span>*/}
          {/*  <select*/}
          {/*    name=""*/}
          {/*    className="form-select "*/}
          {/*    id=""*/}
          {/*    onChange={(e) => {*/}
          {/*      setwatch1(e.target.value);*/}
          {/*    }}>*/}
          {/*    <option className="" value="">*/}
          {/*      {en.sign.select_device}*/}
          {/*    </option>*/}
          {/*    {wearableTypes.length > 0*/}
          {/*      ? wearableTypes.map((item) => (*/}
          {/*          <option className="" value={item.id}>*/}
          {/*            {item.Type}{" "}*/}
          {/*          </option>*/}
          {/*        ))*/}
          {/*      : ""}*/}
          {/*  </select>*/}
          {/*</div>*/}

          {/*<a*/}
          {/*  className="btn yellow-btn mr-5 disabled"*/}
          {/*  id="connect_parent"*/}
          {/*  onClick={connect1}>*/}
          {/*  <span className="m-3">{en.sign.linkwatch_btn}</span>*/}
          {/*</a>*/}
          <div className=" m-auto form-white">
            <hr className="my-3 hr-yellow" />
          </div>
          <p style={{ color: "white", textAlign: "left" }}>
            {i18next.t("sign_gamerRegisterSammary")}
          </p>
          <div className=" m-auto form-white">
            <hr className="my-3 hr-yellow" />
          </div>
          <div className={  cookies.lang == "ar" ?"col-lg-12 text-end":"col-lg-12 text-start"}  dir={cookies.lang == "ar" ? "rtl" : "ltr"}>
            <Checkbox onChange={onChangeAgreement}  >

              <a
                  onClick={() => window.open("/TermsAndConditions.pdf",'_blank')}
                  className="text-white"
              >
                <span className=" text-white"> {i18next.t("termsPrefix")} </span>
                <span className="  text-white link">{i18next.t("terms")}</span>
              </a>


            </Checkbox>




          </div>
          <div className=" m-auto form-white">
            <hr className="my-3 hr-yellow" />
          </div>
          <a
            className={LoginBtnStyle}
            id="sign_parent"
            onClick={submit1}
          >
            <span className="m-3">{i18next.t("creategamer_btn")}</span>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                fill="#100E23"
              />
            </svg>
          </a>
          <div className="  text-white">
            <hr></hr>
            {i18next.t("or")}
          </div>
          <div className="row">
            {/*<div className="col-lg-6">*/}
            {/*  <a*/}
            {/*    className="btn yellow-btn mr-5 mt-4"*/}
            {/*    id="sign"*/}
            {/*    onClick={LoginByGoogle}*/}
            {/*    style={{ backgroundColor: "#EECE52 !important" }}*/}
            {/*  >*/}
            {/*    <svg*/}
            {/*      width="14"*/}
            {/*      height="14"*/}
            {/*      viewBox="0 0 14 14"*/}
            {/*      fill="none"*/}
            {/*      xmlns="http://www.w3.org/2000/svg"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"*/}
            {/*        fill="#100E23"*/}
            {/*      />*/}
            {/*    </svg>*/}
            {/*    <span className="m-3">{i18next.t("FitBitLogin")}</span>*/}
            {/*  </a>*/}
            {/*</div>*/}
            <div className="col-lg-12">
              <a
                  className={LoginBtnStyle}
                id="sign"
                onClick={loginByFitbit}
                style={{ backgroundColor: "#EECE52 !important" }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z"
                    fill="#100E23"
                  />
                </svg>
                <span className="m-3">{i18next.t("GoogleLogin")}</span>
              </a>
            </div>
          </div>
        </form>
        {loading == true ? (
          <div className="w-100 mt-3 mb-2 m-auto">
            <CircularProgress />
          </div>
        ) : (
          <div class="alert alert-danger mt-5" id="message_parent"></div>
        )}
        <p id="demo_parent" className="text-white p-2 mt-3 "></p>
        <NavLink
          className="d-inline-flex p-2 d-flex justify-content-center nav-link text-white link"
          to="/RegisterAccountType"
        >
         {i18next.t("sign_Back")}
        </NavLink>

        <NavLink
          className="d-inline-flex p-2 d-flex justify-content-center nav-link text-white link"
          to="/veryalityApp"
        >
         {i18next.t("have_account")}
        </NavLink>
      </section>

      <Footer bg="bg_blue" />
    </div>
  );
}
