import * as React from 'react';
import { NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect,useMemo } from "react";
import en from '../../resources/en.json'
import AlertDialog from '../../layout/AlertDialog';
import config from "../../config.json";
import { useDispatch, useSelector } from "react-redux";
import {Auth} from "../../redux/Actions/AuthActions";


export default function SignParent()  {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.appReducer.token);

  const navigate = useNavigate();
  //const location = useLocation();
  let [count1, setcount1] = useState(0);
  let [idd1, setid1] = useState(0);
  let [date1, setdate1]=useState({
      dd:'',
      mm:'',
      yy:''
  })
  let [num1, setnum1]=useState({
    code:'',
   // phone:''
})
  const [form1, setform1] = useState({
    Parent_id: "",
    name:"", 
    email:"",
    password:"",
    //Phone:"",
    //Landline:"",
    Gender:"", 
    Weight:"", 
    Height:"", 
    //DOB:"",
    //Country:"",
    RoleID: "",
    WithRole: true

});
let [years1, setyears1] = useState([]);
const [pop, setpop] = useState(false);
let [watch1, setwatch1] = useState('');

var hostName =window.location.origin;
const backend="https://phplaravel-592682-2484169.cloudwaysapps.com/api/";
const front="http://127.0.0.1:8000/api/";
let BaseUrl=config.BaseUrl;;

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank',  "width=500, height=500")

  }
function getdate1(){
        const d = new Date();
        let max =  d.getFullYear();
        let min = max - 60;
        for (var i = max; i >= min; i--) {
          years1.push(i)
        }
        setyears1(years1);
       }


    useEffect(() => {
        if(form1.RoleID=='3'){
            document.getElementById('watch_parent').classList.remove('d-none');
        }
        else if(form1.RoleID=='1'){
            document.getElementById('watch_parent').classList.add('d-none');
        }
      }, [form1.RoleID]);
  
   
function google(){
  fetch(`${BaseUrl}login/google`,{
    method:'get',
    headers:{
    } 
  }).then(function(response){
      response.json().then(function(resp){

       
      })
  })

}

function facebook(){
  fetch(`${BaseUrl}login/facebook`,{
    method:'get',
    headers:{
    } 
  }).then(function(response){
      response.json().then(function(resp){

       
      })
  })

}

function connect1(){
    document.getElementById('sign_parent').style.display='none';
    document.getElementById('connect_parent').classList.add('disabled');
    setcount1(++count1);
    document.getElementById('message_parent').style.display='none';
    document.getElementById("message_parent").innerHTML='';

    fetch(`${BaseUrl}loginn`,{
        method:'post',
        body:JSON.stringify({
            email:form1.email,
            password:form1.password
        }),
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        } 
      }).then(function(response){
        response.json().then(function(loginresp){
            dispatch(Auth(loginresp.user.token, loginresp.user.id,loginresp.user.name))
            setid1(loginresp.user.id);
            if (count1 >=4) {
                clearInterval(x);
                document.getElementById("demo_parent").innerHTML=' ';
                alert(en.sign.manytimes_alert)
                  navigate('/signup');
                }
                else if(count1 <4){
               fetch(`${BaseUrl}connectToFitbi/${loginresp.id}`,{
                method:'get',
                headers:{
                  'Accept':'application/json',
                  'Authorization': `Bearer ${token}`,
                } 
              }).then(function(response11){
                  response11.json().then(function(connect_fitbitResp){
                    openInNewTab(connect_fitbitResp.data);
                    
                  })
                })
                var seconds =30;
                // Update the count down every 1 second
                var x = setInterval(function() {
                  // Time calculations for days, hours, minutes and seconds
                    --seconds;
                
                  // Display the result in the element with id="demo"
                  document.getElementById("demo_parent").innerHTML = en.sign.watchconnect_within +seconds + "s ";
                
                  // If the count down is finished, write some text
                  if (seconds < 0) {
                    clearInterval(x);
                    document.getElementById("demo_parent").innerHTML = en.sign.time_expired;
                  }
                }, 1000);
                var y = setTimeout(function(){
                  fetch(`${BaseUrl}checkFitbitAccess/${loginresp.id}`,{
                    method:'get',
                    headers:{
                      'Accept':'application/json',
                      'Authorization': `Bearer ${token}`
                    } 
                  }).then(function(response33){
                      response33.json().then(function(check_fitbitResp){
                       if(Array.isArray(check_fitbitResp) && check_fitbitResp[0]=='user connect to fitbit successfully') {
                        clearInterval(x);
                        document.getElementById('connect_parent').style.display='none';
                        document.getElementById("demo_parent").innerHTML=en.sign.account_prepare;
                        navigate('/dgamer', {state:{id:loginresp.id,username:form1.name}});


                        }
                       if("UserError" in check_fitbitResp[0]){
                           document.getElementById('connect_parent').style.display='block';
                           document.getElementById('connect_parent').classList.remove('disabled');      
                           document.getElementById('message_parent').style.display='block';
                          if(check_fitbitResp[0].UserError.status=='This user does not have a wearable account'){
                            document.getElementById("message_parent").innerHTML = en.sign.watchlongtime_msg;
                          }
                          else{
                            document.getElementById("message_parent").innerHTML = en.sign.watchfailed_msg;
            
                          }             
                        }
                      })
                    })
                }, 30000);
              }
          })
        
})
}

function submit1(){
  if(form1.RoleID=='3' && watch1==''){
    setpop(true);
  }
  else{

   document.getElementById('form_parent').classList.add('was-validated');
  if (!document.getElementById('password_parent').value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)){
    document.getElementById('pass_parent').style.display='block';
  }
  if(document.getElementById('co-password_parent').value!=document.getElementById('password_parent').value){
    document.getElementById('confirm_parent').style.display='block';
  }

  if (document.getElementById('form_parent').checkValidity() 
  && document.getElementById('co-password_parent').value==document.getElementById('password_parent').value 
  && document.getElementById('password_parent').value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,20}$/)) {

    
    fetch(`${BaseUrl}users`,{
      method:'post',
      body:JSON.stringify(form1),
      headers:{
          'Accept':'application/json',
          'Content-Type':'application/json'
      } 
    }).then(function(response){
        response.json().then(function(signresp){

            if(signresp.msg=='Failed'){
                document.getElementById('message_parent').style.display='block';
                if (signresp && signresp.data && signresp.data.email[0])
                {
                    document.getElementById('message_parent').innerHTML=signresp.data.email[0];
                }
                else {

                    document.getElementById('message_parent').innerHTML=en.sign.fail_createaccount;

                }

            }

            else if(signresp.errNum=='S0000'){
                if(form1.RoleID=='1'){
                    fetch(`${BaseUrl}loginn`,{
                        method:'post',
                        body:JSON.stringify({
                            email:form1.email,
                            password:form1.password
                        }),
                        headers:{
                            'Accept':'application/json',
                            'Content-Type':'application/json'
                        } 
                      }).then(function(response){
                        response.json().then(function(loginResp){
                            dispatch(Auth(loginResp.user.token, loginResp.user.id,loginResp.user.name));
                            navigate('/parent', {state:{id:loginResp.user.id,username:form1.name}});

                    })
                    })
                }
                else if(form1.RoleID=='3'){
                    connect1();
                }

            }
         
        })
    })

  }
   }
 
}


return (
         <div>

                <AlertDialog show={pop} Modalcontent={en.sign.chooseWatch_pop}/>
                    <form className=' m-auto form-white' id='form_parent'>
                        <div class="mb-3 ">
                            <input type="text" maxLength='200' class="form-control"  placeholder={en.sign.name_placeholder} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = en.sign.name_placeholder}  name="name" onChange={e=>setform1({...form1,name:e.target.value})} required/>
                            <div className='invalid-feedback text-start text-danger'>{en.sign.name_validation} </div>
                        </div>
                        <div class="mb-3 ">
                            <input type="email" class="form-control" id="email" placeholder={en.sign.mail_placeholder}  onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = en.sign.mail_placeholder}  name="email" onChange={e=>setform1({...form1,email:e.target.value})} required/>
                            <div className='invalid-feedback text-start text-danger'>{en.sign.mail_validation} </div>
                        </div>

                        <div class="mb-3">
                            <select name="gender" className='form-select ' id="" onChange={e=>setform1({...form1,Gender:e.target.value})}>
                               <option>{en.sign.gender}</option>
                                <option  value="MALE" >{en.sign.male} </option>
                                <option  value="FEMALE">{en.sign.female} </option>
                            </select>                      
                        </div>

                      
                        <div class="mb-3">
                            <input type="password" class="form-control" id="password_parent" placeholder={en.sign.password_placeholder} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = en.sign.password_placeholder} name="password" onChange={e=>setform1({...form1,password:e.target.value})} required/>
                            <div className='invalid-feedback text-start text-danger'>{en.sign.password_validation} </div>
                            <div className='invalid-feedback text-start text-danger' id='pass_parent'>{en.sign.password_validation_contain}</div>

                        </div>
                        <div class="mb-3">
                            <input type="password" class="form-control" id='co-password_parent'  placeholder={en.sign.confirmPassword_placeholder} onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = en.sign.confirmPassword_placeholder} name="" required/>
                            <div className='invalid-feedback text-start text-danger'>{en.sign.confirmpassword_validation} </div>
                            <div className='invalid-feedback text-start text-danger' id='confirm_parent'>{en.sign.confirmpassword_match} </div>
                        </div>
                        <hr class="my-3 hr-yellow" />

                        
                        <div class="mb-3  text-white text-start">
                            <div class="form-check">
                                <input type="radio" class="form-check-input " id="parent_role" name="RoleID" value="1" onChange={e=>setform1({...form1,RoleID:e.target.value})} required/>
                                <label class="form-check-label p-3 pt-0 pb-1" for="radio1">{en.sign.parent_role}</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" id="parent_role" name="RoleID" value="3" onChange={e=>setform1({...form1,RoleID:e.target.value})} required/>
                                <label class="form-check-label p-3 pt-0 " for="radio2">{en.sign.both_role}</label>
                                <div className='invalid-feedback text-start text-danger'>{en.sign.role_validation} </div>

                            </div>

                        </div>
                        <div class="mb-3 input-group d-none" id='watch_parent'>
                            <span class="input-group-text select-watch">
                                <img src='../assets/imgs/watch.png' className=''></img>
                            </span>
                            <select name="" className='form-select '  id="" onChange={e=>{setwatch1(e.target.value)}}>
                               <option className='' value=''>{en.sign.select_device}</option>
                                <option className='' value="fitbit" >Fitbit </option>
                            </select>                      
                        </div>
                        <a className="btn yellow-btn mr-5 " id='sign_parent'  onClick={submit1} style={{backgroundColor:'#EECE52 !important'}}>
                            <span className="m-3">{en.sign.createparent_btn}</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.66659 1.66668V3.44668L6.99992 4.11334L6.33325 3.44668V1.66668H7.66659ZM12.3333 6.33334V7.66668H10.5533L9.88659 7.00001L10.5533 6.33334H12.3333ZM3.44659 6.33334L4.11325 7.00001L3.44659 7.66668H1.66659V6.33334H3.44659ZM6.99992 9.88668L7.66659 10.5533V12.3333H6.33325V10.5533L6.99992 9.88668ZM8.99992 0.333344H4.99992V4.00001L6.99992 6.00001L8.99992 4.00001V0.333344ZM13.6666 5.00001H9.99992L7.99992 7.00001L9.99992 9.00001H13.6666V5.00001ZM3.99992 5.00001H0.333252V9.00001H3.99992L5.99992 7.00001L3.99992 5.00001ZM6.99992 8.00001L4.99992 10V13.6667H8.99992V10L6.99992 8.00001Z" fill="#100E23"/>
                            </svg>
                        </a>
                        <a className="btn yellow-btn mr-5 disabled"  id='connect_parent'  onClick={connect1} style={{backgroundColor:'#EECE52 !important'}}>
                            <span className="m-3">{en.sign.linkwatch_btn}</span>
                        </a>
                    </form>
                    <div class="alert alert-danger mt-5" id='message_parent'>

                    </div>
                    <p id="demo_parent" className='text-white p-2 mt-3 '></p>

        </div>
      
  );
}
